import { useGlobalize } from 'databinding/globalize'
import translations from 'components/Shop/Product/ProductList/_ProductList.i18n.json'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import { Link } from 'react-router-dom'
import Stock from 'components/Shop/Product/Stock'
import React, { memo, useCallback } from 'react'
import Highlighter from 'react-highlight-words'
import ShopImage from 'components/Helpers/ShopImage'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import AddToCart from 'components/Shop/Product/AddToCart/AddToCart'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

const i18nKey = 'product_list'

export default memo(({ product, highlightOptions, targetPath, allowCart }) => {
  const { language, translate } = useGlobalize({ translations })
  const dispatch = useDispatch()
  const handleClick = useCallback((e) => {
    dispatch(push(targetPath))
  }, [targetPath, dispatch])
  const handleLink = useCallback((e) => e.stopPropagation(), [])

  const isProductFamily = !!product.familyMemberIds
  return (
    <Grid.Row id={product.articleNumber} className="indicate-link" onClick={handleClick}>
      <Grid.Column width={12}>
        {!isProductFamily && <Stock as="icon" stock={product.stock} />}
        <Link to={targetPath} onClick={handleLink} className="title">
          <Highlighter {...highlightOptions} textToHighlight={product.title[language]} />
        </Link>
      </Grid.Column>
      <Grid.Column width={2}>
        <ShopImage product={product} size="tiny" />
      </Grid.Column>
      <Grid.Column width={product.status === 'archived' ? 10 : 8}>
        {isProductFamily && translate(`${i18nKey}.from`)}
        <div className="price">
          {product.formattedPrice?.replace('.00', '.-') || ''}
          {product.originalPrice !== product.price && product.formattedPrice && (
            <span className="discount">
              {!!product.discount2Percent && (
                <Label tag color="orange" size="mini" content={`- ${product.discount2Percent} %`} />
              )}
              {!!product.discountPercent && <Label tag size="mini" content={`- ${product.discountPercent} %`} />}
              <span className="strike">{product.formattedOriginalPrice.replace('.00', '.-')}</span>
            </span>
          )}
        </div>
      </Grid.Column>
      {allowCart && (
        <Grid.Column width={2}>
          <AddToCart quickAdd product={product} />
        </Grid.Column>
      )}
    </Grid.Row>
  )
})
