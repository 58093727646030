import React from 'react'
import ReactPlayer from 'react-player'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'

class Video extends React.PureComponent {
  render () {
    const { url, options = {}, fullbleed } = this.props
    return (

      <Container fluid={fullbleed} className='video-container'>
        <div className='player-wrapper'>
          <ReactPlayer className='react-player' url={url} width='100%' height='100%' {...options} playsinline />
        </div>
      </Container>
    )
  }
}

export default Video
