import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import SemanticButton from 'semantic-ui-react/dist/commonjs/elements/Button'

class MenuButton extends React.PureComponent {
  render() {
    const { path, navigationTitle, children, dispatch, ...remainingProps } = this.props
    if (remainingProps.labelPosition === '') delete remainingProps.labelPosition
    return (
      <SemanticButton
        {...{
          content: children || navigationTitle,
          icon: remainingProps.icon,
          href: path,
          onClick: (event) => {
            event.preventDefault()
            return dispatch(push(path))
          },
        }}
        {...remainingProps}
      />
    )
  }
}

MenuButton = connect()(MenuButton)
export default MenuButton
