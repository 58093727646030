import config from 'config/config'
import { globalize } from 'databinding/globalize'
import React from 'react'
import { connect } from 'react-redux'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
const mapStateToProps = (state) => {
  const { assets } = state.cms
  return { assets }
}

class AssetDownload extends React.PureComponent {
  render() {
    const {
      button,
      id,
      inverted, //ownProps
      globalize: { language }, //globalize
    } = this.props
    const { assets = [] } = this.props
    const asset = assets.find((asset) => asset.uuid === id) || {}
    const { styles = {}, data = {} } = asset
    const { original = {} } = styles
    const title = (language === 'de' ? data.titleDe : data.titleFr) || asset.fileName || 'Unnamed Download'

    let returnValue = false
    if (button) {
      returnValue = <Button href={process.env.REACT_APP_CMS_API + original.url} target="_blank" {...button} />
    } else {
      if (asset.contentType) {
        let assetProps = {
          inverted,
          content: `${title} (${original.humanSize})`,
          icon: config.contentTypes[asset.contentType].icon,
          href: process.env.REACT_APP_CMS_API + original.url,
        }
        returnValue = <Header {...assetProps} as="a" target="_blank" />
      }
    }
    return returnValue
  }
}

AssetDownload = connect(mapStateToProps)(AssetDownload)
AssetDownload = globalize()(AssetDownload)
export default AssetDownload
