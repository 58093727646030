import MyMarkdown from 'components/cms/cmsComponents/MyMarkdown'
import AssetImage from 'components/Helpers/AssetImage'
import { useGlobalize } from 'databinding/globalize'
import React from 'react'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import translations from './BlogEntryHeading.i18n.json'
import ReactPlayer from 'react-player'
import removeMd from 'remove-markdown'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import config from 'config/config'

export default (props) => {
  const { title, author, asset_id, youtube_id, imageCaption, text } = props
  const { language } = useGlobalize({ translations })
  const assets = useSelector(({ cms: { assets } }) => assets)
  const description = removeMd(text).replace(/\n/g, '').replace(/\s+/g, ' ')
  const image = assets.find((asset) => asset.uuid === asset_id) || {}
  const assetPath = ((image.styles || {}).large || {}).url
  return (
    <Container>
      <Helmet>
        {title && <title children={`${title} - ${config.company.name[language]}`} />}
        {text && <meta name="description" content={description} />}
        {text && <meta property="og:description" content={description} />}
        {title && <meta property="og:title" content={title} />}
        {youtube_id && <meta property="og:image" content={`https://img.youtube.com/vi/${youtube_id}/hqdefault.jpg`} />}
        {assetPath && <meta property="og:image" content={config.cmsAPI + assetPath} />}
      </Helmet>
      <div>
        <Header as="h1" content={title || ''} className="page-title" />
        {author && (
          <div className="blog-header-tagline">
            <Icon name="user circle" />
            {author}
          </div>
        )}
      </div>

      <div className="blog-header-caption">
        <MyMarkdown children={text} />
      </div>
      {youtube_id && (
        <div className="player-wrapper" style={{ border: 'solid black 1px' }}>
          <ReactPlayer
            className="react-player"
            url={`https://www.youtube.com/watch?v=${youtube_id}`}
            controls
            width="100%"
            height="100%"
            playsinline
          />
        </div>
      )}
      {asset_id && (
        <div className="blog-header-image">
          <AssetImage fluid {...{ id: asset_id }} />
        </div>
      )}
      <p className="spacing-after" children={imageCaption || ' '} />
    </Container>
  )
}
