import Cards from 'components/cms/cmsComponents/Cards/Cards'
import CategoryList from 'components/cms/cmsComponents/CategoryList/CategoryList'
import ContentSubnavi from 'components/cms/cmsComponents/ContentSubnavi/ContentSubnavi'
import DealerList from 'components/cms/cmsComponents/DealerList/DealerList'
import Divider from 'components/cms/cmsComponents/Divider/Divider'
import GoogleMap from 'components/cms/cmsComponents/GoogleMap/GoogleMap'
import Navigation from 'components/cms/cmsComponents/Navigation/Navigation'
import ProductList from 'components/cms/cmsComponents/ProductList/CMSProductList'
import Slides from 'components/cms/cmsComponents/Slider/Slides'
import Teaser from 'components/cms/cmsComponents/Teaser/Teaser'
import TextImage from 'components/cms/cmsComponents/TextImage/TextImage'
import Title from 'components/cms/cmsComponents/Title/Title'
import Video from 'components/cms/cmsComponents/Video/Video'
import React from 'react'
import Advantages from 'components/cms/cmsComponents/Advantages/Advantages'
import uuid from 'uuid'

export default {
  Title: {
    component: Title,
    editor: React.lazy(() => import('components/cms/cmsComponents/Title/TitleEditor')),
    icon: 'header',
    editable: true,
    defaultProps: {},
  },
  TextImage: {
    component: TextImage,
    editor: React.lazy(() => import('components/cms/cmsComponents/TextImage/TextImageEditor')),
    elementEditor: React.lazy(() => import('components/cms/cmsComponents/TextImage/TextImageColumnEditor')),
    icon: 'newspaper',
    editable: true,
    defaultProps: {},
  },
  Divider: {
    component: Divider,
    editor: React.lazy(() => import('components/cms/cmsComponents/Divider/DividerEditor')),
    icon: 'cut',
    editable: true,
    defaultProps: {
      clearing: true,
      section: true,
    },
  },
  Slides: {
    component: Slides,
    editor: React.lazy(() => import('components/cms/cmsComponents/Slider/SlidesEditor')),
    elementEditor: React.lazy(() => import('components/cms/cmsComponents/Slider/SlideEditor')),
    icon: 'film',
    editable: true,
    defaultProps: {},
    getTemplate: function() {
      return {
        animate: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        pauseOnHover: true,
        size: 'medium',
        slidesToShow: 1,
        speed: 500,
      }
    },
  },
  Teaser: {
    component: Teaser,
    editor: React.lazy(() => import('components/cms/cmsComponents/Teaser/TeaserEditor')),
    icon: 'lightning',
    editable: true,
    defaultProps: {},
  },
  ProductList: {
    component: ProductList,
    editor: React.lazy(() => import('components/cms/cmsComponents/ProductList/ProductListEditor')),
    icon: 'list layout',
    editable: true,
    defaultProps: {},
    getTemplate: function() {
      return { withoutFamilyMembers: true }
    },
  },
  CategoryList: {
    component: CategoryList,
    editor: React.lazy(() => import('components/cms/cmsComponents/CategoryList/CategoryListEditor')),
    icon: 'grid layout',
    editable: true,
    defaultProps: {},
  },
  DealerList: {
    component: DealerList,
    icon: 'marker',
    editable: false,
    defaultProps: {},
  },
  Navigation: {
    component: Navigation,
    editor: React.lazy(() => import('components/cms/cmsComponents/Navigation/NavigationEditor')),
    icon: 'map signs',
    editable: true,
    defaultProps: {},
    getTemplate: function() {
      return {
        layout: {
          fullbleed: true,
        },
        as: 'Menu',
      }
    },
  },
  Video: {
    component: Video,
    editor: React.lazy(() => import('components/cms/cmsComponents/Video/VideoEditor')),
    icon: 'video play outline',
    editable: true,
    defaultProps: {},
    getTemplate: function() {
      return { url: 'https://www.youtube.com/watch?v=QPdWJeybMo8' }
    },
  },
  ContentSubnavi: {
    component: ContentSubnavi,
    editor: React.lazy(() => import('components/cms/cmsComponents/ContentSubnavi/ContentSubnaviEditor')),
    icon: 'mouse pointer',
    editable: true,
    defaultProps: {
      clearing: true,
      section: true,
    },
  },
  Cards: {
    component: Cards,
    editor: React.lazy(() => import('components/cms/cmsComponents/Cards/CardsEditor')),
    elementEditor: React.lazy(() => import('components/cms/cmsComponents/Cards/CardEditor')),
    text: 'Cards',
    icon: 'file text outline',
    editable: true,
    defaultProps: {},
  },
  ContactForm: {
    component: React.lazy(() => import('components/cms/cmsComponents/ContactForm/ContactForm')),
    editor: React.lazy(() => import('components/cms/cmsComponents/ContactForm/ContactFormEditor')),
    icon: 'comment',
    editable: true,
    defaultProps: {},
    getTemplate: function() {
      return {
        uuid: uuid.v4(),
        contentWidth: 4,
        formWidth: 4,
        addressWidth: 4,
      }
    },
  },
  GoogleMap: {
    component: GoogleMap,
    editor: React.lazy(() => import('components/cms/cmsComponents/GoogleMap/GoogleMapEditor')),
    icon: 'marker',
    editable: true,
    defaultProps: {},
    getTemplate: function() {
      return {
        size: 'small',
        url:
          'https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d2710.8328052495744!2d8.780118102364012!3d47.20028492905772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e3!4m5!1s0x479ab6ad5a8c53bf%3A0x3ce3f5c40c1d05cb!2sHuobstrasse+13%2C+Freienbach!3m2!1d47.2001698!2d8.7822631!4m3!3m2!1d47.2001771!2d8.782359699999999!5e0!3m2!1sen!2sch!4v1512334652438',
      }
    },
  },
  Advantages: {
    component: Advantages,
    editor: React.lazy(() => import('components/cms/cmsComponents/Advantages/AdvantagesEditor')),
    icon: 'trophy',
    editable: true,
    defaultProps: {},
    getTemplate: function() {
      return { spacing: true, grey: true }
    },
  },
  BlogRoll: {
    component: React.lazy(() => import('components/cms/cmsComponents/BlogRoll/BlogRoll')),
    editor: React.lazy(() => import('components/cms/cmsComponents/BlogRoll/BlogRollEditor')),
    icon: 'newspaper',
    editable: true,
    defaultProps: {},
  },
}
