import React from 'react'
import SemanticDivider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'

class Divider extends React.PureComponent {
  render() {
    const { hidden, compact, text } = this.props
    return (
      <Container>
        <SemanticDivider
          {...{
            content: !hidden && text,
            horizontal: !!text,
            section: !compact,
            hidden,
            clearing: true,
          }}
        />
      </Container>
    )
  }
}

export default Divider
