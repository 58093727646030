import MyMarkdown from 'components/cms/cmsComponents/MyMarkdown'
import AssetDownload from 'components/Helpers/AssetDownload'
import AssetImage from 'components/Helpers/AssetImage'
import NavigationLink from 'components/Navigation/NavigationLink'
import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import React from 'react'
import { connect } from 'react-redux'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'

const mapStateToProps = (state) => {
  const { products = [] } = state.shop
  return { products }
}

const mapDispatchToProps = undefined

class Teaser extends React.PureComponent {
  render() {
    const {
      asset_id: assetId,
      button = {},
      layout = {},
      heading,
      text,
      width = 4, // ownProps
      products,
      globalize: { language },
    } = this.props

    const segmentCSS = classNames([layout.fullbleed && 'fullbleed', layout.spacing && 'spacing', 'Teaser'])
    let node
    if (button.articleNumber) {
      const product = products.find((product) => product.articleNumber === button.articleNumber) || {}
      const { seoUrl = {} } = product
      node = `/product/${seoUrl[language]}/${button.articleNumber}`
    } else {
      node = button.node
    }

    const buttonProps = {
      secondary: button.type === 'secondary',
      primary: button.type === 'primary',
      icon: button.icon,
      content: button.title,
    }

    return (
      <Segment basic className={segmentCSS}>
        <Container>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={width} className="text">
                {heading && <Header content={heading} as="h2" />}
                {text && <MyMarkdown children={text} />}
                {button.title && node && !button.download_id && (
                  <NavigationLink node={node} component="Button" {...buttonProps} />
                )}
                {button.download_id && <AssetDownload id={button.download_id} button={buttonProps} />}
              </Grid.Column>
              <Grid.Column width={12 - width}>{assetId && <AssetImage id={assetId} fluid />}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
  }
}

Teaser = connect(mapStateToProps, mapDispatchToProps)(Teaser)
Teaser = globalize()(Teaser)
export default Teaser
