import setDetailsCheckout from 'components/Shop/Checkout/lib/setDetailsCheckout'
import { useGlobalize } from 'databinding/globalize'
import { hideSalesMessage } from 'databinding/shop/actions'
import classNames from 'lib/classNames'
import formatCHF from 'lib/formatCHF'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'

export default ({ floating }) => {
  const [amount, setAmount] = useState(0)
  const [cachedProductCount, setCachedProductCount] = useState(0)
  const [isFetching, setIsFetching] = useState(false)
  const orderCart = useSelector(({ shop: { orderCart } }) => orderCart)
  const products = useSelector(({ shop: { products } }) => products)
  const specialItems = useSelector(({ shop: { specialItems } }) => specialItems)
  const spareParts = useSelector(({ shop: { spareParts } }) => spareParts)
  const salesMessageHidden = useSelector(({ shop: { salesMessageHidden } }) => salesMessageHidden)
  const dispatch = useDispatch()
  const { translate } = useGlobalize()
  const hide = useCallback(() => dispatch(hideSalesMessage()), [dispatch])

  useEffect(() => {
    const cartProductCount = (((orderCart || {}).data || {}).products || []).reduce((q, p) => q + p.quantity, 0)
    const getAmount = async () => {
      setIsFetching(true)
      const discountCode = (((orderCart || {}).data || {}).discountCode || {}).code
      const cartProducts = await setDetailsCheckout(
        orderCart,
        products,
        spareParts,
        specialItems,
        translate,
        discountCode
      )
      const cartSum = cartProducts.reduce((q, p) => q + p.sum, 0)
      setCachedProductCount(cartProductCount)
      setIsFetching(false)
      if (cartSum >= 900 && cartSum < 1500) {
        setAmount(1500 - cartSum)
        return
      }
      setAmount(false)
    }
    if (!salesMessageHidden && cartProductCount !== cachedProductCount && !isFetching) getAmount()
  }, [cachedProductCount, orderCart, products, salesMessageHidden, spareParts, specialItems, translate, isFetching])
  return (
    !salesMessageHidden &&
    !!amount && (
      <Message className={classNames(['free-shipping', floating && 'floating'])} onDismiss={hide}>
        <div
          style={{ textAlign: 'center' }}
          dangerouslySetInnerHTML={{
            __html: translate(`global.shop.sales_message.text`, { amount: formatCHF(amount) }),
          }}
        />
      </Message>
    )
  )
}
