import logo from 'images/logo.svg'
import logoCompact from 'images/logoCompact.svg'
import React from 'react'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'

export default class Logo extends React.PureComponent {
  render() {
    // props
    const { compact, size } = this.props

    const imageProps = {
      className: 'logo',
      src: compact ? logoCompact : logo,
    }

    return <Image {...imageProps} size={size} />
  }
}
