import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'

const mapStateToProps = undefined

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ push }, dispatch)
}

class DropdownMenuItemsNavigation extends React.PureComponent {
  render() {
    const { routes, push } = this.props
    return routes.map((route, index) => {
      let returnValue = false
      if (route) {
        returnValue = (
          <Dropdown.Item
            {...{
              key: index,
              value: index,
              icon: route.icon,
              onClick: () => push(route.path),
              active: route.active,
              text: route.navigationTitle,
            }}
          />
        )
      } else {
        console.warn('Route is undefined')
      }
      return returnValue
    })
  }
}

DropdownMenuItemsNavigation = connect(mapStateToProps, mapDispatchToProps)(DropdownMenuItemsNavigation)
export default DropdownMenuItemsNavigation
