import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import NavigationLink from 'components/Navigation/NavigationLink'
import List from 'semantic-ui-react/dist/commonjs/elements/List'
import { useGlobalize } from 'databinding/globalize'

export default memo(({ categories }) => {
  const { language } = useGlobalize()
  const routes = useSelector(({ cms: { routes } }) => routes)
  return categories.map((category = {}) => {
    const route = routes.find((route) => route.id === category.id)
    if (!route) return false
    const breadCrumbs = route.node
      .split('/')
      .map((id) => {
        const route = routes.find((route) => route.id === +id)
        if (route) return route.navigationTitle[language] || route.title[language]
        return undefined
      })
      .filter((b) => !!b)
    const path = breadCrumbs.pop()
    return (
      breadCrumbs.length > 0 && (
        <List.Item key={category.id}>
          <span className="path" children={breadCrumbs.join(' \\ ')} />
          <NavigationLink node={route.node} children={path} />
        </List.Item>
      )
    )
  })
})
