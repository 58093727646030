import request from 'superagent'
import { loadJwtPublicKeyDone } from '../actions'
import loadCurrentUser from 'databinding/shop/thunks/loadCurrentUser'

export default () => async (dispatch) => {
  try {
    const response = await request.get(process.env.REACT_APP_SHOP_API + '/jwt_key.json')
    dispatch(loadJwtPublicKeyDone(response.body))
    dispatch(loadCurrentUser())
  } catch (e) {
    //todo redux: handle connection errors
    console.log(e)
  }
}
