import request from 'superagent'
import { updateCartDone, updateCartFailed } from 'databinding/shop/actions'
import { toCamel, toSnake } from 'convert-keys'

export default ({ cart }) =>
  async (dispatch, getState) => {
    try {
      dispatch(updateCartDone({ cart }))
      const { jwt: token } = getState().shop
      const { language } = getState().globalize
      const response = await request
        .patch(`${process.env.REACT_APP_SHOP_API}/carts/${cart.id}.json`, {
          cart: toSnake({
            ...cart,
            cartName: undefined,
            data: { ...cart.data, language, paymentType: cart.data.paymentType || 'ECA' },
          }),
        })
        .set('Authorization', `Bearer ${token}`)
      window.bc.postMessage('cart updated')
      dispatch(updateCartDone({ cart: toCamel({ ...response.body, cartName: cart.cartName, jwt: cart.jwt }) }))

      // todo redux: find out why this is needed
      // if (reload) dispatch(updateCartAdminDone({ cart: toCamel(response.body) }))
      // else dispatch(updateCartDone({ cart: toCamel({ ...response.body, cartName: cart.cartName, jwt: cart.jwt }) }))
    } catch (e) {
      //todo redux: handle connection errors
      dispatch(updateCartFailed({ error: toCamel(e.response.body) }))
    }
  }
