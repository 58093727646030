import SelectAssetModal from 'components/cms/AssetBrowser/SelectAssetModal'
import ConfirmationModal from 'components/Helpers/ConfirmationModal'
import Index from 'components/Index'
import { history, store } from 'databinding/store'
import detectIE from 'lib/detectIE'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { LastLocationProvider } from 'react-router-last-location'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer } from 'react-toastify'
import 'react-photoswipe-2/lib/photoswipe.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'styles/theme.sass'
import ErrorBoundary from 'ErrorBoundary'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ScrollToTop } from 'ScrollToTop'

export default () => {
  useEffect(() => {
    const version = detectIE()
    if (version !== false) {
      if (version >= 12) {
        document.body.className += ' less-evil-browser ie' + version
      } else {
        document.body.className += ' evil-browser ie' + version
      }
    }
  })

  return (
    <ErrorBoundary location="app-root">
      <Provider store={store}>
        <React.Fragment>
          <ToastContainer position="top-center" type="success" autoClose={2000} hideProgressBar={true} />
          <ConfirmationModal />
          <SelectAssetModal />
          <ConnectedRouter history={history}>
            <LastLocationProvider>
              <ScrollToTop>
                <DndProvider backend={HTML5Backend}>
                  <Index />
                </DndProvider>
              </ScrollToTop>
            </LastLocationProvider>
          </ConnectedRouter>
        </React.Fragment>
      </Provider>
    </ErrorBoundary>
  )
}
