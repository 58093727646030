import Logo from 'components/Navigation/Menu/Logo'
import NavigationLink from 'components/Navigation/NavigationLink'
import classNames from 'lib/classNames'
import React from 'react'

class MenuItemLogo extends React.PureComponent {
  render() {
    const { inverted, compact, showLogo } = this.props

    return (
      <NavigationLink
        component="Link"
        node="/index"
        className={classNames(['homeLink', !showLogo && 'hide-above-computer'])}>
        <Logo {...{ compact, inverted }} size="tiny" />
      </NavigationLink>
    )
  }
}

export default MenuItemLogo
