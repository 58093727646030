import translations from 'components/cms/AssetBrowser/_AssetBrowser.i18n.json'
import DropTarget from 'components/Helpers/DropTarget'
import { globalize } from 'databinding/globalize'
import React from 'react'
import { connect } from 'react-redux'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import uuid from 'uuid'
import createAsset from 'databinding/cms/thunks/createAsset'

const i18nKey = 'asset_browser'

const mapStateToProps = (state) => {
  const { uploadingAsset } = state.cms
  return { uploadingAsset }
}

class UploadButton extends React.PureComponent {
  render() {
    const { uploadingAsset, dispatch, globalize } = this.props
    const { translate } = globalize

    return (
      <DropTarget
        {...{
          multiple: true,
          onDrop: (files) => {
            //          console.debug('UploadButton.jsx - drop')
            if (files.length === 0) return // prevent drag on self
            const id = uuid.v4()
            dispatch(createAsset({ files, uuid: id }))
          },
        }}>
        <Button
          loading={uploadingAsset}
          icon={uploadingAsset ? 'spinner' : 'cloud upload'}
          content={uploadingAsset ? '' : translate(i18nKey + '.upload')}
          disabled={uploadingAsset}
          primary
        />
      </DropTarget>
    )
  }
}

UploadButton = connect(mapStateToProps)(UploadButton)
UploadButton = globalize({ translations })(UploadButton)
export default UploadButton
