import LinkItem from 'components/Navigation/renditions/children/LinkItem'
import MenuButton from 'components/Navigation/renditions/children/MenuButton'
import MenuItem from 'components/Navigation/renditions/children/MenuItem'
import { globalize } from 'databinding/globalize'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LinkRendition from './renditions/_Link'

const components = {
  Button: MenuButton,
  Link: LinkRendition,
  LinkItem,
  MenuItem,
}

const mapStateToProps = (state) => {
  const { routes } = state.cms
  return { routes }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

class NavigationLink extends React.PureComponent {
  render() {
    const {
      node,
      component = 'Link', //ownProps
      routes, //connect
      globalize: { language }, // globalize
      ...remainingProps
    } = this.props
    let path,
      navigationTitle,
      resultElement = false
    let parameters = {}
    const routeByNode = routes.find((route) => {
      let returnValue = true
      if (route.node === node) {
        returnValue = true
      } else {
        // find parameter nodes
        const routeNodeParts = route.node.split('/')
        const nodeParts = node.split('/')
        if (routeNodeParts.length !== nodeParts.length) {
          // no match if no length match
          returnValue = false
        } else {
          // check path elements for equality, omit parameters
          for (let i = 0; i < nodeParts.length; i++) {
            if (!routeNodeParts[i].match(/^:/) && nodeParts[i] !== routeNodeParts[i]) {
              returnValue = false
              break
            }
          }
          if (returnValue) {
            // save parameter values in object
            for (let i = 0; i < nodeParts.length; i++) {
              if (routeNodeParts[i].match(/^:/)) parameters[routeNodeParts[i]] = nodeParts[i]
            }
          }
        }
      }
      return returnValue
    })

    if (routeByNode) {
      ;({ path, navigationTitle } = routeByNode)
      if (!path || !path[language]) {
        console.error(`Undefined Language ${language} for node ${node}`)
      } else if (!navigationTitle || !navigationTitle[language]) {
        console.error(`Undefined navigationTitle ${language} for node ${node}`)
      } else {
        path = path[language]
        // substitute parameters in path
        const pathParts = path.split('/')
        path = pathParts.map((part) => (part.match(/^:/) ? parameters[part] : part)).join('/')
        resultElement = React.createElement(components[component], {
          path,
          navigationTitle: navigationTitle[language] || node,
          ...remainingProps,
        })
      }
    } else {
      console.warn(`Node not found: ${node}`)
    }
    return resultElement
  }
}

NavigationLink.propTypes = {
  node: PropTypes.string.isRequired,
  component: PropTypes.string,
}

NavigationLink = globalize({ exports: ['translate'] })(NavigationLink)
NavigationLink = connect(mapStateToProps, mapDispatchToProps)(NavigationLink)
export default NavigationLink
