import ContactInfo from 'components/Navigation/Footer/ContactInfo'
import SignupNewsletter from 'components/Navigation/Footer/SignUpNewsletter'
import SocialLinks from 'components/Navigation/Footer/SocialLinks'
import NavigationChildren from 'components/Navigation/NavigationChildren'
import { globalize } from 'databinding/globalize'
import logo from 'images/logo.svg'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import translations from './_Footer.i18n.json'
import NavigationLink from '../NavigationLink'

class NavigationFooter extends React.PureComponent {
  render() {
    const year = new Date().getFullYear()
    const {
      globalize: { translate },
    } = this.props
    return (
      <Segment basic className="NavigationFooter">
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Image src={logo} size="small" centered />
              </Grid.Column>
            </Grid.Row>
            <Divider hidden clearing />
            <Grid.Row>
              <Grid.Column mobile={6} tablet={3} computer={3}>
                <Header as="h3" content={translate('footer.nav.bartscher')} />
                <NavigationChildren component="LinkList" node="/bartscher" />
                <Header as="h3" content={translate('footer.nav.support')} />
                <NavigationChildren component="LinkList" node="/support" />
              </Grid.Column>
              <Grid.Column mobile={6} tablet={5} computer={6}>
                <Header as="h3" content={translate('footer.nav.categories')} />
                <NavigationChildren component="LinkList" node="/categories" horizontal />
                <Divider hidden />
                <NavigationLink
                  node="/blog"
                  children={({ navigationTitle }) => <Header as="h3" content={navigationTitle} />}
                />
                <Header as="h3" content={translate('footer.nav.newsletter')} />
                <SignupNewsletter />
                <Divider hidden />
                <SocialLinks />
              </Grid.Column>
              <Grid.Column mobile={12} tablet={4} computer={3} className="unobtrusive">
                <ContactInfo />
              </Grid.Column>
            </Grid.Row>
            <Divider hidden clearing />
            <Grid.Row>
              <Grid.Column width={12} className="unobtrusive">
                &copy; {translate('footer.copyright', { year: year })}, {translate('footer.prices')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
  }
}

NavigationFooter = globalize({ translations })(NavigationFooter)
NavigationFooter = withRouter(NavigationFooter)
export default NavigationFooter
