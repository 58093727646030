import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker'

ReactDOM.hydrate(<App />, document.getElementById('root'))
//registerServiceWorker();
unregister()

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(<NextApp />, document.getElementById('root'))
  })
}
