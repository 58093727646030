import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'

const mapStateToProps = undefined

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ push }, dispatch)
}

class CardNavigationHeader extends React.PureComponent {
  constructor(props) {
    super(props)
    this.push = this.push.bind(this)
  }

  push() {
    const {
      route, //ownProps
      push, //connect
    } = this.props
    push(route.path)
  }

  render() {
    const {
      content,
      route, //ownProps
    } = this.props

    return (
      <Card.Header
        as="a"
        className="ellipsis"
        children={content ? content : route.navigationTitle}
        path={route.path}
        onClick={this.push}
      />
    )
  }
}

CardNavigationHeader.propTypes = {
  route: PropTypes.object.isRequired,
}

CardNavigationHeader = connect(mapStateToProps, mapDispatchToProps)(CardNavigationHeader)
export default CardNavigationHeader
