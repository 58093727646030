import request from 'superagent'
import { saveAssetDone, saveAssetFailed } from '../actions'
import loadAssets from 'databinding/cms/thunks/loadAssets'
import { toSnake } from 'convert-keys'

export default ({ id, data }) =>
  async (dispatch, getState) => {
    try {
      const { jwt: token } = getState().shop
      await request
        .patch(`${process.env.REACT_APP_CMS_API}/assets/${id}.json`, toSnake({ asset: { data } }))
        .set('Authorization', `Bearer ${token}`)
      dispatch(saveAssetDone())
      dispatch(loadAssets())
    } catch (e) {
      dispatch(saveAssetFailed({ error: e.message }))
      //todo redux: handle connection errors
      console.log(e)
    }
  }
