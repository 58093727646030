import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'

const mapStateToProps = (state) => {
  const { routes } = state.cms
  return { routes }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

class LinkItem extends React.PureComponent {
  render() {
    const { path, navigationTitle, ...remainingProps } = this.props
    return (
      <Menu.Item active={true}>
        <RouterLink to={path} children={navigationTitle} {...remainingProps} />
      </Menu.Item>
    )
  }
}

LinkItem = connect(mapStateToProps, mapDispatchToProps)(LinkItem)
export default withRouter(LinkItem)
