export default () => ({
  components: {
    de: [
      { component: 'Title', title: 'Seite nicht gefunden' },
      {
        component: 'TextImage',
        columns: [
          {
            text: `
Es tut uns leid, diese Inhaltsseite konnte leider nicht gefunden werden.
Möglicherweise wurde sie deaktiviert, oder Sie sind einem veralteten Link gefolgt.

[Hier](/de/blog) gelangen Sie zur Übersichsseite`,
          },
        ],
      },
    ],
    fr: [
      { component: 'Title', title: 'Page non trouvée' },
      {
        component: 'TextImage',
        columns: [
          {
            text: `
Nous sommes désolés, cette page de contenu n'a pas pu être trouvée.
Elle a peut-être été désactivée, ou vous avez suivi un lien périmé.

[Ici](/fr/blog) vous arriverez à la page de présentation`,
          },
        ],
      },
    ],
  },
})
