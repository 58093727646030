import Item from 'semantic-ui-react/dist/commonjs/views/Item'
import { Link } from 'react-router-dom'
import ShopImage from 'components/Helpers/ShopImage'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { useGlobalize } from 'databinding/globalize'
import translations from 'components/Shop/Product/ProductList/_ProductList.i18n.json'
import Stock from 'components/Shop/Product/Stock'
import AddToCart from 'components/Shop/Product/AddToCart/AddToCart'

const i18nKey = 'product_list'

export default ({ product, highlightOptions, targetPath, allowCart }) => {
  const { language, translate } = useGlobalize({ translations })

  const isProductFamily = !!product.familyMemberIds
  return (
    <Item id={product.articleNumber} key={product.id}>
      <Link to={targetPath}>
        <ShopImage product={product} size='mini' className="product-image" />
      </Link>
      <Item.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={12}  tablet={7} computer={7} widescreen={7} largeScreen={7} className="ellipsis">
              {!isProductFamily && <Stock as='icon' stock={product.stock} />}
              <Link to={targetPath}>
                <Highlighter {...highlightOptions} textToHighlight={product.title[language]} />
              </Link>
              <div>
                <Link to={targetPath} children={` (${product.articleNumber})`} className='unobtrusive' />
              </div>
            </Grid.Column>
            <Grid.Column mobile={9}  tablet={3} computer={3} widescreen={3} largeScreen={3}>
              <div className='price'>
                {isProductFamily && translate(`${i18nKey}.from`)}
                {product.formattedPrice?.replace('.00', '.-') || ''}
                {product.originalPrice !== product.price && product.formattedPrice && (
                  <span className='discount'>
                    <span className='strike'>{product.formattedOriginalPrice.replace('.00', '.-')}</span>
                    {!!product.discountPercent && <Label tag size='mini' content={`- ${product.discountPercent} %`} />}
                    {!!product.discount2Percent && (
                      <Label tag color='orange' size='mini' content={`- ${product.discount2Percent} %`} />
                    )}
                  </span>
                )}
              </div>
            </Grid.Column>
            <Grid.Column mobile={3} tablet={2} computer={2} widescreen={2} largeScreen={2}>
              {allowCart && <AddToCart quickAdd product={product} style={{ float: 'right' }} />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Item.Content>
    </Item>
  )
}
