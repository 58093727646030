import ProductList from 'components/Shop/Product/ProductList/ProductList'
import { useGlobalize } from 'databinding/globalize'
import capitalize from 'capitalize'
import classNames from 'lib/classNames'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import List from 'semantic-ui-react/dist/commonjs/elements/List'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import transliterate from 'vendor/transliterate/transliterate'
import translations from './_SearchResults.i18n.json'
import SearchResultsCategories from './SearchResultsCategories'
import {
  consolidateProductFamilies,
  filterProductsByKeyword,
} from 'components/cms/cmsComponents/ProductList/lib/filterProducts'

export default memo(({ toggleSearch, filterValue }) => {
  const { translate, language } = useGlobalize({ translations })
  const productCategories = useSelector(({ shop: { productCategories } }) => productCategories)
  const products = useSelector(({ shop: { products } }) => (products || []).filter((p) => !p.clearance))

  const alphaNumericFilter = transliterate(filterValue).replace(/\W/g, '')
  const re = new RegExp(alphaNumericFilter, 'i')
  const filteredCategories = productCategories.filter((category = {}) => {
    const { [`title${capitalize(language)}`]: title = '' } = category
    return transliterate(title).replace(/\W/, '').match(re)
  })
  const filteredProducts = filterProductsByKeyword(products, filterValue, language)
  const productsWithEnrichedFamilies = consolidateProductFamilies(filteredProducts, products)

  // const categories =
  return (
    <Segment className={classNames(['open', 'search-results'])}>
      <Container>
        <Grid stackable relaxed>
          <Grid.Row>
            {filteredCategories.length > 0 && (
              <Grid.Column tablet={12} computer={4}>
                <div className="hide-below-tablet">
                  <Header dividing as="h4" content={translate('search.categories')} />
                  <List size="small" className="search-result-category-list" divided filter={filterValue}>
                    <SearchResultsCategories categories={filteredCategories} />
                  </List>
                  <Divider hidden section />
                </div>
              </Grid.Column>
            )}
            <Grid.Column tablet={12} computer={filteredCategories.length > 0 ? 8 : 12}>
              <ProductList
                layout={{toggle: true}}
                filterText={filterValue}
                inline
                headerSearch
                itemsPerRow={filteredCategories.length > 0 ? 3 : 4}
                heading={translate('search.your_search', {
                  query: filterValue,
                })}
                products={productsWithEnrichedFamilies}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Icon
        {...{
          link: true,
          name: 'close',
          size: 'large',
          onClick: toggleSearch,
        }}
      />
    </Segment>
  )
})
