import BlogEntryHeading from 'components/cms/cmsComponents/BlogEntryHeading/BlogEntryHeading'
import configuredComponents from 'config/components'
import React from 'react'

export default ({ currentPage, language }) => {
  const resultComponents = (((currentPage || {}).components || {})[language] || []).map((componentData, index) => {
    // the component definition in the config file with it's default properties
    const configComponent = configuredComponents[(componentData || {}).component] || {}

    // the real react component as defined in the class that will get rendered
    const reactComponent = configComponent.component
    if (reactComponent) {
      const props = {
        key: index,
        pageUUID: currentPage.uuid,
        ...configComponent.defaultProps,
        ...componentData,
      }
      return React.createElement(reactComponent, props)
    }
    return false
  })
  const { meta } = currentPage || {}
  if (meta && meta[language])
    resultComponents.unshift(
      <BlogEntryHeading key="blogEntryHeading" {...{ ...meta[language], createdAt: currentPage.created_at }} />
    )

  return resultComponents
}
