import { useSelector } from 'react-redux'
import React, { memo } from 'react'
import { useGlobalize } from 'databinding/globalize'
import { consolidateProductFamilies, filterProductsByKeyword } from './lib/filterProducts'
import ProductList from 'components/Shop/Product/ProductList/ProductList'

export default memo(({ articleNumbers, productCategoryIds, filter, withoutFamilyMembers, ...props }) => {
  const { language } = useGlobalize()
  const products = useSelector(({ shop: { products } }) => (products || []).filter((p) => !p.clearance))
  const productCategories = useSelector(({ shop: { productCategories } }) => productCategories)
  const collectedProducts = [
    ...(Array.isArray(articleNumbers) && articleNumbers.length > 0
      ? products.filter((p) => articleNumbers.includes(p.articleNumber))
      : []),
    ...(Array.isArray(productCategoryIds) && productCategoryIds.length > 0
      ? [
          ...new Set(
            productCategoryIds.map((pcId) => {
              const pc = productCategories.find((productCategory) => productCategory.id === pcId)
              // if (!pc) return []
              return (pc && pc.productIds) || []
            })
          ),
        ]
          .flat(1)
          .map((pId) => products.find((p) => p.id === pId))
          .filter((id) => id)
      : []),
  ]
  const filteredProducts = filter ? filterProductsByKeyword(collectedProducts, filter, language) : collectedProducts
  const resultProducts = withoutFamilyMembers
    ? consolidateProductFamilies(filteredProducts, products)
    : filteredProducts.filter((p) => !p.familyMemberIds)
  return <ProductList {...props} products={resultProducts} />
})
