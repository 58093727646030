import request from 'superagent'
import { replaceAssetDone, replaceAssetFailed } from '../actions'
import loadAssets from 'databinding/cms/thunks/loadAssets'
import { CREATE_ASSET } from 'databinding/cms/constants'

export default ({ file, id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_ASSET })
      const { jwt: token } = getState().shop
      await request
        .put(`${process.env.REACT_APP_CMS_API}/assets/${id}.json`)
        .set('Authorization', `Bearer ${token}`)
        .attach(file.name, file)
      dispatch(replaceAssetDone())
      dispatch(loadAssets())
    } catch (e) {
      dispatch(replaceAssetFailed({ error: e.message }))
      //todo redux: handle connection errors
      console.log(e)
    }
  }
