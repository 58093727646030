import { push } from 'connected-react-router'

export default ({ node, params }) =>
  async (dispatch, getState) => {
    const { routes = [] } = getState().cms
    const { language } = getState().globalize
    const route = routes.find((route) => route.node === node)
    const path = route && typeof route.path === 'object' && route.path[language]
    if (path) {
      if (params && typeof params === 'object') {
        const pathWithParams = Object.entries(params).reduce(
          (path, [key, value]) => path.replace(':' + key, value),
          path
        )
        dispatch(push(pathWithParams))
      } else {
        dispatch(push(path))
      }
    } else {
      console.warn('Node not found in routes: ', node)
    }
  }
