import { useGlobalize } from 'databinding/globalize'
import React from 'react'
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'

const colorMap = {
  'Winestorage.svg': {
    'A+++': '#009641',
    'A++': '#52AE32',
    'A+': '#C8D300',
    A: '#FFED00',
    B: '#FBBA00',
    C: '#EC6608',
    D: '#E30613',
    E: '#E30613',
    F: '#E30613',
    G: '#E30613',
  },
  'Fridge_7classes.svg': {
    'A+++': '#009641',
    'A++': '#52AE32',
    'A+': '#C8D300',
    A: '#FFED00',
    B: '#FBBA00',
    C: '#EC6608',
    D: '#E30613',
    E: '#E30613',
    F: '#E30613',
    G: '#E30613',
  },
  'Refrigeration.svg': {
    'A+++': '#009641',
    'A++': '#52AE32',
    'A+': '#C8D300',
    A: '#FFED00',
    B: '#FBBA00',
    C: '#EC6608',
    D: '#E30613',
    E: '#E30613',
    F: '#E30613',
    G: '#E30613',
  },
}

export default ({ properties = [], articleNumber, position }) => {
  const { language } = useGlobalize()
  if (properties.length > 0) {
    const energyProp = properties.find((property) => property.key === 'energieeffizienzklasse') || {}
    const { value = {} } = energyProp
    const { [language]: energyClass } = value
    const labelProperty = properties.find((property) => property.key === 'energieeffizienzlabel') || {}
    const { value: labelValue = {} } = labelProperty
    const energyLabelType = labelValue.de

    if (energyClass && energyLabelType) {
      const trigger = (
        <Label
          tag
          attached="bottom right"
          className="energy"
          style={{ backgroundColor: colorMap[energyLabelType][energyClass], zIndex: 2 }}
          content={energyClass}
        />
      )
      const src = `${process.env.REACT_APP_SHOP_API}/products/${articleNumber}/eel.svg`
      const content = <Image size="medium" src={src} />
      return <Popup {...{ content, position, trigger, size: 'mini' }} />
    }
  }
  return null
}
