export default ({ stock, alwaysOnStock = false, translate }) => {
  const i18nKey = 'global.shop.inventory'

  let color, text, stockText

  if (alwaysOnStock) {
    text = translate(`${i18nKey}.always_on_stock`)
    color = 'green'
  } else if (stock <= 0 || stock === undefined) {
    text = translate(`${i18nKey}.no_stock`)
    color = 'orange'
  } else if (stock > 0 && stock <= 5) {
    color = 'olive'
    text = translate(`${i18nKey}.stock_1-5`, { stock })
  } else if (stock > 5 && stock <= 10) {
    color = 'green'
    text = translate(`${i18nKey}.stock_6-10`)
  } else if (stock > 10) {
    color = 'green'
    text = translate(`${i18nKey}.stock_11_plus`)
    // } else {
    //  color = 'orange'
    //  text = translate(`${i18nKey}.no_data`)
  }
  stockText = translate(`${i18nKey}.stock`)
  return { color, text, stockText }
}
