import classNames from 'lib/classNames'
import PropTypes from 'prop-types'
import React from 'react'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'

class Loader extends React.PureComponent {
  render() {
    const { style, loading = true, transparent } = this.props

    return (
      <Segment
        style={style}
        loading={loading}
        attached
        basic
        className={classNames([transparent && 'transparent', 'placeholder'])}
      />
    )
  }
}

Loader.propTypes = {
  data: PropTypes.string,
  title: PropTypes.string,
  inactive: PropTypes.bool,
  loading: PropTypes.bool,
}

export default Loader
