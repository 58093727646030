import CardNavigation from 'components/Navigation/renditions/CardNavigation'
import DropdownMenuItemsNavigation from 'components/Navigation/renditions/DropdownMenuItemsNavigation'
import DropdownNavigation from 'components/Navigation/renditions/DropdownNavigation'
import LinkListNavigation from 'components/Navigation/renditions/LinkListNavigation'
import MenuItemsNavigation from 'components/Navigation/renditions/MenuItemsNavigation'
import StepNavigation from 'components/Navigation/renditions/StepNavigation'
import { globalize } from 'databinding/globalize'
import buildRoutes from 'lib/buildRoutes'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const components = {
  Cards: CardNavigation,
  Dropdown: DropdownNavigation,
  DropdownMenuItems: DropdownMenuItemsNavigation,
  LinkList: LinkListNavigation,
  Steps: StepNavigation,
  MenuItems: MenuItemsNavigation,
  Menu: MenuItemsNavigation,
}

const mapStateToProps = (state) => {
  const { routes = [] } = state.cms
  const { currentUser = {} } = state.shop
  return { routes, currentUser }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

// TODO: @andreas: Consolidate Siblings and Children
class NavigationChildren extends React.PureComponent {
  // TODO: @andreas: method unneeded, move to render
  buildRoutes() {
    const {
      node,
      level,
      path,
      routes,
      currentUser,
      location: { pathname }, //withRouter
      globalize: { language }, // globalize
    } = this.props
    // TODO: @andreas: review. what is path? why overwrite?
    return buildRoutes({ node, level, pathname, language, path, routes, currentUser })
  }

  render() {
    // Do not remove unused imports!
    //noinspection JSUnusedLocalSymbols
    const { component, routes, level, globalize, currentUser, ...remainingProps } = this.props // eslint-disable-line no-unused-vars

    let renderValue = false
    if (components[component]) {
      const routes = this.buildRoutes()
      renderValue = React.createElement(components[component], {
        ...remainingProps,
        component,
        routes,
      })
    } else {
      console.warn(`NavigationChildren: Component "${component}" is not defined.`)
    }
    return renderValue
  }
}

NavigationChildren.propTypes = {
  component: PropTypes.string.isRequired, // The rendition the navigation is to be displayed as
  node: PropTypes.string.isRequired, // The node the children are to be displayed of
  path: PropTypes.string, // A path the children are to be shown of
  level: PropTypes.number, // The level of the child path
  inactive: PropTypes.bool, // just render navigation, not active
}

NavigationChildren = globalize()(NavigationChildren)
NavigationChildren = connect(mapStateToProps, mapDispatchToProps)(NavigationChildren)
NavigationChildren = withRouter(NavigationChildren)
export default NavigationChildren
