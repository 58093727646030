import { globalize } from 'databinding/globalize'
import iconMedal from './icon-medal.svg'
import iconPresent from './icon-present.svg'
import iconTag from './icon-tag.svg'
import iconVan from './icon-van.svg'
import classNames from 'lib/classNames'
import React from 'react'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import translations from './_Advantages.i18n.json'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'

const i18nKey = 'advantages.'

class Advantages extends React.PureComponent {
  render() {
    const {
      grey,
      spacing,
      globalize: { translate }, // globalize
    } = this.props

    const classesContainer = classNames(['advantages', spacing && 'spacing-after', grey && 'grey'])
    const columns = {
      className: 'ellipsis',
      mobile: 12,
      tablet: 3,
      computer: 3,
    }
    return (
      <Segment basic className={classesContainer}>
        <Container className="advantages">
          <Grid textAlign="center" className="unobtrusive" relaxed divided>
            <Grid.Row>
              <Grid.Column {...columns}>
                <Image src={iconMedal} size="tiny" />
                <br />
                {translate(i18nKey + 'guarantee')}
              </Grid.Column>
              <Grid.Column {...columns}>
                <Image src={iconVan} size="tiny" />
                <br />
                {translate(i18nKey + 'shipping')}
              </Grid.Column>
              <Grid.Column {...columns}>
                <Image src={iconTag} size="tiny" />
                <br />
                {translate(i18nKey + 'pickup')}
              </Grid.Column>
              <Grid.Column {...columns}>
                <Image src={iconPresent} size="tiny" />
                <br />
                {translate(i18nKey + 'handling')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
  }
}

Advantages = globalize({ translations })(Advantages)
export default Advantages
