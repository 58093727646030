import request from 'superagent'
import { deleteAssetDone, deleteAssetFailed } from 'databinding/cms/actions'
import loadAssets from 'databinding/cms/thunks/loadAssets'

export default ({ id }) =>
  async (dispatch, getState) => {
    try {
      const { jwt: token } = getState().shop
      await request.delete(`${process.env.REACT_APP_CMS_API}/assets/${id}.json`).set('Authorization', `Bearer ${token}`)
      dispatch(deleteAssetDone())
      dispatch(loadAssets())
    } catch (e) {
      dispatch(deleteAssetFailed({ error: e.message }))
      console.error(e)
    }
  }
