import request from 'superagent'
import { loadCartDone, loadCartFailed } from 'databinding/shop/actions'
import { toCamel } from 'convert-keys'

export default ({ id, jwt, cartName }) =>
  async (dispatch, getState) => {
    try {
      const { jwt: token } = getState().shop
      const response = await request
        .get(`${process.env.REACT_APP_SHOP_API}/carts/${id}.json${jwt ? `?cart[jwt]=${jwt}` : ''}`)
        .set('Authorization', `Bearer ${token}`)
      dispatch(loadCartDone({ cart: { ...toCamel(response.body), cartName, jwt } }))
    } catch (e) {
      //todo redux: handle connection errors
      dispatch(loadCartFailed({ error: toCamel(e.response.body) }))
    }
  }
