import config from 'config/config'
import deepMerge from 'deepmerge'
import template from 'es6-template-strings'
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

export const globalize = (configParams = { translations: {} }) => {
  //  const { exports = [] } = params;
  return (WrappedComponent) => {
    class GlobalizeHOC extends React.Component {
      constructor(props) {
        super(props)
        this.siteTranslate = this.siteTranslate.bind(this)
        this.adminTranslate = this.adminTranslate.bind(this)
      }

      siteTranslate(key, params) {
        const { language } = this.props
        return this.translate(key, params, language)
      }

      adminTranslate(key, params) {
        const { adminLanguage } = this.props
        return this.translate(key, params, adminLanguage)
      }

      translate(key, params, language) {
        const { translations = {} } = this.props
        const mergedTranslations = deepMerge(translations, configParams.translations)
        const result = `${language}.${key}`.split('.').reduce((nestedObject, key) => {
          let returnValue = undefined
          if (nestedObject && key in nestedObject) {
            if (typeof nestedObject[key] === 'string') {
              returnValue = template(nestedObject[key], params)
            } else {
              returnValue = nestedObject[key]
            }
          }
          return returnValue
        }, mergedTranslations)
        return result || `${language}.${key}`
      }

      render() {
        // noinspection JSUnusedLocalSymbols
        const {
          language,
          adminLanguage,
          availableLanguages,
          loadTranslations,
          setLanguage,
          setAvailableLanguages,
          translations,
          ...remainingParameters
        } = this.props //eslint-disable-line no-unused-vars
        let exporting = {
          language,
          adminLanguage,
          availableLanguages,
          loadTranslations,
          setLanguage,
          setAvailableLanguages,
          translate: this.siteTranslate,
          adminTranslate: this.adminTranslate,
        }

        return <WrappedComponent {...remainingParameters} globalize={exporting} />
      }
    }

    return connect(
      (state) => {
        const { language, adminLanguage, availableLanguages, translations } = state.globalize
        return { language, adminLanguage, availableLanguages, translations }
      },
      (dispatch) =>
        bindActionCreators(
          {
            loadTranslations,
            setLanguage,
            setAvailableLanguages,
          },
          dispatch
        )
    )(GlobalizeHOC)
  }
}

const LOAD_TRANSLATIONS = '@@globalize/LOAD_TRANSLATIONS'
const SET_LANGUAGE = '@@globalize/SET_LANGUAGE'
const SET_AVAILABLE_LANGUAGES = '@@globalize/SET_AVAILABLE_LANGUAGES'
const loadTranslations = (translations) => ({
  type: LOAD_TRANSLATIONS,
  translations,
})
const setLanguage = (language) => ({ type: SET_LANGUAGE, language })
const setAvailableLanguages = (availableLanguages) => ({
  type: SET_AVAILABLE_LANGUAGES,
  availableLanguages,
})

// TODO: @andreas: Set admin language on user login
export const reducer = (state = { adminLanguage: config.cms.language }, action) => {
  switch (action.type) {
    case LOAD_TRANSLATIONS:
      return { ...state, translations: action.translations }
    case SET_LANGUAGE:
      return { ...state, language: action.language }
    case SET_AVAILABLE_LANGUAGES:
      return { ...state, availableLanguages: action.availableLanguages }
    default:
      return state
  }
}

const translate = (key, params, language, translations) => {
  const result = `${language}.${key}`.split('.').reduce((nestedObject, key) => {
    if (nestedObject && key in nestedObject) {
      if (typeof nestedObject[key] === 'string') return template(nestedObject[key], params)
      else return nestedObject[key]
    }
    return undefined
  }, translations)
  return result || `${language}.${key}`
}

export const useGlobalize = (configParams = { translations: {} }) => {
  const dispatch = useDispatch()
  const { language, adminLanguage, availableLanguages, translations } = useSelector(({ globalize }) => globalize)
  const mergedTranslations = deepMerge(translations, configParams.translations)
  return {
    language,
    adminLanguage,
    availableLanguages,
    loadTranslations: (translations) => dispatch(loadTranslations(translations)),
    translate: (key, params) => translate(key, params, language, mergedTranslations),
    adminTranslate: (key, params) => translate(key, params, adminLanguage, mergedTranslations),
    setLanguage: (language) => dispatch(setLanguage(language)),
    setAvailableLanguages: (languages) => dispatch(setAvailableLanguages(languages)),
  }
}
