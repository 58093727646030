import getLanguagePathByNode from 'lib/getLanguagePathByNode'
import { useSelector } from 'react-redux'
import { useGlobalize } from 'databinding/globalize'
import translations from './_ProductList.i18n.json'
import React from 'react'
import CardItem from './CardItem'
import ListItem from './ListItem'
import ListItemSmall from './ListItemSmall'

export default ({ product, filterText, showAs }) => {
  const routes = useSelector(({ cms: { routes } }) => routes)
  const { language } = useGlobalize({ translations })
  const { seoUrl = {} } = product
  const targetPath =
    product.targetPath ||
    getLanguagePathByNode({
      routes,
      language,
      node: `/product/${seoUrl[language]}/${product.articleNumber}`,
    })
  const highlightMatch = new RegExp(
    (filterText || '')
      .replace(/\W/g, '')
      .replace(/(.)/g, '$1\\W*')
      .replace(/[aA]/g, '[a\u00e0-\u00e5]')
      .replace(/[cC]/g, '[c\u00e7]')
      .replace(/[eE]/g, '[e\u00e8-\u00eb]')
      .replace(/[iI]/g, '[i\u00ec-\u00ef]')
      .replace(/[oO]/g, '[o\u00f2-\u00f6]')
      .replace(/[uU]/g, '[u\u00f9-\u00fc]')
  )
  const highlightOptions = {
    highlightClassName: 'highlight',
    searchWords: [highlightMatch],
  }

  const allowCart = !(product.specialStock || {}).kind && !!!product.familyMemberIds && !product.selectableCount && product.status !== 'archived'

  switch (showAs) {
    case 'small':
    return <ListItemSmall {...{ product, highlightOptions, targetPath, allowCart }}  />
    case 'list':
      return <ListItem {...{ product, highlightOptions, targetPath, allowCart }} />
    default:
      return <CardItem {...{ product, highlightOptions, targetPath, allowCart }} />
  }

  // if (showAsList) {
  //   if (size === 'small') return <Grid verticalAlign="middle" divided="vertically" className="products small" children={children} />
  //   else return <ListItem {...{ product, targetPath }} />
  // } else return  <CardItem {...{ product, targetPath }} />
}
