import translations from 'components/cms/AssetBrowser/_AssetBrowser.i18n.json'
import AssetListImage from 'components/cms/AssetBrowser/AssetListImage'
import TitleFields from 'components/cms/AssetBrowser/TitleFields'
import ComponentHeader from 'components/cms/Helper/ComponentHeader'
import DropTarget from 'components/Helpers/DropTarget'
import ReduxFormTagsSimple from 'components/Helpers/ReduxForm/ReduxFormTagsSimple'
import { useGlobalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import darkTheme from 'lib/darkTheme'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import replaceAsset from 'databinding/cms/thunks/replaceAsset'
import saveAsset from 'databinding/cms/thunks/saveAsset'
import { compose } from 'recompose'

const i18nKey = 'asset_browser.editor'

export default compose(reduxForm({ form: 'assetEditor' }))(
  ({ editingAssetId, setEditingAssetID, handleSubmit, initialize }) => {
    const dispatch = useDispatch()
    const { translate } = useGlobalize({ translations })
    const assets = useSelector(({ cms: { assets } }) => assets)
    const routes = useSelector(({ cms: { routes } }) => routes)
    const uploadingAsset = useSelector(({ cms: { uploadingAsset } }) => uploadingAsset)
    const editingAsset = assets.find((asset) => asset.id === editingAssetId) || {}

    const submit = useCallback(
      (formValues) => {
        dispatch(saveAsset({ id: editingAssetId, data: formValues }))
        setEditingAssetID(undefined)
      },
      [dispatch, editingAssetId, setEditingAssetID]
    )

    useEffect(() => {
      if (editingAsset) initialize(editingAsset?.data || {})
    }, [editingAsset, initialize])

    const dark = darkTheme()
    const languageRoute = routes.find((route) => route.node === '/languages') || {}
    const { path = {} } = languageRoute
    const languages = Object.keys(path)

    return (
      <div className={classNames(['assetBrowser', 'ui', 'form', dark && 'inverted'])}>
        <ComponentHeader
          inline
          title={`${editingAsset.fileName} (${editingAsset.contentType})`}
          fluid={true}
          customActions={[
            <Button
              content={translate(`${i18nKey}.abort`)}
              onClick={() => setEditingAssetID(undefined)}
              icon="cancel"
              secondary
            />,
            <Button
              content={translate(`${i18nKey}.save`)}
              onClick={handleSubmit(submit)}
              icon="checkmark"
              color="green"
            />,
          ]}
        />

        <Segment className={classNames(['payload', 'small', dark && 'inverted'])}>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={6}>
                <DropTarget
                  {...{
                    multiple: false,
                    onDrop: (files) => {
                      if (files.length === 0) return // prevent drag on self
                      dispatch(replaceAsset({ id: editingAssetId, file: files[0] }))
                    },
                  }}>
                  <AssetListImage asset={editingAsset} rendition="large" />
                  <Button
                    attached="bottom"
                    fluid
                    color="grey"
                    size="tiny"
                    loading={uploadingAsset}
                    icon={uploadingAsset ? 'spinner' : 'refresh'}
                    content={`${uploadingAsset ? '' : translate(i18nKey + '.replace')}`}
                    disabled={uploadingAsset}
                  />
                </DropTarget>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as="h4" dividing content={translate(`${i18nKey}.titles`)} />
                <TitleFields languages={languages} />
                <Field
                  component={ReduxFormTagsSimple}
                  placeholder={'keywords'}
                  name={'tags'}
                  allowNew
                  autofocus={false}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    )
  }
)
