import get404 from './get404'

export default (pages, currentNode, id, language) => {
  if (pages.length === 0) return {}
  const page = pages.find((page = {}) => page.node === currentNode)
  if (page) return page
  const blogPage = pages.find((page = {}) => page.uuid === id) || {}
  if (((blogPage.meta || {})[language] || {}).active) return blogPage
  if (pages.length > 0) return get404()
  return {}
}
