import MyMarkdown from 'components/cms/cmsComponents/MyMarkdown'
import NavigationChildren from 'components/Navigation/NavigationChildren'
import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
const mapStateToProps = (state) => {
  const { routes } = state.cms
  return { routes }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

class CategoryList extends React.PureComponent {
  render() {
    const {
      layout = {},
      itemsPerRow = 4,
      heading,
      text,
      node, //ownProps
    } = this.props

    const segmentCSS = classNames([layout.fullbleed && 'grey', layout.spacing && 'spacing', 'CategoryList'])
    return (
      <Segment basic className={segmentCSS}>
        <Container>
          {heading && <Header content={heading} as="h2" />}
          {text && <MyMarkdown className="description" children={text} />}
          <Divider hidden clearing />
          <NavigationChildren component="Cards" className="product-cards" node={node} itemsPerRow={itemsPerRow} />
        </Container>
      </Segment>
    )
  }
}

CategoryList = connect(mapStateToProps, mapDispatchToProps)(CategoryList)
CategoryList = globalize()(CategoryList)

export default CategoryList
