import config from 'config/config'
import { globalize } from 'databinding/globalize'
import React from 'react'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import translations from './_Footer.i18n.json'

class SignupNewsletter extends React.PureComponent {
  render() {
    const {
      globalize: { translate, language },
    } = this.props
    if (!config.campaignMonitor || !config.campaignMonitor[language]) return false
    const { fieldName, dataId } = config.campaignMonitor
    return (
      !!fieldName &&
      !!dataId && (
        <Form
          id="subForm"
          className="js-cm-form"
          action="https://www.createsend.com/t/subscribeerror?description="
          method="post"
          data-id={dataId}>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                <Form.Input
                  id="fieldEmail"
                  name={fieldName}
                  placeholder={translate('contact.email')}
                  type="email"
                  className="js-cm-email-input"
                  required
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Button
                  className="js-cm-submit-button"
                  type="submit"
                  primary
                  fluid
                  icon="checkmark"
                  // content={translate('newsletter.signup')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )
    )
  }
}

SignupNewsletter = globalize({ translations })(SignupNewsletter)
export default SignupNewsletter
