import CardNavigationItem from 'components/Navigation/renditions/children/CardNavigationItem'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'

class CardNavigation extends React.PureComponent {
  getItems() {
    const { routes } = this.props

    return routes.map((route, index) => {
      return <CardNavigationItem key={index} route={route} />
    })
  }

  render() {
    // noinspection JSUnusedLocalSymbols Do not remove unused imports!
    const { routes, history, location, match, selection, staticContext, node, push, ...remainingProps } = this.props //eslint-disable-line no-unused-vars

    const cards = this.getItems()

    return (
      cards &&
      cards.length > 0 && (
        <div className="card-navigation">
          <Card.Group stackable doubling {...remainingProps} children={cards} />
          <Divider hidden section />
        </div>
      )
    )
  }
}

CardNavigation = withRouter(CardNavigation)
export default CardNavigation
