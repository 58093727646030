import { globalize } from 'databinding/globalize'
import React from 'react'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import translations from './_ReduxForm.i18n.json'

class ReduxFormInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(event, comp) {
    const { input } = this.props
    input.onChange(comp.value)
  }

  render() {
    const {
      input,
      meta: { touched, error },
      label,
      globalize: { translate },
      showErrors,
      ...remainingParameters
    } = this.props

    return (
      <Form.Input
        error={error && (touched || showErrors)}
        name={input.name}
        label={
          error && (touched || showErrors)
            ? (label ? label + ' – ' : '') + translate('form.errors.' + error.toLowerCase().replace(' ', '_'))
            : label
        }
        value={input.value}
        onChange={this.onChange}
        onBlur={input.onBlur}
        {...remainingParameters}
      />
    )
  }
}

ReduxFormInput = globalize({ translations })(ReduxFormInput)
export default ReduxFormInput
