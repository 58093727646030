import MyMarkdown from 'components/cms/cmsComponents/MyMarkdown'
import AssetImage from 'components/Helpers/AssetImage'
import NavigationLink from 'components/Navigation/NavigationLink'
import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'

const mapStateToProps = (state) => {
  const { products = [] } = state.shop
  return { products }
}

const mapDispatchToProps = undefined

class CardItems extends React.PureComponent {
  render() {
    const {
      cards = [],
      reordering,
      dragMarker = {},
      products,
      globalize: { language },
    } = this.props
    return cards.map((card = {}, index) => {
      let dragIndicator
      let isDragging
      if (reordering) {
        if (dragMarker.index === index) {
          dragIndicator = dragMarker.position === 'dragBelow' ? 'dragRight' : 'dragLeft'
        }
        if (dragMarker.draggedIndex === index) {
          isDragging = 'isDragging'
        }
      }
      let node
      if (card.articleNumber) {
        const product = products.find((product) => product.articleNumber === card.articleNumber) || {}
        const { seoUrl = {} } = product
        node = `/product/${seoUrl[language]}/${card.articleNumber}`
      } else {
        node = card.node
      }
      return (
        <Card fluid key={index} className={classNames([dragIndicator, isDragging])}>
          {card.asset_id && <AssetImage id={card.asset_id} fluid />}
          <Card.Content>
            {card.header && <Card.Header children={card.header} />}
            {card.meta && <Card.Meta children={card.meta} />}
            {card.description && (
              <Card.Content extra>
                <MyMarkdown children={card.description} />
              </Card.Content>
            )}
          </Card.Content>
          {card.extra && (
            <Card.Content extra>
              <MyMarkdown children={card.extra} />
            </Card.Content>
          )}
          {node && (
            <Card.Content
              extra
              children={
                <NavigationLink
                  secondary={card.buttonType === 'secondary'}
                  primary={card.buttonType === 'primary'}
                  node={node}
                  component="Button"
                  children={card.title}
                />
              }
            />
          )}
        </Card>
      )
    })
  }
}

CardItems.propTypes = {
  itemsPerRow: PropTypes.number,
  width: PropTypes.number,
  doubling: PropTypes.bool,
  stackable: PropTypes.bool,
  cards: PropTypes.array,
  reordering: PropTypes.bool,
  dragMarker: PropTypes.object,
}

CardItems = connect(mapStateToProps, mapDispatchToProps)(CardItems)
CardItems = globalize()(CardItems)
export default CardItems
