import CardItems from 'components/cms/cmsComponents/Cards/CardItems'
import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import PropTypes from 'prop-types'
import React from 'react'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'

class Cards extends React.PureComponent {
  render() {
    const {
      itemsPerRow = 4,
      width = 12,
      doubling = true,
      stackable = true,
      cards = [],
      reordering,
      dragMarker, //ownProps
    } = this.props
    const classesContainer = classNames(['advantages'])
    return (
      <Container>
        <Grid stackable centered className={classesContainer}>
          <Grid.Row>
            <Grid.Column width={width}>
              <Card.Group {...{ doubling, stackable, itemsPerRow }}>
                <CardItems {...{ cards, reordering, dragMarker }} />
              </Card.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

Cards.propTypes = {
  itemsPerRow: PropTypes.number,
  width: PropTypes.number,
  doubling: PropTypes.bool,
  stackable: PropTypes.bool,
  cards: PropTypes.array,
  reordering: PropTypes.bool,
  dragMarker: PropTypes.object,
}
Cards = globalize()(Cards)
export default Cards
