// eslint-disable-next-line
import Worker from 'worker-loader!databinding/worker/worker'

const worker = new Worker()

export default ({ translate, i18nKey, ...params }) =>
  new Promise((res) => {
    const translations = { price_on_request: translate(`${i18nKey}.price_on_request`) }
    const token = localStorage.getItem('jwt')
    worker.addEventListener('message', (e) => res(e.data))
    worker.postMessage({ action: 'getDetails', translations, token, ...params })
  })
