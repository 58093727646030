import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGlobalize } from 'databinding/globalize'
import translations from 'components/Shop/Product/ProductList/_ProductList.i18n.json'
import { useLocation } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'
import getCurrentRoute from 'lib/getCurrentRoute'
import { clearScrollPosition, storeScrollPosition } from 'databinding/shop/actions'
import { history } from 'databinding/store'
import getDetails from 'databinding/shop/getDetails'

const i18nKey = 'product_list'

const getScrollPosition = (lastPath, currentPath, routes, scrollPositions) => {
  const lastRoute = getCurrentRoute(routes, lastPath)
  if (lastRoute && lastRoute.component === 'ProductDetail') {
    return scrollPositions[currentPath]
  }
}

export const useHooks = ({ products, as, headerSearch }) => {
  const [showAsList, setShowAsList] = useState(as === 'list')
  const toggleViewStyle = useCallback(() => setShowAsList(!showAsList), [showAsList])
  const dispatch = useDispatch()
  const { translate } = useGlobalize({ translations })
  const [displayCount, setDisplayCount] = useState(36)
  const [productsWithPrice, setProductsWithPrice] = useState([])
  const [displayedProducts, setDisplayedProducts] = useState(productsWithPrice.slice(0, displayCount))
  const [scrollPosition, setScrollPosition] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const { pathname: currentPath } = useLocation()
  const lastLocation = useLastLocation()

  const currentUser = useSelector(({ shop: { currentUser } }) => currentUser)
  const orderCart = useSelector(({ shop: { orderCart } }) => orderCart)
  const scrollPositions = useSelector(({ shop: { scrollPositions } }) => scrollPositions)
  const routes = useSelector(({ cms: { routes } }) => routes)

  useEffect(() => setProductsWithPrice([]), [currentUser.id])

  const discountCode = (((orderCart || {}).data || {}).discountCode || {}).code

  // make sure to adapt, when the product list changes (can get smaller when searching)
  useEffect(() => {
    setDisplayedProducts(
      products.slice(0, displayCount).map((p) => productsWithPrice.find((dp) => dp.id === p.id) || p)
    )
  }, [products, productsWithPrice, displayCount])

  const increaseDisplayedProducts = useCallback(() => {
    setDisplayedProducts(productsWithPrice.slice(0, displayCount + 36))
    setDisplayCount(displayCount + 36)
  }, [displayCount, productsWithPrice])

  // restore scroll position
  useEffect(() => {
    if (lastLocation) {
      const scrollPosition = getScrollPosition(lastLocation.pathname, currentPath, routes, scrollPositions)
      if (scrollPosition) {
        setDisplayCount(scrollPosition.displayItems || 36)
        setScrollPosition(scrollPosition)
        setShowAsList(scrollPosition.showAsList)
      }
    }
  }, [currentPath, lastLocation, routes, scrollPositions])

  const storeHistory = useCallback(
    ({ pathname: nextPath }) => {
      const nextRoute = getCurrentRoute(routes, nextPath)
      if (nextRoute && nextRoute.component === 'ProductDetail') {
        const pathParts = nextPath.split('/')
        const articleNumber = pathParts.pop()
        dispatch(storeScrollPosition(currentPath, displayCount, articleNumber, showAsList))
      } else {
        dispatch(clearScrollPosition(currentPath))
      }
    },
    [currentPath, displayCount, routes, dispatch, showAsList]
  )

  // when a scroll position is set, scroll there after a little delay
  useEffect(() => {
    // don't scroll if product is not displayed
    if (!scrollPosition || !displayedProducts.find((p) => p.articleNumber === scrollPosition.articleNumber)) return
    const element = document.getElementById(scrollPosition.articleNumber)
    if (element) {
      const headerOffset = 100
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition - headerOffset
      document.body.scrollTo(0, offsetPosition)
    }
    setScrollPosition(null)
    dispatch(clearScrollPosition(currentPath))
    // }, 200)
  }, [dispatch, scrollPosition, currentPath, displayCount, displayedProducts])

  // add history listener
  useEffect(() => {
    if (headerSearch) return
    const cleanup = history.listen(storeHistory)
    return () => cleanup()
  }, [storeHistory, headerSearch])

  // fetch missing prices
  useEffect(() => {
    if (isFetching) return
    const fetchMissingProducts = async () => {
      const productsToLoad = products
        .slice(0, displayCount)
        .filter((p) => !productsWithPrice.find((pwp) => pwp.id === p.id))
      if (productsToLoad.length === 0) return
      setIsFetching(true)
      const { products: fetchedProductsWithPrice } = await getDetails({
        products: productsToLoad,
        translate,
        i18nKey,
        details: false,
        discountCode,
      })
      const newProductsWithPrice = [...productsWithPrice, ...fetchedProductsWithPrice]
      setProductsWithPrice(newProductsWithPrice)
      setDisplayedProducts(
        products.slice(0, displayCount).map((p) => newProductsWithPrice.find((dp) => dp.id === p.id) || p)
      )
      setIsFetching(false)
    }
    fetchMissingProducts().then()
  }, [discountCode, isFetching, translate, products, productsWithPrice, displayCount])
  return { showAsList, displayedProducts, increaseDisplayedProducts, toggleViewStyle, displayCount, translate }
}
