import locale from 'browser-locale'
import ProductRedirect from 'components/Shop/ProductRedirect'
import NavigationWrapper from 'components/Navigation/NavigationWrapper'
import config from 'config/config'
import { globalize } from 'databinding/globalize'
import translations from 'databinding/i18n.json'
import { clearCart, userLoginDone, userLogout } from 'databinding/shop/actions'
import JWT from 'jsonwebtoken'
import getCurrentRoute from 'lib/getCurrentRoute'
import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import Routes from 'Routes'
import loadRoutes from 'databinding/cms/thunks/loadRoutes'
import loadAssets from 'databinding/cms/thunks/loadAssets'
import loadPages from 'databinding/cms/thunks/loadPages'
import loadProducts from 'databinding/shop/thunks/loadProducts'
import loadJwtPublicKey from 'databinding/shop/thunks/loadJwtPublicKey'
import loadProductCategories from 'databinding/shop/thunks/loadProductCategories'
import loadDealers from 'databinding/shop/thunks/loadDealers'
import loadProductProperties from 'databinding/shop/thunks/loadProductProperties'
import loadSpecialItems from 'databinding/shop/thunks/loadSpecialItems'
import userRefresh from 'databinding/shop/thunks/userRefresh'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import PaymentRedirect from 'components/Shop/PaymentRedirect'
import loadCurrentUser from '../databinding/shop/thunks/loadCurrentUser'
import loadCart from '../databinding/shop/thunks/loadCart'
import { BroadcastChannel } from 'broadcast-channel'

const mapStateToProps = (state) => {
  const { usersError, categoryRoutesError, jwt, jwtPublicKey, jwtError, orderCart = {} } = state.shop
  const { textsError, slidersError, sectionsError, assetsError, routes = [] } = state.cms
  return {
    usersError,
    categoryRoutesError,
    textsError,
    slidersError,
    sectionsError,
    assetsError,
    routes,
    jwt,
    jwtPublicKey,
    jwtError,
    orderCart,
  }
}

window.bc = new BroadcastChannel('bartscher.ch')

class Index extends React.PureComponent {
  constructor (props) {
    super(props)
    window.addEventListener('storage', this.handleLocalStorageChange.bind(this))
    window.bc.onmessage = this.handleBroadcastMessage.bind(this)
  }

  componentDidMount () {
    const { dispatch, routes = [] } = this.props
    const { loadTranslations, language } = this.props.globalize

    const intervalId = setInterval(this.refreshAuthentication.bind(this), 1800 * 1000)
    this.setState({ intervalId: intervalId })

    if (routes.length === 0) {
      dispatch(loadRoutes())
    } else {
      if (!language) {
        this.setLanguage(routes)
      }
    }
    dispatch(loadJwtPublicKey())
    dispatch(loadAssets())
    dispatch(loadPages())
    dispatch(loadProducts())
    dispatch(loadSpecialItems())
    dispatch(loadProductCategories())
    dispatch(loadDealers())
    dispatch(loadProductProperties())

    if (routes.length === 0) {
      loadTranslations(translations)
    }
  }

  componentDidUpdate (prevProps) {
    const {
      assetsError: oldAssetsError,
      usersError: oldUsersError,
      textsError: oldTextsError,
      slidersError: oldSlidersError,
      sectionsError: oldSectionsError,
      productsError: oldProductsError,
      categoryRoutesError: oldCategoryRoutesError,
      routesError: oldRoutesError,
      jwt: oldJwt,
    } = prevProps
    const {
      assetsError: newAssetsError,
      usersError: newUsersError,
      textsError: newTextsError,
      slidersError: newSlidersError,
      sectionsError: newSectionsError,
      productsError: newProductsError,
      categoryRoutesError: newCategoryRoutesError,
      routesError: newRoutesError,
      routes: newRoutes = [],
      globalize: { language },
      jwt: newJwt,
      jwtError: newJwtError,
    } = this.props

    if (oldAssetsError !== newAssetsError && newAssetsError) {
      toast.error(newAssetsError.error)
    }
    if (oldUsersError !== newUsersError && newUsersError) {
      toast.error(newUsersError.error)
    }
    if (oldTextsError !== newTextsError && newTextsError) {
      toast.error(newTextsError.error)
    }
    if (oldSlidersError !== newSlidersError && newSlidersError) {
      toast.error(newSlidersError.error)
    }
    if (oldSectionsError !== newSectionsError && newSectionsError) {
      toast.error(newSectionsError.error)
    }
    if (oldProductsError !== newProductsError && newProductsError) {
      toast.error(newProductsError.error)
    }
    if (oldCategoryRoutesError !== newCategoryRoutesError && newCategoryRoutesError) {
      toast.error(newCategoryRoutesError.error)
    }

    if (oldRoutesError !== newRoutesError && newRoutesError) {
      toast.error(
        <div>
          <div className='errorHeader'>Loading Routes failed</div>
          <div className='errorURL'>{newRoutesError.url}</div>
          <div className='errorMessage'>{newRoutesError.error}</div>
        </div>,
        { autoClose: false },
      )
    }
    if (!language && newRoutes.length > 0) {
      this.setLanguage(newRoutes)
    }

    // if a new jwt came in, put it in local storage
    if (newJwt && oldJwt !== newJwt && !newJwtError) {
      localStorage.setItem('jwt', newJwt)
    }

    // TODO: Check why this was deleted
    // const { userType } = decodedToken
    //
    // // redirect user if permissions don't allow them to see current page
    // const route = getCurrentRoute(routes, pathname)
    //
    // if (route.allowedUserTypes) {
    //   if (userType) {
    //     if (!route.allowedUserTypes.includes(userType)) {
    //       toast.warn('Sie wurden zur Startseite umgeleitet, weil Sie eine unerlaubte URL aufgerufen haben')
    //       push('/fallback') // push to fallback route
    //     }
    //   } else {
    //     pushNode({ node: '/user/login/required' })
    //   }
    // }
  }

  componentWillUnmount () {
    clearInterval(this.state.intervalId)
  }

  refreshAuthentication () {
    const { dispatch, jwt } = this.props
    if (jwt) {
      dispatch(userRefresh())
    }
  }

  handleLocalStorageChange () {
    const { dispatch, jwt } = this.props
    const newJWT = localStorage.getItem('jwt')
    if (jwt !== newJWT) {
      if (newJWT) {
        dispatch(userLoginDone({ jwt: newJWT }))
        dispatch(loadCurrentUser())
      } else {
        dispatch(userLogout())
      }
    }
    this.loadCart()
  }

  handleBroadcastMessage (event) {
    // if (event.data === 'cart updated') this.loadCart(true)
  }

  loadCart (force = false) {
    const { dispatch, orderCart, jwtPublicKey } = this.props
    const orderCartToken = localStorage.getItem('orderCartToken')
    if (orderCartToken) {
      if (jwtPublicKey) {
        try {
          const decodedKey = JWT.verify(orderCartToken, jwtPublicKey)
          const id = decodedKey.cartId
          if (orderCart.id !== id || force) {
            const cartName = 'order'
            dispatch(loadCart({ id, jwt: orderCartToken, cartName }))
          }
        } catch (err) {
          console.error('orderCartToken was not valid', err)
          dispatch(clearCart({ cartName: 'order' }))
        }
      }
    } else {
      dispatch(clearCart({ cartName: 'order' }))
    }
  }

  setLanguage (routes) {
    const {
      location: { pathname },
      globalize: { setAvailableLanguages, setLanguage },
    } = this.props
    const languageRoute = routes.find((route) => route.node === '/languages')
    const availableLanguages = languageRoute ? Object.keys(languageRoute.path) : []
    let languageToUse

    if (availableLanguages.length === 0) {
      console.warn('No languages available! Is node /languages set in database?')
    } else {
      setAvailableLanguages(availableLanguages)

      // if there is already a location matching a language, prefer that one
      Object.entries(languageRoute.path).forEach(([language, path]) => {
        if (pathname.startsWith(path)) {
          languageToUse = language
        }
      })
      // If not set, user Browser locale
      if (!languageToUse) {
        languageToUse = locale()
      }
      // If browser locale isn't in available languages, set it to first language
      if (!availableLanguages.includes(languageToUse)) {
        languageToUse = availableLanguages[0]
      }
      setLanguage(languageToUse)
    }
  }

  render () {
    const {
      routes,
      jwt,
      jwtPublicKey, //connect
      location: { pathname }, // withRouter
      globalize: { translate, language },
    } = this.props

    let returnValue = false
    const route = getCurrentRoute(routes, pathname) || {}
    const { title = {} } = route
    if (routes.length > 0 && language)
      returnValue = (
        <NavigationWrapper>
          <Helmet htmlAttributes={{ lang: language }}>
            {!((route.node || '').startsWith('/blog/')) && <title children={`${title[language]} - ${config.company.name[language]}`} /> }
            <meta name="description" content={translate('meta_description')} />
            <meta httpEquiv="Content-Language" content={language} />
          </Helmet>
          <React.Suspense fallback={<Loader />}>
            <Routes />
          </React.Suspense>
        </NavigationWrapper>
      )
    let decodedToken = {}
    // check jwt and logout if not valid
    if (jwt && jwtPublicKey) {
      try {
        decodedToken = JWT.verify(jwt, jwtPublicKey)
      } catch (err) {
        console.debug('User redirected!')
      }
    }
    const { userType } = decodedToken

    if (route.allowedUserTypes) {
      if (userType) {
        if (!route.allowedUserTypes.includes(userType)) {
          returnValue = false
        }
      } else {
        returnValue = false
      }
    }

    if (route.component === 'ProductRedirect')
      returnValue = <Route path='/p/:articleNumber' component={ProductRedirect} />
    if (route.component === 'PaymentRedirect')
      returnValue = <Route path="/redirect_payment/:cartId/:language" component={PaymentRedirect} />

    return returnValue
  }
}

Index = connect(mapStateToProps)(Index)
Index = globalize()(Index)
Index = withRouter(Index)
export default Index
