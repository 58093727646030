import ComponentHeaderButton from 'components/cms/Helper/ComponentHeaderButton'
import qodo from 'components/cms/images/qodo-flat.svg'
import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import darkTheme from 'lib/darkTheme'
import PropTypes from 'prop-types'
import React from 'react'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
class ComponentHeader extends React.PureComponent {
  getHeaderButtons() {
    const { actions } = this.props
    return actions.map((action, index) => <ComponentHeaderButton key={index} {...{ action }} />)
  }

  getHeaderButtonsMobile() {
    const { actions } = this.props
    return actions.map((action, index) => (
      <Button
        {...{
          key: index,
          as: action.as,
          target: action.target,
          href: action.href,
          icon: action.icon,
          inverted: action.inverted,
          color: action.color,
          onClick: action.onClick,
          secondary: action.secondary,
          disabled: action.disabled,
          primary: action.primary,
          className: classNames(['icon', 'hide-above-computer', 'componentButton']),
          size: 'large',
        }}
      />
    ))
  }

  getHeaderCells() {
    const { customActions } = this.props
    return (
      customActions &&
      customActions.map((customAction, index) => (
        <Table.Cell verticalAlign="middle" textAlign="right" collapsing key={index} children={customAction} />
      ))
    )
  }

  render() {
    const {
      globalize: { translate },
    } = this.props

    const dark = darkTheme()

    const { title, actions, disabled, dirty, type, icon, inline, footer } = this.props

    const componentTitle = title + (dirty ? (title && ' - ') + translate('global.unsaved_changes') : '')

    return (
      <Segment
        inverted={!inline}
        attached={footer ? 'bottom' : 'top'}
        disabled={disabled}
        className={classNames(['componentHeader', 'noselect', dirty && 'dirty', type, dark && 'inverted'])}>
        <Table basic="very" singleLine unstackable>
          <Table.Body>
            <Table.Row>
              {icon && (
                <Table.Cell verticalAlign="middle" collapsing className="status">
                  <Icon {...icon} />
                </Table.Cell>
              )}
              <Table.Cell verticalAlign="middle">
                {componentTitle === 'qodo' ? (
                  <Image src={qodo} size="tiny" className="qodo-brand" />
                ) : (
                  <Header as="h5" inverted={!inline} content={componentTitle} />
                )}
              </Table.Cell>
              {this.getHeaderCells()}
              {actions && (
                <Table.Cell verticalAlign="middle" collapsing textAlign="right">
                  <Button.Group>
                    {this.getHeaderButtons()}
                    {this.getHeaderButtonsMobile()}
                  </Button.Group>
                </Table.Cell>
              )}
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    )
  }
}

ComponentHeader.propTypes = {
  title: PropTypes.string.isRequired,
  //dirty:        PropTypes.bool, // set state unsaved changes
  customActions: PropTypes.array,
  actions: PropTypes.array,
}

ComponentHeader = globalize()(ComponentHeader)
export default ComponentHeader
