import NavigationChildren from 'components/Navigation/NavigationChildren'
import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  const { currentUser = { userType: 'visitor' } } = state.shop
  return { currentUser }
}

class MenuItemAccount extends React.PureComponent {
  render() {
    const { currentUser, ...remainingProps } = this.props //connect
    return (
      currentUser.userType !== 'visitor' && (
        <NavigationChildren component="MenuItems" node={`/account/${currentUser.userType}`} {...remainingProps} />
      )
    )
  }
}

MenuItemAccount = connect(mapStateToProps)(MenuItemAccount)
export default MenuItemAccount
