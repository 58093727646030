import classNames from 'lib/classNames'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

class MenuItem extends React.PureComponent {
  render() {
    // noinspection JSUnusedLocalSymbols Do not remove unused imports!
    const { path, navigationTitle, sidebar, responsive, icon, dispatch, ...remainingProps } = this.props // eslint-disable-line no-unused-vars
    return (
      <Menu.Item>
        <RouterLink to={path} {...remainingProps}>
          {icon && <Icon name={icon} />}
          <span className={classNames([responsive && 'hide-below-tablet'])}>{navigationTitle}</span>
        </RouterLink>
      </Menu.Item>
    )
  }
}

//export default withRouter(MenuItem);
export default MenuItem
