import placeholder from 'images/placeholder_bar.png'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import classNames from 'lib/classNames'

export default ({ children, onDrop, accept, ...remainingProps }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept })
  return (
    <div {...getRootProps()} {...remainingProps} className={classNames(['dropZone', isDragActive ? 'valid' : ''])}>
      <input {...getInputProps()} />
      {children ? children : <Image src={placeholder} />}
    </div>
  )
}
