import { loadPagesDone } from '../actions'
// eslint-disable-next-line
import Worker from 'worker-loader!databinding/worker/worker'
import JWT from 'jsonwebtoken'

const worker = new Worker()

const getAdminToken = () => {
  try {
    const jwt = localStorage.getItem('jwt')
    const decodedToken = JWT.decode(jwt)
    if (decodedToken.userType === 'admin') return jwt
  } catch (e) {}
  return undefined
}

export default () => (dispatch) => {
  worker.addEventListener('message', (e) => {
    if (e.data.success) dispatch(loadPagesDone({ pages: e.data.body }))
    else console.log('pages could not be loaded', e.data)
  })
  const adminToken = getAdminToken()
  worker.postMessage({
    action: 'get',
    url: process.env.REACT_APP_CMS_API + '/pages.json',
    convert: false,
    ...(adminToken && {auth: `Bearer ${adminToken}`})
  })
}
