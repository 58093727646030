import config from 'config/config'
import { globalize } from 'databinding/globalize'
import React from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'

/*
call like this (now independent from redux and redux-form)
import { confirmationModal } from 'ConfirmationModal';

confirmationModal({
   icon:    icon,   // icon object, see config.js
   text:    text,   // modal content (optional)
   title:   title,  // modal title
   button:  button, // button Text
   confirm:  action
});

*/

class confirmationModalProxy {
  static update() {
    console.warn('update undefined')
  }

  static register(callback) {
    this.update = callback
  }
}

export const confirmationModal = (modal) => {
  confirmationModalProxy.update({ ...modal, initialized: true })
}

class ConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.confirm = this.confirm.bind(this)
    this.resetState = this.resetState.bind(this)
    this.state = {
      initialized: false,
      confirm: () => console.warn('No confirmation action configured!'),
    }
  }

  componentDidMount() {
    confirmationModalProxy.register((modal) => this.setState(modal))
  }

  resetState() {
    this.setState({
      initialized: false,
      icon: undefined,
      title: undefined,
      text: undefined,
      negative: undefined,
      button: undefined,
      confirm: () => console.warn('No confirmation action configured!'),
    })
  }

  confirm() {
    const { confirm } = this.state
    confirm()
    this.resetState()
  }

  render() {
    const { icon, title, text, negative, button, initialized } = this.state
    const {
      globalize: { translate }, // globalize
    } = this.props
    return (
      <Modal open={initialized} size="small" basic>
        <Header as="h1">
          <Icon {...icon} />
          {title}
        </Header>
        <Modal.Content content={text} />
        <Modal.Actions>
          <Button
            {...{
              inverted: true,
              onClick: this.resetState,
              content: translate('global.cancel'),
              icon: config.icons.modalCancel,
            }}
          />
          <Button
            negative={negative}
            positive={!negative}
            //icon={modal.negative ? 'cancel' : 'checkmark'}
            icon="checkmark"
            labelPosition="right"
            content={button || translate('global.yes')}
            onClick={this.confirm}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

ConfirmationModal = globalize()(ConfirmationModal)
export default ConfirmationModal
