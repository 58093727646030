import request from 'superagent'
import { toCamel, toSnake } from 'convert-keys'
import { createCartDone } from '../actions'

export default ({ cart }) =>
  async (dispatch, getState) => {
    try {
      const { jwt: token } = getState().shop
      const { language } = getState().globalize
      const req = request.post(
        `${process.env.REACT_APP_SHOP_API}/carts.json`,
        toSnake({ cart: { ...cart, cartName: undefined, language } })
      )
      if (token) req.set('Authorization', `Bearer ${token}`)
      const response = await req
      dispatch(createCartDone({ cart: toCamel({ ...response.body, cartName: cart.cartName }) }))
    } catch (e) {
      //todo redux: handle connection errors
      console.log(e)
    }
  }
