import transliterate from 'vendor/transliterate/transliterate'

const getPreferredProduct = (familyProduct, allProducts) => {
  const preferredProduct = allProducts.find((product = {}) => product.id === familyProduct.preferredProductId)
  if (preferredProduct) return preferredProduct
  for (const id of familyProduct.familyMemberIds) {
    const product = allProducts.find((product = {}) => product.id === id)
    if (product) return product
  }
}

export const consolidateProductFamilies = (products, allProducts) => {
  const familyMemberIds = products.map((categoryProduct = {}) => categoryProduct.familyMemberIds).flat(1)
  const productsWithoutFamilyMembers = products.filter((product = {}) => !familyMemberIds.includes(product.id))
  return productsWithoutFamilyMembers.map((product) => {
    if (Array.isArray(product.familyMemberIds) && product.familyMemberIds.length > 0) {
      const preferredProduct = getPreferredProduct(product, allProducts)
      if (preferredProduct) {
        return {
          ...product,
          id: preferredProduct.id,
          articleNumber: preferredProduct.articleNumber,
          assets: Array.isArray(product.assets) && product.assets.length > 0 ? product.assets : preferredProduct.assets,
        }
      }
      return null
    }
    return product
  }).filter(p => !!p)
}

export const filterProductsByKeyword = (products, filter, language) => {
  const alphaNumericFilter = transliterate(filter).replace(/\W/g, '')
  const re = new RegExp(alphaNumericFilter, 'i')
  return products.filter(
    (product = {}) =>
      transliterate(product.title[language]).replace(/\W/g, '').match(re) ||
      product.articleNumber.replace(/\W/g, '').match(re) ||
      (product.keywords &&
        product.keywords[language] &&
        product.keywords[language].find((keyword) => keyword.match(re)))
  )
}
