import Slide from 'components/cms/cmsComponents/Slider/Slide'
import classNames from 'lib/classNames'
import React from 'react'
import Slider from 'react-slick'
// import 'slick-carousel/slick/slick-theme.scss'
// import 'slick-carousel/slick/slick.scss'

class Slides extends React.PureComponent {
  state = {
    isChanging: false,
  }

  getSlideItems() {
    const { slides = [] } = this.props //ownProps
    return slides.map((slide, index) => <Slide key={index} slide={slide} />)
  }

  render() {
    const { isChanging } = this.state

    const {
      animate = true,
      arrows = true,
      autoplay = true, // ownProps
      autoplaySpeed = 5000,
      dots = true,
      infinite = true,
      pauseOnHover = true,
      size = 'medium',
      slidesToShow = 1,
      speed = 500,
    } = this.props //ownProps

    const cssClasses = classNames([
      size,
      slidesToShow > 1 && 'inline',
      isChanging && 'changing',
      'no-padding',
      'noselect',
      'slider-wrapper',
    ])

    const animation = animate && {
      beforeChange: () => this.setState({ isChanging: true }),
      afterChange: () => this.setState({ isChanging: false }),
    }

    return (
      <div className={cssClasses}>
        <Slider
          {...{
            ...animation,
            infinite,
            arrows,
            dots,
            pauseOnHover,
            speed,
            autoplaySpeed,
            useCSS: true,
            autoplay,
            slidesToShow,
            slidesToScroll: 1,
            children: this.getSlideItems(),
          }}
        />
      </div>
    )
  }
}

export default Slides
