import NavigationChildren from 'components/Navigation/NavigationChildren'
import classNames from 'lib/classNames'
import React from 'react'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'

class Navigation extends React.PureComponent {
  render() {
    const {
      layout = {},
      node,
      as = 'MenuItems', //ownProp
    } = this.props // from redux
    const segmentCSS = classNames([layout.fullbleed && 'fullbleed', layout.spacing && 'spacing', 'Teaser'])
    return !node ? (
      false
    ) : (
      <Segment basic className={segmentCSS}>
        <Container>
          <NavigationChildren component={as} node={node} />
        </Container>
      </Segment>
    )
  }
}

export default Navigation
