import classNames from 'lib/classNames'
import darkTheme from 'lib/darkTheme'
import PropTypes from 'prop-types'
import React from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

class Pagination extends React.PureComponent {
  constructor(props) {
    super(props)
    this.decreasePageNumber = this.decreasePageNumber.bind(this)
    this.increasePageNumber = this.increasePageNumber.bind(this)
  }

  decreasePageNumber() {
    const { setPageNumber, currentPage } = this.props
    setPageNumber(currentPage - 1)
  }

  increasePageNumber() {
    const { setPageNumber, currentPage } = this.props
    setPageNumber(currentPage + 1)
  }

  render() {
    const { currentPage, totalPages } = this.props
    const dark = darkTheme()
    return (
      <Button.Group size="tiny">
        <Button
          icon="chevron left"
          className={classNames([dark && 'inverted'])}
          disabled={currentPage === 0}
          onClick={this.decreasePageNumber}
        />
        <Button disabled className={classNames([dark && 'inverted'])} content={currentPage + 1 + ' / ' + totalPages} />
        <Button
          icon="chevron right"
          className={classNames([dark && 'inverted'])}
          disabled={currentPage + 1 === totalPages}
          onClick={this.increasePageNumber}
        />
      </Button.Group>
    )
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  setPageNumber: PropTypes.func,
}

export default Pagination
