import AssetListImage from 'components/cms/AssetBrowser/AssetListImage'
import EditDropdown from 'components/cms/AssetBrowser/EditDropdown'
import PropTypes from 'prop-types'
import React from 'react'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'

class AssetListItems extends React.PureComponent {
  render() {
    const { defaultAction } = this.context
    const {
      assets = [], //ownProps
    } = this.props
    return assets.map((asset = {}) => {
      return (
        <Table.Row key={asset.id} asset={asset} onClick={() => defaultAction(asset)}>
          <Table.Cell>
            <AssetListImage asset={asset} />
          </Table.Cell>
          <Table.Cell className="unobtrusive" verticalAlign="middle" children={asset.contentType} />
          <Table.Cell textAlign="right">
            <EditDropdown {...{ asset }} />
          </Table.Cell>
        </Table.Row>
      )
    })
  }
}

AssetListItems.contextTypes = {
  defaultAction: PropTypes.func,
}

export default AssetListItems
