import { dirname } from 'path'

/**
 * Either provide a node
 * @param node The node we want to get the routes from
 * Or a
 * @param level The depth beginning t
 * @param pathname the current path name
 * @param language
 * @param routes all routes from state
 * @return Array of {
 *   active: Is the route active
 *   navigationTitle: in the selected language
 *   title: in the selected language
 *   node: node of the child route
 *   path: path of the child route
 *   icon: a small picture, for the rest of us
 * }
 */
export default ({ node, level, pathname, language, routes, currentUser = {} }) => {
  const resultRoutes = routes
    .filter((route) => dirname(route.node) === node)
    .filter((route) => !route.allowedUserTypes || route.allowedUserTypes.includes(currentUser.userType))

  return resultRoutes.map((route) => {
    let splitPath,
      shortenedPath,
      returnValue = false
    const { navigationTitle, title, node, path, icon } = route
    let active = path[language] === pathname
    if (level) {
      splitPath = pathname.split('/')
      shortenedPath = splitPath.slice(0, Number(level) + 2).join('/')
      active = path[language] === shortenedPath
    }

    if (!path || !path[language]) {
      console.error(`Undefined Language ${language} for node ${route.node}`)
    } else if (!navigationTitle || !navigationTitle[language]) {
      console.error(`Undefined navigationTitle ${language} for node ${route.node}`)
    } else {
      returnValue = {
        navigationTitle: navigationTitle[language],
        title: title[language],
        path: path[language],
        active,
        icon,
        node,
      }
    }
    return returnValue
  }) // TODO: @andreas: weed out undefined
}
