import { globalize } from 'databinding/globalize'
import React from 'react'
import translations from '../_Shop.i18n.json'
import getStock from '../lib/getStock'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

class Stock extends React.PureComponent {
  render () {
    const { labelsOnly, stock, alwaysOnStock, globalize, as = 'label' } = this.props
    const { translate } = globalize

    const { color, text, stockText } = getStock({ stock, alwaysOnStock, translate })

    if (as === 'label') {
      return (
        <div className='stock unobtrusive'>
          {!labelsOnly && stockText} <Label color={color} content={text} size='small' />
        </div>
      )
    }
    if (as === 'icon')
      return (
        <Popup
          content={stockText + text}
          trigger={<Icon name="circle" color={color}/>}
          size="tiny"
          wide
          style={{ borderRadius: '4px' }}
        />
      )
  }
}

Stock = globalize({ translations })(Stock)
export default Stock
