import formatCHF from 'lib/formatCHF'
import roundCHF from 'lib/roundCHF'
import getDetails from 'databinding/shop/getDetails.js'

const i18nKey = 'checkout.cart.'

export default async (cart, allProducts, allSpareParts, specialItems, translate, discountCode) => {
  const products = (((cart || {}).data || {}).products || [])
    .map((cartProduct, index) => ({ ...cartProduct, index }))
    .filter((cartProduct) => cartProduct.kind === 'product' || cartProduct.kind === 'specialItem')
    .map((cartProduct) => ({
      ...cartProduct,
      ...(allProducts.find((product) => product.articleNumber === cartProduct.articleNumber) || {}),
    }))
  const spareParts = (((cart || {}).data || {}).products || [])
    .map((cartProduct, index) => ({ ...cartProduct, index }))
    .filter((cartProduct) => cartProduct.kind === 'sparePart')
    .map((cartProduct) => ({
      ...cartProduct,
      ...(allSpareParts.find((sparePart) => sparePart.articleNumber === cartProduct.articleNumber) || {}),
    }))
  const { products: productsWithPrice = [], spareParts: sparePartsWithPrice = [] } = await getDetails({
    products,
    spareParts,
    translate,
    i18nKey,
    details: true,
    discountCode,
  })
  const result = (((cart || {}).data || {}).products || []).map((cartProduct, index) => {
    if (cartProduct.kind === 'product') return productsWithPrice.find((p) => p.index === index) || cartProduct
    else if (cartProduct.kind === 'sparePart') return sparePartsWithPrice.find((s) => s.index === index) || cartProduct
    else if (cartProduct.kind === 'specialItem') {
        const product = productsWithPrice.find((p) => p.index === index) || cartProduct
        const kind = cartProduct.specialItemKind
        return {
          ...product,
          kind: 'specialItem',
          specialItemKind: kind,
          stock: product.specialStock[kind].quantity,
          formattedPrice: formatCHF(roundCHF(product.specialStock[kind].price)),
          formattedSum: formatCHF(roundCHF(product.specialStock[kind].price * cartProduct.quantity)),
          price: product.specialStock[kind].price,
          sum: product.specialStock[kind].price * cartProduct.quantity,
          discountPercent: 0,
          discount2Percent: 0,
        }
      }
    return cartProduct
  })
  return result
}
