import getComponentsForPage from 'components/cms/lib/getComponentsForPage'
import getCurrentPage from 'components/cms/lib/getCurrentPage'
import Loader from 'components/Helpers/Loader'
import { globalize } from 'databinding/globalize'
import getCurrentNode from 'lib/getCurrentNode'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import ErrorBoundary from 'ErrorBoundary'

const EditablePage = React.lazy(() => import('components/cms/EditablePage'))

const mapStateToProps = (state, props) => {
  const { currentUser = { userType: 'visitor' } } = state.shop
  const { pages = [], routes = [] } = state.cms
  if (routes.length === 0 || pages.length === 0) return {}
  const { path } = props.match
  const { id } = props.match.params
  const { language } = props.globalize
  const currentPage = getCurrentPage(pages, getCurrentNode(routes, path), id, language)
  return { currentUser, currentPage, routes }
}

class Page extends React.PureComponent {
  render() {
    const { language } = this.props.globalize
    const { currentPage, currentUser = {} } = this.props

    if (currentUser.userType === 'admin') return <EditablePage />
    const myComponents = getComponentsForPage({ currentPage, language })
    if (myComponents)
      return (
        <ErrorBoundary location="page">
          <Segment basic children={myComponents} />
        </ErrorBoundary>
      )
    return <Loader />
  }
}

Page = connect(mapStateToProps)(Page)
Page = globalize()(Page)
Page = withRouter(Page)
export default Page
