import { useSelector } from 'react-redux'
import { usePrevious } from 'lib/usePrevious'
export const useLocalStorageEffectOrder = () => {
  const orderCart = useSelector(({ shop: { orderCart } }) => orderCart)
  const oldOrderCartId = usePrevious(orderCart.id)
  if (orderCart.id && oldOrderCartId !== orderCart.id) localStorage.setItem('orderCartToken', orderCart.jwt)
}

export const useLocalStorageEffectOffer = () => {
  const offerCart = useSelector(({ shop: { offerCart } }) => offerCart)
  const oldOfferCartId = usePrevious(offerCart.id)
  if (oldOfferCartId !== offerCart.id) localStorage.setItem('offerCartId', offerCart.id)
}
