import capitalize from 'capitalize'
import {
  CLEAR_CART,
  CLEAR_CART_PDF,
  CLEAR_SCROLL_POSITION,
  CLEAR_STATE,
  CREATE_CART,
  CREATE_CART_DONE,
  CREATE_CART_FAILED,
  CREATE_CART_PDF,
  CREATE_CART_PDF_DONE,
  CREATE_CART_PDF_FAILED,
  CREATE_USER_LOGO,
  CREATE_USER_LOGO_DONE,
  CREATE_USER_LOGO_FAILED,
  FINALIZE_CART,
  FINALIZE_CART_DONE,
  FINALIZE_CART_FAILED,
  HIDE_SALES_MESSAGE,
  LOAD_ADDRESSES,
  LOAD_ADDRESSES_DONE,
  LOAD_ADDRESSES_FAILED,
  LOAD_ARCHIVED_PRODUCTS,
  LOAD_ARCHIVED_PRODUCTS_DONE,
  LOAD_CART,
  LOAD_CART_DONE,
  LOAD_CART_FAILED,
  LOAD_CURRENT_USER_DONE,
  LOAD_CURRENT_USER_FAILED,
  LOAD_CUSTOMER_ORDERS,
  LOAD_CUSTOMER_ORDERS_DONE,
  LOAD_DEALER_ORDERS,
  LOAD_DEALER_ORDERS_DONE,
  LOAD_DEALERS_DONE,
  LOAD_JWT_PUBLIC_KEY_DONE,
  LOAD_MY_ORDERS_DONE,
  LOAD_OFFERS,
  LOAD_OFFERS_DONE,
  LOAD_PRODUCT_CATEGORIES_DONE,
  LOAD_PRODUCT_PROPERTIES_DONE,
  LOAD_PRODUCTS_DONE,
  LOAD_PRODUCTS_FAILED,
  LOAD_SAVED_CARTS_DONE,
  LOAD_SPARE_PARTS,
  LOAD_SPARE_PARTS_DONE,
  LOAD_SPARE_PARTS_FAILED,
  LOAD_SPECIAL_ITEMS,
  LOAD_SPECIAL_ITEMS_DONE,
  LOAD_USERS,
  LOAD_USERS_DONE,
  LOAD_USERS_FAILED,
  STORE_SCROLL_POSITION,
  UPDATE_CART,
  UPDATE_CART_ADMIN_DONE,
  UPDATE_CART_DEBOUNCE,
  UPDATE_CART_DONE,
  UPDATE_CART_FAILED,
  UPDATE_PRODUCT_CATEGORY,
  UPDATE_USER,
  USER_FORGOT_PASSWORD,
  USER_FORGOT_PASSWORD_DONE,
  USER_FORGOT_PASSWORD_FAILED,
  USER_LOGIN,
  USER_LOGIN_DONE,
  USER_LOGIN_FAILED,
  USER_LOGOUT,
  USER_REFRESH_DONE,
  USER_REFRESH_FAILED,
  USER_REQUEST_PASSWORD,
  USER_REQUEST_PASSWORD_DONE,
  USER_REQUEST_PASSWORD_FAILED,
  USER_RESET_JWT_ERROR,
} from './constants'

const initialState = {
  jwt: localStorage.getItem('jwt'),
  addresses: [],
  products: [],
  specialItems: [],
  spareParts: [],
  savedCarts: [],
  productCategories: [],
  productFamilies: [],
  productProperties: [],
  orderCart: {},
  offerCart: {},
  dealers: [],
  users: [],
  scrollPositions: {},
  customerOrders: { carts: [], more: false },
  dealerOrders: { carts: [], more: false },
  currentUser: { userType: 'visitor' },
  salesMessageHidden: false,
}

// noinspection FunctionWithMultipleReturnPointsJS
export const shop = (state = initialState, action) => {
  //  if (action.type.match(/^shop/)) console.debug('shopReducer:shop:' + action.type)
  switch (action.type) {
    case CLEAR_STATE:
      return {
        jwt: localStorage.getItem('jwt'),
        offerCart: undefined,
        orderCart: undefined,
      }

    case USER_RESET_JWT_ERROR:
      return {
        ...state,
        jwtError: undefined,
      }

    case USER_LOGIN:
      return {
        ...state,
        userLoggingIn: true,
      }

    case USER_REFRESH_DONE:
    case USER_LOGIN_DONE:
      return {
        ...state,
        userLoggingIn: false,
        jwtError: undefined,
        jwt: action.jwt,
        userLoginAt: new Date().getTime(),
      }

    case USER_REFRESH_FAILED:
    case USER_LOGIN_FAILED:
      return {
        ...state,
        userLoggingIn: false,
        jwtError: action.error,
        jwt: undefined,
        userLoginAt: new Date().getTime(),
        currentUser: { userType: 'visitor' },
      }

    case USER_LOGOUT:
      // retain the values we still need after logout
      return (({
        jwtPublicKey,
        productCategories,
        productCategoriesLoadedAt,
        productCategoriesLoading,
        productFamilies,
        productsLoadedAt,
        productsLoading,
        rawProducts,
        dealers,
        products,
        specialItems,
      }) => ({
        ...initialState,
        jwtPublicKey,
        productCategories,
        productCategoriesLoadedAt,
        productCategoriesLoading,
        productFamilies,
        productsLoadedAt,
        productsLoading,
        rawProducts,
        dealers,
        products: products.filter((product) => product.status !== 'archived'),
        specialItems,
        currentUser: { userType: 'visitor' },
      }))(state)
    case USER_FORGOT_PASSWORD:
      return {
        ...state,
        userRequestingPassword: true,
        userRequestPasswordError: undefined,
      }

    case USER_FORGOT_PASSWORD_DONE:
      return {
        ...state,
        userRequestingPassword: false,
        userRequestPasswordError: undefined,
        userRequestedPasswordAt: new Date().getTime(),
      }

    case USER_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        userRequestingPassword: false,
        userRequestPasswordError: action.error,
        userRequestedPasswordAt: new Date().getTime(),
      }

    case USER_REQUEST_PASSWORD:
      return {
        ...state,
        userResettingPassword: true,
        userResetPasswordError: undefined,
      }

    case USER_REQUEST_PASSWORD_DONE:
      return {
        ...state,
        userResettingPassword: false,
        userResetPasswordError: undefined,
        userResetPasswordAt: new Date().getTime(),
      }

    case USER_REQUEST_PASSWORD_FAILED:
      return {
        ...state,
        userResettingPassword: false,
        userResetPasswordError: action.error,
        userResetPasswordAt: new Date().getTime(),
      }

    case LOAD_CURRENT_USER_DONE:
      return {
        ...state,
        currentUser: action.currentUser,
        addresses: action.currentUser.addresses,
        currentUserLoadedAt: new Date().getTime(),
      }

    case LOAD_CURRENT_USER_FAILED:
      return {
        ...state,
        currentUser: { userType: 'visitor' },
        currentUserLoadedAt: new Date().getTime(),
      }

    case UPDATE_USER:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.user.user },
      }

    case LOAD_JWT_PUBLIC_KEY_DONE:
      return { ...state, jwtPublicKey: action.jwtPublicKey }

    case LOAD_PRODUCTS_DONE: {
      return {
        ...state,
        products: [...(state.archivedProducts || []), ...action.rawProducts],
        rawProducts: action.rawProducts,
        productsLoadedAt: new Date().getTime(),
      }
    }

    case LOAD_ARCHIVED_PRODUCTS:
      return {
        ...state,
        archivedProductsLoading: true,
      }

    case LOAD_ARCHIVED_PRODUCTS_DONE: {
      return {
        ...state,
        products: [...(state.rawProducts || []), ...action.archivedProducts],
        archivedProducts: action.archivedProducts,
        archivedProductsLoadedAt: new Date().getTime(),
        archivedProductsLoading: false,
        archivedProductsError: undefined,
      }
    }

    case LOAD_SPECIAL_ITEMS:
      return {
        ...state,
        specialItemsLoading: true,
      }

    case LOAD_SPECIAL_ITEMS_DONE: {
      return {
        ...state,
        specialItems: action.specialItems,
        specialItemsLoadedAt: new Date().getTime(),
        specialItemsLoading: false,
        specialItemsError: undefined,
      }
    }

    case LOAD_PRODUCTS_FAILED:
      return {
        ...state,
        productsLoading: false,
        productsError: action.error,
        productsLoadedAt: new Date().getTime(),
      }

    case LOAD_PRODUCT_CATEGORIES_DONE: {
      return { ...state, productCategories: action.productCategories }
    }

    case LOAD_PRODUCT_PROPERTIES_DONE: {
      return { ...state, productProperties: action.productProperties }
    }

    case UPDATE_PRODUCT_CATEGORY:
      if (!state.productCategories) {
        return { ...state }
      }
      const ix = state.productCategories.findIndex((pc) => pc.id === action.productCategory.id)
      const productCategories = [
        ...state.productCategories.slice(0, ix),
        action.productCategory,
        ...state.productCategories.slice(ix + 1),
      ]
      return { ...state, productCategories }

    case LOAD_ADDRESSES:
      return {
        ...state,
        addressesLoading: true,
      }

    case LOAD_ADDRESSES_DONE:
      return {
        ...state,
        addressesLoading: false,
        addressesError: undefined,
        addresses: action.addresses,
        addressesLoadedAt: new Date().getTime(),
      }

    case LOAD_ADDRESSES_FAILED:
      return {
        ...state,
        addressesLoading: false,
        addressesError: action.error,
        addressesLoadedAt: new Date().getTime(),
      }

    case CREATE_CART_PDF:
      return {
        ...state,
        [`cartPdf${capitalize(action.language)}`]: {
          creating: true,
          path: undefined,
          error: undefined,
        },
      }

    case CREATE_CART_PDF_DONE:
      return {
        ...state,
        [`cartPdf${capitalize(action.language)}`]: {
          creating: false,
          path: action.path,
          error: undefined,
          updatedAt: new Date().getTime(),
        },
      }

    case CREATE_CART_PDF_FAILED:
      return {
        ...state,
        [`cartPdf${capitalize(action.language)}`]: {
          creating: false,
          path: undefined,
          error: action.error,
          updatedAt: new Date().getTime(),
        },
      }

    case CLEAR_CART_PDF:
      return {
        ...state,
        [`cartPdf${capitalize(action.language)}`]: undefined,
      }

    case CLEAR_CART:
      const newState = {
        ...state,
        ...(action.cartName === 'order' && {
          NPSOrderCartToken: localStorage.getItem('orderCartToken'),
          NPSOrderCartId: state.orderCart?.id,
        }),
        [`${action.cartName}Cart`]: {},
        [`${action.cartName}CartError`]: undefined,
        [`${action.cartName}CartLoadedAt`]: undefined,
      }
      if (action.cartName === 'order') localStorage.removeItem('orderCartToken')
      return newState

    case CREATE_CART:
    case UPDATE_CART:
    case FINALIZE_CART:
    case LOAD_CART: {
      const { cart = {} } = action
      return {
        ...state,
        [`${cart.cartName}CartPending`]: true,
      }
    }

    case UPDATE_CART_DEBOUNCE:
      const { cart = {} } = action
      return {
        ...state,
        [`${cart.cartName}Cart`]: action.cart,
      }

    case CREATE_CART_DONE:
    case UPDATE_CART_DONE:
    case FINALIZE_CART_DONE:
    case LOAD_CART_DONE: {
      const { cart = {} } = action
      return {
        ...state,
        [`${cart.cartName}Cart`]: action.cart,
        [`${cart.cartName}CartPending`]: false,
        [`${cart.cartName}CartError`]: undefined,
        [`${cart.cartName}CartLoadedAt`]: new Date().getTime(),
      }
    }

    case CREATE_CART_FAILED:
    case UPDATE_CART_FAILED:
    case FINALIZE_CART_FAILED:
    case LOAD_CART_FAILED: {
      if (action.cartName) {
        return {
          ...state,
          [`${action.cartName}Cart`]: undefined,
          [`${action.cartName}CartPending`]: false,
          [`${action.cartName}CartError`]: action.error,
          [`${action.cartName}CartLoadedAt`]: new Date().getTime(),
        }
      } else {
        return {
          ...state,
        }
      }
    }

    case UPDATE_CART_ADMIN_DONE:
      const index = state.carts.findIndex((cart) => cart.id === action.cart.id)
      const carts = [...state.carts.slice(0, index), action.cart, ...state.carts.slice(index + 1)]
      return { ...state, carts }

    case LOAD_MY_ORDERS_DONE:
      return {
        ...state,
        ...(state.currentUser.userType === 'customer' && { customerOrders: action.myOrders }),
        ...(['dealer', 'bop'].includes(state.currentUser.userType) && { dealerOrders: action.myOrders }),
        myOrdersLoadedAt: new Date().getTime(),
      }

    case LOAD_CUSTOMER_ORDERS:
      return {
        ...state,
        customerOrders: { ...(state.customerOrders || {}), loading: true },
      }

    case LOAD_CUSTOMER_ORDERS_DONE:
      return {
        ...state,
        customerOrders: { ...action.customerOrders, loading: false },
      }

    case LOAD_DEALER_ORDERS:
      return {
        ...state,
        dealerOrders: { ...(state.dealerOrders || {}), loading: true },
      }

    case LOAD_DEALER_ORDERS_DONE:
      return {
        ...state,
        dealerOrders: { ...action.dealerOrders, loading: false },
      }

    case LOAD_SAVED_CARTS_DONE:
      return {
        ...state,
        savedCarts: action.savedCarts,
        savedCartsLoadedAt: new Date().getTime(),
      }

    case LOAD_OFFERS:
      return {
        ...state,
        offers: { ...(state.offers || {}), loading: true },
      }

    case LOAD_OFFERS_DONE:
      return {
        ...state,
        offers: { ...action.offers, loading: false },
      }

    case LOAD_DEALERS_DONE:
      return { ...state, dealers: action.dealers }

    case LOAD_USERS:
      return {
        ...state,
        usersLoading: true,
      }

    case LOAD_USERS_DONE:
      return {
        ...state,
        usersLoading: false,
        usersError: undefined,
        users: action.users,
        usersLoadedAt: new Date().getTime(),
      }

    case LOAD_USERS_FAILED:
      return {
        ...state,
        usersLoading: false,
        usersError: action.error,
        usersLoadedAt: new Date().getTime(),
      }

    case CREATE_USER_LOGO:
      return {
        ...state,
        creatingDealerLogo: true,
      }

    case CREATE_USER_LOGO_DONE:
      return {
        ...state,
        creatingDealerLogo: false,
        dealerLogoCreatedAt: new Date().getTime(),
      }

    case CREATE_USER_LOGO_FAILED:
      return {
        ...state,
        creatingDealerLogo: false,
        dealerLogoError: action.error,
        dealerLogoCreatedAt: new Date().getTime(),
      }

    case LOAD_SPARE_PARTS:
      return {
        ...state,
        sparePartsLoading: true,
      }

    case LOAD_SPARE_PARTS_DONE:
      return {
        ...state,
        sparePartsLoading: false,
        spareParts: action.spareParts,
        sparePartsError: undefined,
        sparePartsLoadedAt: new Date().getTime(),
      }

    case LOAD_SPARE_PARTS_FAILED:
      return {
        ...state,
        sparePartsLoading: false,
        spareParts: undefined,
        sparePartsError: action.error,
        sparePartsLoadedAt: new Date().getTime(),
      }

    case STORE_SCROLL_POSITION:
      return {
        ...state,
        scrollPositions: {
          ...state.scrollPositions,
          [action.route]: {
            displayItems: action.displayItems,
            articleNumber: action.articleNumber,
            showAsList: action.showAsList,
          },
        },
      }

    case CLEAR_SCROLL_POSITION:
      const scrollPositions = { ...state.scrollPositions }
      delete scrollPositions[action.route]
      return {
        ...state,
        scrollPositions,
      }

    case HIDE_SALES_MESSAGE:
      return {
        ...state,
        salesMessageHidden: true,
      }
    default:
      return state
  }
}
