import { applyMiddleware, combineReducers, createStore } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { cms } from 'databinding/cms/cmsReducer'
import { shop } from 'databinding/shop/shopReducer'
import { reducer as globalizeReducer } from './globalize'
import thunkMiddleware from 'redux-thunk'

// import { createLogger } from 'redux-logger'

export const history = createBrowserHistory()

const reducers = combineReducers({
  form: reduxFormReducer,
  router: connectRouter(history),
  globalize: globalizeReducer,
  shop,
  cms,
})

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const loggerMiddleware = createLogger({ duration: true, collapsed: true })

export const store = createStore(
  reducers,
  applyMiddleware(
    routerMiddleware(history),
    thunkMiddleware
    // loggerMiddleware
  )
)

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    console.debug('HMR ENABLED')
    module.hot.accept('./store', () => {
      store.replaceReducer(reducers)
    })
  }
}
