export const CLEAR_STATE = 'shop/CLEAR_STATE'

export const LOAD_JWT_PUBLIC_KEY_DONE = 'shop/LOAD_JWT_PUBLIC_KEY_DONE'

export const USER_LOGIN = 'shop/USER_LOGIN'
// todo redux: check login done and login failed
export const USER_LOGIN_DONE = 'shop/USER_LOGIN_DONE'
export const USER_LOGIN_FAILED = 'shop/USER_LOGIN_FAILED'
export const USER_LOGOUT = 'shop/USER_LOGOUT'
export const USER_RESET_JWT_ERROR = 'shop/USER_RESET_JWT_ERROR'

export const USER_REFRESH = 'shop/USER_REFRESH'
export const USER_REFRESH_DONE = 'shop/USER_REFRESH_DONE'
export const USER_REFRESH_FAILED = 'shop/USER_REFRESH_FAILED'

export const USER_FORGOT_PASSWORD = 'shop/USER_FORGOT_PASSWORD'
export const USER_FORGOT_PASSWORD_DONE = 'shop/USER_FORGOT_PASSWORD_DONE'
export const USER_FORGOT_PASSWORD_FAILED = 'shop/USER_FORGOT_PASSWORD_FAILED'

export const USER_REQUEST_PASSWORD = 'shop/USER_REQUEST_PASSWORD'
export const USER_REQUEST_PASSWORD_DONE = 'shop/USER_REQUEST_PASSWORD_DONE'
export const USER_REQUEST_PASSWORD_FAILED = 'shop/USER_REQUEST_PASSWORD_FAILED'

export const LOAD_CURRENT_USER = 'shop/LOAD_CURRENT_USER'
export const LOAD_CURRENT_USER_DONE = 'shop/LOAD_CURRENT_USER_DONE'
export const LOAD_CURRENT_USER_FAILED = 'shop/LOAD_CURRENT_USER_FAILED'

export const UPDATE_USER = 'shop/UPDATE_USER'
export const UPDATE_USER_DONE = 'shop/UPDATE_USER_DONE'
export const UPDATE_USER_FAILED = 'shop/UPDATE_USER_FAILED'

export const LOAD_PRODUCTS_DONE = 'shop/LOAD_PRODUCTS_DONE'
export const LOAD_PRODUCTS_FAILED = 'shop/LOAD_PRODUCTS_FAILED'

export const LOAD_ARCHIVED_PRODUCTS = 'shop/LOAD_ARCHIVED_PRODUCTS'
export const LOAD_ARCHIVED_PRODUCTS_DONE = 'shop/LOAD_ARCHIVED_PRODUCTS_DONE'
export const LOAD_ARCHIVED_PRODUCTS_FAILED = 'shop/LOAD_ARCHIVED_PRODUCTS_FAILED'

export const LOAD_SPECIAL_ITEMS = 'shop/LOAD_SPECIAL_ITEMS'
export const LOAD_SPECIAL_ITEMS_DONE = 'shop/LOAD_SPECIAL_ITEMS_DONE'

export const LOAD_PRODUCT_CATEGORIES_DONE = 'shop/LOAD_PRODUCT_CATEGORIES_DONE'
export const UPDATE_PRODUCT_CATEGORY = 'shop/UPDATE_PRODUCT_CATEGORY'

export const LOAD_PRODUCT_PROPERTIES = 'shop/LOAD_PRODUCT_PROPERTIES'
export const LOAD_PRODUCT_PROPERTIES_DONE = 'shop/LOAD_PRODUCT_PROPERTIES_DONE'
export const LOAD_PRODUCT_PROPERTIES_FAILED = 'shop/LOAD_PRODUCT_PROPERTIES_FAILED'

export const CREATE_CART = 'shop/CREATE_CART'
export const CREATE_CART_DONE = 'shop/CREATE_CART_DONE'
export const CREATE_CART_FAILED = 'shop/CREATE_CART_FAILED'

export const DELETE_CART = 'shop/DELETE_CART'
export const DELETE_CART_DONE = 'shop/DELETE_CART_DONE'
export const DELETE_CART_FAILED = 'shop/DELETE_CART_FAILED'

export const UPDATE_CART = 'shop/UPDATE_CART'
export const UPDATE_CART_DEBOUNCE = 'shop/UPDATE_CART_DEBOUNCE'
export const UPDATE_CART_DONE = 'shop/UPDATE_CART_DONE'
export const UPDATE_CART_FAILED = 'shop/UPDATE_CART_FAILED'
export const UPDATE_CART_ADMIN_DONE = 'shop/UPDATE_CART_ADMIN_DONE'

export const FINALIZE_CART = 'shop/FINALIZE_CART'
export const FINALIZE_CART_DONE = 'shop/FINALIZE_CART_DONE'
export const FINALIZE_CART_FAILED = 'shop/FINALIZE_CART_FAILED'

export const LOAD_CART = 'shop/LOAD_CART'
export const LOAD_CART_DONE = 'shop/LOAD_CART_DONE'
export const LOAD_CART_FAILED = 'shop/LOAD_CART_FAILED'
export const CLEAR_CART = 'shop/CLEAR_CART'

export const CREATE_CART_PDF = 'shop/CREATE_CART_PDF'
export const CREATE_CART_PDF_DONE = 'shop/CREATE_CART_PDF_DONE'
export const CREATE_CART_PDF_FAILED = 'shop/CREATE_CART_PDF_FAILED'
export const CLEAR_CART_PDF = 'shop/CLEAR_CART_PDF'

export const LOAD_MY_ORDERS_DONE = 'shop/LOAD_MY_ORDERS_DONE'
export const LOAD_MY_ORDERS_FAILED = 'shop/LOAD_CARTS_FAILED'

export const LOAD_CUSTOMER_ORDERS = 'shop/LOAD_CUSTOMER_ORDERS'
export const LOAD_CUSTOMER_ORDERS_DONE = 'shop/LOAD_CUSTOMER_ORDERS_DONE'
export const LOAD_CUSTOMER_ORDERS_FAILED = 'shop/LOAD_CUSTOMER_ORDERS_FAILED'

export const LOAD_DEALER_ORDERS = 'shop/LOAD_DEALER_ORDERS'
export const LOAD_DEALER_ORDERS_DONE = 'shop/LOAD_DEALER_ORDERS_DONE'
export const LOAD_DEALER_ORDERS_FAILED = 'shop/LOAD_DEALER_ORDERS_FAILED'

export const LOAD_OFFERS = 'shop/LOAD_OFFERS'
export const LOAD_OFFERS_DONE = 'shop/LOAD_OFFERS_DONE'
export const LOAD_OFFERS_FAILED = 'shop/LOAD_OFFERS_FAILED'

export const LOAD_SAVED_CARTS_DONE = 'shop/LOAD_SAVED_ORDERS_DONE'
export const LOAD_SAVED_CARTS_FAILED = 'shop/LOAD_SAVED_ORDERS_FAILED'

export const LOAD_ADDRESSES = 'shop/LOAD_ADDRESSES'
export const LOAD_ADDRESSES_DONE = 'shop/LOAD_ADDRESSES_DONE'
export const LOAD_ADDRESSES_FAILED = 'shop/LOAD_ADDRESSES_FAILED'

export const CREATE_ADDRESS = 'shop/CREATE_ADDRESS'
export const CREATE_ADDRESS_DONE = 'shop/CREATE_ADDRESS_DONE'
export const CREATE_ADDRESS_FAILED = 'shop/CREATE_ADDRESS_FAILED'

export const UPDATE_ADDRESS = 'shop/UPDATE_ADDRESS'
export const UPDATE_ADDRESS_DONE = 'shop/UPDATE_ADDRESS_DONE'
export const UPDATE_ADDRESS_FAILED = 'shop/UPDATE_ADDRESS_FAILED'

export const DELETE_ADDRESS = 'shop/DELETE_ADDRESS'
export const DELETE_ADDRESS_DONE = 'shop/DELETE_ADDRESS_DONE'
export const DELETE_ADDRESS_FAILED = 'shop/DELETE_ADDRESS_FAILED'

export const LOAD_DEALERS_DONE = 'shop/LOAD_DEALERS_DONE'
export const LOAD_DEALERS_FAILED = 'shop/LOAD_DEALERS_FAILED'

export const LOAD_USERS = 'shop/LOAD_USERS'
export const LOAD_USERS_DONE = 'shop/LOAD_USERS_DONE'
export const LOAD_USERS_FAILED = 'shop/LOAD_USERS_FAILED'

export const CREATE_USER_LOGO = 'shop/CREATE_USER_LOGO'
export const CREATE_USER_LOGO_DONE = 'shop/CREATE_USER_LOGO_DONE'
export const CREATE_USER_LOGO_FAILED = 'shop/CREATE_USER_LOGO_FAILED'

export const DELETE_USER_LOGO = 'shop/DELETE_USER_LOGO'
export const DELETE_USER_LOGO_DONE = 'shop/DELETE_USER_LOGO_DONE'
export const DELETE_USER_LOGO_FAILED = 'shop/DELETE_USER_LOGO_FAILED'

export const CREATE_TERMS_PDF_DONE = 'shop/CREATE_TERMS_PDF_DONE'

export const LOAD_SPARE_PARTS = 'shop/LOAD_SPARE_PARTS'
export const LOAD_SPARE_PARTS_DONE = 'shop/LOAD_SPARE_PARTS_DONE'
export const LOAD_SPARE_PARTS_FAILED = 'shop/LOAD_SPARE_PARTS_FAILED'

export const STORE_SCROLL_POSITION = 'shop/STORE_SCROLL_POSITION'
export const CLEAR_SCROLL_POSITION = 'shop/CLEAR_SCROLL_POSITION'

export const HIDE_SALES_MESSAGE = 'shop/HIDE_SALES_MESSAGE'
