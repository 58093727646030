import { useGlobalize } from 'databinding/globalize'
import Page from 'components/cms/Page'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Switch } from 'react-router'
import { Redirect, Route } from 'react-router-dom'
import ErrorBoundary from 'ErrorBoundary'

const components = {
  AccountAddresses: React.lazy(() => import('components/Shop/Account/Helpers/AccountAddresses')),
  AccountAdmin: React.lazy(() => import('components/Shop/Account/AccountAdmin')),
  AccountSavedCarts: React.lazy(() => import('components/Shop/Account/Helpers/SavedCarts/Carts')),
  AccountCustomer: React.lazy(() => import('components/Shop/Account/AccountCustomer')),
  AccountCustomerProfile: React.lazy(() => import('components/Shop/Account/AccountCustomer/Profile')),
  AccountDealer: React.lazy(() => import('components/Shop/Account/AccountDealer')),
  AccountBOP: React.lazy(() => import('components/Shop/Account/AccountBOP')),
  AccountDealerProfile: React.lazy(() => import('components/Shop/Account/Helpers/AccountDealerProfile')),
  AccountDiscounts: React.lazy(() => import('components/Shop/Account/AccountDiscounts')),
  AccountDownloads: React.lazy(() => import('components/Shop/Account/Helpers/Downloads/Downloads')),
  AccountOffers: React.lazy(() => import('components/Shop/Account/Helpers/Offers/Offers')),
  AccountOrderHistory: React.lazy(() => import('components/Shop/Account/Helpers/OrderHistory/OrderHistory')),
  AccountCustomerOrders: React.lazy(() => import('components/Shop/Account/Helpers/CustomerOrders/CustomerOrders')),
  AccountShop: React.lazy(() => import('components/Shop/Account/AccountShop')),
  AccountSpareparts: React.lazy(() => import('components/Shop/Account/Helpers/SpareParts/SpareParts')),
  AccountUser: React.lazy(() => import('components/Shop/Account/AccountUser')),
  Admin: React.lazy(() => import('components/cms/admin/Admin')),
  AdminOverview: React.lazy(() => import('components/cms/blog/Admin/AdminOverview')),
  BlogOverview: React.lazy(() => import('components/cms/blog/BlogOverview')),
  Categories: React.lazy(() => import('components/Shop/Category/Categories')),
  CheckoutCustomerOrder: React.lazy(() => import('components/Shop/Checkout/CustomerOrder')),
  CheckoutDealerOffer: React.lazy(() => import('components/Shop/Checkout/DealerOffer/CheckoutDealerOffer')),
  CheckoutDealerOrder: React.lazy(() => import('components/Shop/Checkout/DealerOrder/CheckoutDealerOrder')),
  CustomerOrderBasket: React.lazy(() => import('components/Shop/Checkout/CustomerOrder/CustomerOrderBasket')),
  CustomerOrderDealer: React.lazy(() => import('components/Shop/Checkout/CustomerOrder/CustomerOrderDealer')),
  CustomerOrderDelivery: React.lazy(() => import('components/Shop/Checkout/CustomerOrder/CustomerOrderDelivery')),
  CustomerOrderPayment: React.lazy(() => import('components/Shop/Checkout/CustomerOrder/CustomerOrderPayment')),
  ErrorTest: React.lazy(() => import('components/ErrorTest')),
  FirstLogin: React.lazy(() => import('components/Login/RequestPassword')),
  Login: React.lazy(() => import('components/Login/Login')),
  OfferBasket: React.lazy(() => import('components/Shop/Checkout/DealerOffer/OfferBasket')),
  OfferConfirmation: React.lazy(() => import('components/Shop/Checkout/DealerOffer/OfferConfirmation')),
  OfferOverview: React.lazy(() => import('components/Shop/Checkout/DealerOffer/OfferOverview')),
  OfferRecipient: React.lazy(() => import('components/Shop/Checkout/DealerOffer/OfferRecipient')),
  OrderConfirmation: React.lazy(() => import('components/Shop/Checkout/OrderConfirmation')),
  DealerOrderBasket: React.lazy(() => import('components/Shop/Checkout/DealerOrder/DealerOrderBasket')),
  DealerOrderDelivery: React.lazy(() => import('components/Shop/Checkout/DealerOrder/DealerOrderDelivery')),
  Page,
  ProductArchive: React.lazy(() => import('components/Shop/Account/Helpers/Archive/ProductArchive')),
  ProductDetail: React.lazy(() => import('components/Shop/Product/ProductDetail')),
  Register: React.lazy(() => import('components/Login/Register')),
  RequestPassword: React.lazy(() => import('components/Login/RequestPassword')),
  ResetPassword: React.lazy(() => import('components/Login/ResetPassword')),
  RouteEditor: React.lazy(() => import('components/cms/admin/RouteEditor')),
  SpecialItems: React.lazy(() => import('components/Shop/SpecialItems/SpecialItems')),
  Search: React.lazy(() => import('components/Shop/Search')),
}

const ConnectedSwitch = connect((state) => ({ location: state.location }))(Switch)

export default React.memo(({ path, ...remainingProps }) => {
  const routes = useSelector(({ cms: { routes } }) => routes)
  const { availableLanguages, language } = useGlobalize()
  const fallbackDefinitionRoute = routes.find((route) => route.node === '/fallback')
  const fallbackRoute =
    fallbackDefinitionRoute && routes.find((route) => route.node === fallbackDefinitionRoute.redirect)
  const fallbackPath = fallbackRoute && fallbackRoute.path && fallbackRoute.path[language]
  if (routes.length === 0) return false

  const redirects = routes.filter((route) => route.redirect && !route.node)
  const resultRoutes = routes
    .filter(({ path }) => path)
    .map(({ path, component, exact }) =>
      typeof path === 'string'
        ? { path, component, exact }
        : Object.entries(path)
            .filter(([language]) => availableLanguages.includes(language))
            .map(([_, path]) => ({ path, component, exact }))
    )
    .flat(1)
    .filter(({ component, path }) => component && path)
    .sort((a, b) => a.path.localeCompare(b.path))
    // if wanted, only get child routes of given path
    // add trailing slash cause React Router always shows path without it
    .filter((route) => (path ? route.path.indexOf(path + '/') === 0 : true))
  return (
    <ErrorBoundary location="switch">
      <ConnectedSwitch
        children={[
          ...resultRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              render={(componentProps) =>
                React.createElement(components[route.component], { ...componentProps, ...remainingProps })
              }
              exact={route.exact}
            />
          )),
          ...redirects.map(({ redirect }) => (
            <Route key={redirect.path} path={redirect.path} exact={redirect.exact}>
              <Redirect to={redirect.target} />
            </Route>
          )),
          ...(fallbackPath ? [<Redirect to={fallbackPath} />] : []),
        ]}
      />
    </ErrorBoundary>
  )
})
