import { useGlobalize } from 'databinding/globalize'
import placeholderDe from 'images/placeholder_de.jpg'
import placeholderFr from 'images/placeholder_fr.jpg'
import emptyImage from 'images/empty.png'
import React, { useCallback, useState } from 'react'
import ProgressiveImage from 'react-progressive-graceful-image'
import classNames from 'lib/classNames'

export default ({ as, product, title, shopUrl, url, className, size, fluid }) => {
  const { language } = useGlobalize()
  const [hasError, setHasError] = useState(false)
  const getImageDetails = useCallback(() => {
    if (product) {
      const { assets = [], title = {} } = product
      const asset = assets[0] || {}
      const { small = {} } = asset
      return {
        alt: title[language],
        minHeight: '150px',
        src: small.url ? process.env.REACT_APP_SHOP_API + small.url : undefined,
      }
    }
    return {
      alt: title,
      minHeight: size === 'tiny' ? '100px' : '300px',
      src: shopUrl ? process.env.REACT_APP_SHOP_API + shopUrl : url,
    }
  }, [product, title, language, size, shopUrl, url])

  const { src, alt, minHeight } = getImageDetails()

  const getImageRenderer = useCallback(
    (src, loading) => {
      // if (as === 'div') console.log({ hasError, as, minHeight, language, alt, size, fluid, className, src, loading })
      if (as === 'div' && loading)
        return <div className="ui basic loading attached segment placeholder" style={{ minHeight }} />

      if (hasError)
        return (
          <div className="shop-image-loader-wrapper">
            <div className={classNames(['shop-image-loader'])}>
              <img
                alt={alt}
                className={classNames([className, 'ui image', fluid ? 'fluid' : '', size])}
                src={language === 'fr' ? placeholderFr : placeholderDe}
              />
            </div>
          </div>
        )

      if (loading) {
        return (
          <div className="shop-image-loader-wrapper">
            <div className={classNames(['shop-image-loader', 'loading'])}>
              <img
                alt={alt}
                className={classNames([className, 'ui image', fluid ? 'fluid' : '', size])}
                src={emptyImage}
              />
            </div>
          </div>
        )
      }
      if (as === 'div') return <div className={className} style={{ backgroundImage: `url(${src})` }} />
      return <img alt={alt} src={src} className={classNames([className, 'ui image', fluid ? 'fluid' : '', size])} />
    },
    [as, hasError, minHeight, language, alt, size, fluid, className]
  )

  if (!src)
    return (
      <img
        alt={alt}
        className={classNames([className, 'ui image', fluid ? 'fluid' : '', size])}
        src={language === 'fr' ? placeholderFr : placeholderDe}
      />
    )
  return <ProgressiveImage src={src} placeholder="" onError={() => setHasError(true)} children={getImageRenderer} />
}
