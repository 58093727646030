import AssetListImage from 'components/cms/AssetBrowser/AssetListImage'
import EditDropdown from 'components/cms/AssetBrowser/EditDropdown'
import PropTypes from 'prop-types'
import React from 'react'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'

class AssetGridItems extends React.PureComponent {
  render() {
    const { defaultAction } = this.context
    const {
      assets,
      deleteAsset, //ownProps
    } = this.props
    return assets.map((asset = {}) => {
      return (
        <Card key={asset.id} asset={asset} onClick={() => defaultAction(asset)}>
          <AssetListImage
            {...{
              asset,
              rendition: 'thumbLarge',
              customProps: { fluid: true },
            }}
          />
          <Card.Content>
            <EditDropdown {...{ asset, deleteAsset }} />
          </Card.Content>
        </Card>
      )
    })
  }
}

AssetGridItems.contextTypes = {
  defaultAction: PropTypes.func,
}

export default AssetGridItems
