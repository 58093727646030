import translations from 'components/cms/AssetBrowser/_AssetBrowser.i18n.json'
import AssetGridItems from 'components/cms/AssetBrowser/AssetGridItems'
import AssetListItems from 'components/cms/AssetBrowser/AssetListItems'
import { confirmationModal } from 'components/Helpers/ConfirmationModal'
import config from 'config/config'
import { globalize } from 'databinding/globalize'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import deleteAsset from 'databinding/cms/thunks/deleteAsset'

const i18nKey = 'asset_browser'

class AssetList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.deleteAsset = this.deleteAsset.bind(this)
  }

  deleteAsset(id) {
    const { globalize, dispatch } = this.props
    const { translate } = globalize
    confirmationModal({
      icon: config.icons.delete, // icon object, see config.js
      text: translate(`${i18nKey}.modal_delete.text`), // modal content (optional)
      title: translate(`${i18nKey}.modal_delete.title`), // modal title
      button: translate(`${i18nKey}.modal_delete.button`), // button Text
      confirm: () => dispatch(deleteAsset({ id })),
    })
  }

  render() {
    const { viewAsList, itemsPerRow, assets } = this.props

    if (viewAsList) {
      return (
        <Table basic="very" size="small" compact selectable singleLine className="spaced">
          <Table.Body>
            <AssetListItems {...{ assets, deleteAsset: this.deleteAsset }} />
          </Table.Body>
        </Table>
      )
    } else {
      return (
        <Card.Group {...{ itemsPerRow }}>
          <AssetGridItems {...{ assets, deleteAsset: this.deleteAsset }} />
        </Card.Group>
      )
    }
  }
}

AssetList.propTypes = {
  viewAsList: PropTypes.bool,
  itemsPerRow: PropTypes.number,
  assets: PropTypes.array,
}

AssetList = connect()(AssetList)
AssetList = globalize({ translations })(AssetList)
export default AssetList
