import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import request from 'superagent'

const useGetCart = () => {
  const { language, cartId } = useParams()
  const [cartStatus, setCartStatus] = useState()
  const orderCartToken = localStorage.getItem('orderCartToken')
  const token = useSelector(({ shop: { jwt } }) => jwt)
  const routes = useSelector(({ cms: { routes } }) => routes)

  const getCart = async () => {
    try {
      await request
        .get(
          `${process.env.REACT_APP_SHOP_API}/carts/${cartId}.json${
            orderCartToken ? `?cart[jwt]=${orderCartToken}` : ''
          }`
        )
        .set('Authorization', `Bearer ${token}`)
      setCartStatus('open')
    } catch (e) {
      if (e.status === 401) {
        setCartStatus('finalized')
        return
      }
      console.error(e.status)
    }
  }
  useEffect(() => {
    getCart().then()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  if (cartStatus === 'finalized') {
    const route = routes.find((route) => route.node === '/checkout/confirmation')
    if (route && typeof route.path === 'object') {
      localStorage.removeItem('orderCartToken')
      return route.path[language]
    }
  } else if (cartStatus === 'open') {
    const route = routes.find((route) => route.node === '/checkout/customer/payment')
    if (route && typeof route.path === 'object') return route && typeof route.path === 'object' && route.path[language]
  }
  return false
}

export default () => {
  const path = useGetCart()
  return path && <Redirect to={path} />
}
