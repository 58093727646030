import config from 'config/config'
import { globalize } from 'databinding/globalize'
import React from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import translations from './_Footer.i18n.json'

class SocialLinks extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        {config.social.youtube && (
          <Button circular color="youtube" icon="youtube" target="_blank" href={config.social.youtube} />
        )}
        {config.social.linkedin && (
          <Button circular color="linkedin" icon="linkedin" target="_blank" href={config.social.linkedin} />
        )}
        {config.social.twitter && (
          <Button circular color="twitter" icon="twitter" target="_blank" href={config.social.twitter} />
        )}
        {config.social.googlePlus && (
          <Button circular color="google plus" icon="google plus" target="_blank" href={config.social.googlePlus} />
        )}
        {config.social.instagram && (
          <Button circular color="instagram" icon="instagram" target="_blank" href={config.social.instagram} />
        )}
        {config.social.pinterest && (
          <Button circular color="pinterest" icon="pinterest" target="_blank" href={config.social.pinterest} />
        )}
        {config.social.whatsapp && <Button circular color="green" icon="whatsapp" href=" whatsapp://send" />}
      </React.Fragment>
    )
  }
}

SocialLinks = globalize({ translations })(SocialLinks)
export default SocialLinks
