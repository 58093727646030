const itemsPerView = 6

export default ({ pageNumber, assets, allowedTypes, filterText, filterType }) => {
  let currentPage = pageNumber
  let filteredAssets = assets
  if (allowedTypes) filteredAssets = filteredAssets.filter((asset = {}) => allowedTypes.indexOf(asset.contentType) >= 0)
  const totalItems = filteredAssets.length
  const totalPages = Math.ceil(totalItems / itemsPerView)
  if (filterText) {
    filteredAssets = filteredAssets.filter((asset = {}) => {
      const data = asset.data || {}
      const { tags = [] } = data
      const re = new RegExp(filterText, 'i')
      const keys = Object.keys(data)
      // is the filter Value contained in the strings?
      let match = keys.reduce((acc, key) => acc || (typeof data[key] === 'string' && data[key].match(re)), false)

      // search in the tags
      if (!match) {
        match = tags.reduce((acc, tag) => acc || (typeof tag === 'string' && tag.match(re)), false)
      }
      return match
    })
  }
  if (filterType) {
    filteredAssets = filteredAssets.filter((asset = {}) => {
      const { contentType = '' } = asset
      return contentType.startsWith(filterType)
    })
  }
  if (filteredAssets.length < currentPage * itemsPerView) {
    currentPage = Math.floor(totalItems / itemsPerView)
  }
  filteredAssets = filteredAssets.slice(currentPage * itemsPerView, currentPage * itemsPerView + itemsPerView)
  return { filteredAssets, currentPage, totalPages }
}
