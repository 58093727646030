import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import updateCart from 'databinding/shop/thunks/updateCart'
import createCart from 'databinding/shop/thunks/createCart'

export const useAddToCart = (product, cartName, selectedProperties) => {
  const dispatch = useDispatch()
  const carts = useSelector(({ shop: { orderCart = {}, offerCart = {} } }) => ({ orderCart, offerCart }))
  return useCallback(() => {
    const kind = product.kind || (product.specialItemKind ? 'specialItem' : 'product')
    const cart = carts[`${cartName}Cart`]
    const productToAdd = {
      articleNumber: product.articleNumber,
      kind,
      ...(!!product.specialItemKind && { specialItemKind: product.specialItemKind }),
      quantity: 1,
      ...(selectedProperties ? { selectedProperties } : {}),
    }
    if (cartName === 'offer') {
      if (product.discount2Percent) {
        productToAdd.discount2Percent = product.discount2Percent
        productToAdd.discount2Name = product.discount2Name
        productToAdd.discountPercent = 0
        productToAdd.price = product.originalPrice * (1.0 - product.discount2Percent / 100)
        productToAdd.originalPrice = product.originalPrice
      } else {
        productToAdd.discount2Percent = 0
        productToAdd.discountPercent = 0
        productToAdd.price = product.originalPrice
        productToAdd.originalPrice = product.originalPrice
      }
    }

    if (cart.id) {
      const index = cart.data.products.findIndex(
        (p) =>
          p.articleNumber === product.articleNumber &&
          p.kind === kind &&
          Object.entries(p.selectedProperties || {}).reduce(
            (acc, [key, value]) => acc && selectedProperties[key].valueId === value.valueId,
            true
          )
      )
      if (index >= 0) {
        dispatch(
          updateCart({
            cart: {
              ...cart,
              data: {
                ...cart.data,
                products: [
                  ...cart.data.products.slice(0, index),
                  {
                    ...cart.data.products[index],
                    quantity: cart.data.products[index].quantity + 1,
                  },
                  ...cart.data.products.slice(index + 1),
                ],
              },
            },
          })
        )
      } else {
        dispatch(
          updateCart({
            cart: {
              ...cart,
              data: { ...cart.data, products: [...cart.data.products, productToAdd] },
            },
          })
        )
      }
    } else {
      dispatch(
        createCart({
          cart: {
            cartName,
            data: {
              kind: cartName,
              products: [productToAdd],
            },
          },
        })
      )
    }
  }, [carts, dispatch, product, cartName, selectedProperties])
}
