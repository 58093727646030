import { globalize } from 'databinding/globalize'
import React from 'react'
import ReactTags from 'react-tag-autocomplete'
import translations from './_ReduxForm.i18n.json'

class ReduxFormTagsSimple extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAddition = this.handleAddition.bind(this)
  }

  handleDelete(index) {
    const { input } = this.props
    const newTags = [...input.value.slice(0, index), ...input.value.slice(index + 1)]
    input.onChange(newTags)
  }

  handleAddition(tag) {
    const { input } = this.props
    const newTags = [...input.value, tag.name]
    input.onChange(newTags)
  }

  render() {
    const { label, input, placeholder, suggestions = [], ...remainingParameters } = this.props
    const value = input.value || []
    let tags = value.map((value) => ({ name: value }))
    let suggestionObjects = suggestions.map((suggestion) => ({
      name: suggestion,
    }))
    return (
      <div className={input.required ? 'required field' : 'field'}>
        <label children={label} />
        <ReactTags
          tags={tags}
          placeholder={placeholder}
          suggestions={suggestionObjects}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          {...remainingParameters}
        />
      </div>
    )
  }
}

ReduxFormTagsSimple = globalize({ translations })(ReduxFormTagsSimple)
export default ReduxFormTagsSimple
