import Address from 'components/Shop/Checkout/Helpers/Address'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'

const mapStateToProps = (state) => {
  const { dealers = [] } = state.shop
  return { dealers }
}

const mapDispatchToProps = undefined

class DealerListItems extends React.PureComponent {
  render() {
    const { filterText } = this.props
    const {
      dealers, // connect
    } = this.props
    let filteredDealers = dealers.sort((a, b) => a.kind - b.kind || a.postcode.localeCompare(b.postcode))
    if (filterText && filterText.match(/^\d+$/)) {
      const re = new RegExp(`^${filterText}`)
      filteredDealers = filteredDealers.filter((dealer) => dealer.postcode.match(re))
    } else if (filterText && filterText.length >= 2) {
      const re = new RegExp(filterText, 'i')
      filteredDealers = filteredDealers.filter(
        (dealer) =>
          (dealer.place && dealer.place.match(re)) ||
          (dealer.address1 && dealer.address1.match(re)) ||
          (dealer.address2 && dealer.address2.match(re)) ||
          (dealer.address3 && dealer.address3.match(re)) ||
          (dealer.address4 && dealer.address4.match(re)) ||
          (dealer.address5 && dealer.address5.match(re)) ||
          (dealer.address6 && dealer.address6.match(re))
      )
    }
    return filteredDealers.map((dealer, index) => (
      <Table.Row key={index} userid={dealer.userId}>
        <Table.Cell>
          <Address address={dealer} filterText={filterText} premium={dealer.kind === 0} />
        </Table.Cell>
      </Table.Row>
    ))
  }
}

DealerListItems.propTypes = {
  filterText: PropTypes.string,
}

DealerListItems = connect(mapStateToProps, mapDispatchToProps)(DealerListItems)
export default DealerListItems
