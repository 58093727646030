import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import getCurrentRoute from 'lib/getCurrentRoute'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import translations from './_translations.json'

const mapStateToProps = (state) => {
  const { routes } = state.cms
  const { products = [] } = state.shop
  return { routes, products }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

class MenuItemLanguages extends React.PureComponent {
  render() {
    const { mobile } = this.props // prop
    const {
      routes,
      push,
      products, //connect
      location: { pathname }, // withRouter
      globalize: { translate, language, availableLanguages = [], setLanguage }, //globalize
    } = this.props // redux

    const options = availableLanguages
      .filter((lang) => lang !== language)
      .map((newLanguage, index) => {
        return {
          key: index,
          text: translate('navigation.languages.' + newLanguage),
          name: newLanguage,
          value: newLanguage,
          onClick: () => {
            const { path = {} } = getCurrentRoute(routes, pathname)
            const parameters = {}
            if (path[[language]]) {
              const routeParts = path[language].split('/')
              const pathNameParts = pathname.split('/')
              for (let i = 0; i < routeParts.length; i++) {
                if (routeParts[i].match(/^:/)) {
                  parameters[routeParts[i]] = pathNameParts[i]
                }
              }
            }

            if (parameters[':seoKey']) {
              const product = products.find((product) => product.articleNumber === parameters[':articleNumber']) || {}
              const { seoUrl = {} } = product
              parameters[':seoKey'] = seoUrl[newLanguage]
            }

            const splitNewPath = path[newLanguage].split('/')
            const newPath = splitNewPath.map((part) => parameters[part] || part).join('/')
            if (newPath) {
              setLanguage(newLanguage)
              push(newPath)
            } else {
              // TODO: @andreas: Throw user to start page if it does not exist (already happening ... !?)
              console.warn(`Route could not be found for language ${newLanguage}: ${pathname}`)
            }
          },
        }
      })

    return mobile ? (
      <Form.Select options={options || []} placeholder={translate('navigation.languages.' + language)} />
    ) : (
      <Dropdown
        {...{
          options,
          trigger: translate('navigation.languages.' + language),
          pointing: 'top',
          className: classNames(['hide-below-tablet', 'item', 'right', 'language-switch']),
        }}
      />
    )
  }
}

MenuItemLanguages = globalize({ translations })(MenuItemLanguages)
MenuItemLanguages = connect(mapStateToProps, mapDispatchToProps)(MenuItemLanguages)
MenuItemLanguages = withRouter(MenuItemLanguages)
export default MenuItemLanguages
