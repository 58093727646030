import NavigationLink from 'components/Navigation/NavigationLink'
import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import React from 'react'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import translations from './_CookieBar.i18n.json'

const i18nkey = 'cookie-bar'

class CookieBar extends React.PureComponent {
  constructor(props) {
    super(props)
    this.acceptCookies = this.acceptCookies.bind(this)
    this.state = { cookies: localStorage.getItem('cookies') }
  }

  acceptCookies() {
    this.setState({ cookies: 'accepted' })
    localStorage.setItem('cookies', 'accepted')
  }

  render() {
    const { cookies } = this.state
    const {
      globalize: { translate }, //globalize
    } = this.props
    return (
      !cookies && (
        <Menu
          {...{
            color: 'grey',
            inverted: true,
            borderless: true,
            fluid: true,
            secondary: true,
            size: 'huge',
            fixed: 'bottom',
            className: classNames(['cookie-bar', 'floating']),
          }}>
          <Menu.Item style={{ flex: 1 }}>
            <p>
              {translate(`${i18nkey}.text`)}
              <NavigationLink
                node={'/support/legal'}
                component="Link"
                children={translate(`${i18nkey}.privacy-policy`)}
              />
            </p>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item fitted>
              <Button inverted primary content={translate(`${i18nkey}.accept`)} onClick={this.acceptCookies} />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      )
    )
  }
}

// CookieBar = keydown('esc')(CookieBar)
CookieBar = globalize({ translations })(CookieBar)
// CookieBar = withRouter(CookieBar) // this is needed for MenuItemLanguages. Do not remove.
export default CookieBar
