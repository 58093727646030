import { useInView } from 'react-intersection-observer'
import React, { useEffect, useState } from 'react'

const getSrc = (asset, entry) => {
  const { width = 0 } = (entry || {}).boundingClientRect || {}
  const { large = {}, medium = {}, small = {} } = asset.styles || {}
  if (width < 320 && small.url) return process.env.REACT_APP_CMS_API + small.url
  if (width < 640 && medium.url) return process.env.REACT_APP_CMS_API + medium.url
  if (large.url) return process.env.REACT_APP_CMS_API + large.url
  return false
}

export default ({ children, asset }) => {
  const { ref, inView, entry } = useInView()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [src, setSrc] = useState()
  useEffect(() => {
    if (!inView) return
    if (src) return
    if (!asset) return
    const img = new Image()
    const source = getSrc(asset, entry)
    if (!source) return
    img.src = source
    img.onload = () => {
      setLoading(false)
      setError(false)
    }
    img.onerror = () => setError(true)
    setSrc(source)
  }, [inView, entry, loading, asset, src])
  const previewSrc =
    (((asset || {}).styles || {}).blurredPreview || {}).url &&
    process.env.REACT_APP_CMS_API + (((asset || {}).styles || {}).blurredPreview || {}).url
  const smallSrc =
    (((asset || {}).styles || {}).small || {}).url &&
    process.env.REACT_APP_CMS_API + (((asset || {}).styles || {}).small || {}).url
  if (!src && !previewSrc && !smallSrc) return false
  return <div ref={ref} children={children({ src: src || previewSrc || smallSrc, loading, error, inView, entry })} />
}
