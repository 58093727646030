/*
 * returns a productUrl
 *
 * */

export default ({ product, routes, language, articleNumber }) => {
  if (product && routes.length > 0 && language && articleNumber) {
    const { seoUrl = {} } = product
    const route = routes.find((route) => route.node === '/product/:seoKey/:articleNumber') || {}
    const { path = {} } = route
    let languagePath = path[language]
    const seoKey = seoUrl[language]
    return languagePath.replace(':seoKey', seoKey).replace(':articleNumber', articleNumber)
  } else {
    return false
  }
}
