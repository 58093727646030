import {
  CREATE_ASSET_DONE,
  CREATE_ASSET_FAILED,
  CREATE_PAGE_DONE,
  CREATE_PAGE_FAILED,
  DELETE_ASSET_DONE,
  DELETE_ASSET_FAILED,
  HIDE_PANEL,
  LOAD_ASSETS_DONE,
  LOAD_PAGES_DONE,
  LOAD_ROUTES_DONE,
  REPLACE_ASSET_DONE,
  REPLACE_ASSET_FAILED,
  SAVE_ASSET_DONE,
  SAVE_ASSET_FAILED,
  SAVE_PAGE_DONE,
  SAVE_PAGE_FAILED,
  SAVE_USER_ROUTES_DONE,
  SAVE_USER_ROUTES_FAILED,
  SET_DRAG_MARKER,
  SET_EDITING_INDEX,
  SHOW_PANEL,
  TOGGLE_PANEL,
} from 'databinding/cms/constants'

export const loadAssetsDone = ({ assets }) => ({ type: LOAD_ASSETS_DONE, assets })
export const createAssetDone = () => ({ type: CREATE_ASSET_DONE })
export const createAssetFailed = ({ error }) => ({ type: CREATE_ASSET_FAILED, error })

export const replaceAssetDone = () => ({ type: REPLACE_ASSET_DONE })
export const replaceAssetFailed = ({ error }) => ({ type: REPLACE_ASSET_FAILED, error })

export const saveAssetDone = () => ({ type: SAVE_ASSET_DONE })
export const saveAssetFailed = ({ error }) => ({ type: SAVE_ASSET_FAILED, error })

export const deleteAssetDone = () => ({ type: DELETE_ASSET_DONE })
export const deleteAssetFailed = ({ error }) => ({ type: DELETE_ASSET_FAILED, error })

export const loadRoutesDone = ({ routes, userRoutes }) => ({ type: LOAD_ROUTES_DONE, routes, userRoutes })

export const saveUserRoutesDone = () => ({ type: SAVE_USER_ROUTES_DONE })
export const saveUserRoutesFailed = ({ error }) => ({ type: SAVE_USER_ROUTES_FAILED, error })

export const loadPagesDone = ({ pages }) => ({ type: LOAD_PAGES_DONE, pages })

export const createPageDone = () => ({ type: CREATE_PAGE_DONE })
export const createPageFailed = ({ error }) => ({ type: CREATE_PAGE_FAILED, error })

export const savePageDone = () => ({ type: SAVE_PAGE_DONE })
export const savePageFailed = ({ error }) => ({ type: SAVE_PAGE_FAILED, error })

export const showCmsSidebar = () => ({ type: SHOW_PANEL })
export const toggleCmsSidebar = () => ({ type: TOGGLE_PANEL })
export const hideCmsSidebar = () => ({ type: HIDE_PANEL })

export const setDragMarker = ({ dragMarker }) => ({ type: SET_DRAG_MARKER, dragMarker })
export const setEditingComponentIndex = ({ editingComponentIndex, editingElementIndex }) => ({
  type: SET_EDITING_INDEX,
  editingComponentIndex,
  editingElementIndex,
})
