/* eslint-disable no-ternary */
import React, { memo } from 'react'
import Highlighter from 'react-highlight-words'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Stock from 'components/Shop/Product/Stock'
import EnergyLabel from 'components/Shop/Product/ProductList/Helpers/EnergyLabel'
import ShopImage from 'components/Helpers/ShopImage'
import { Link } from 'react-router-dom'
import translations from 'components/Shop/Product/ProductList/_ProductList.i18n.json'
import { useGlobalize } from 'databinding/globalize'
import AddToCart from 'components/Shop/Product/AddToCart/AddToCart'

const i18nKey = 'product_list'

export default memo(({ product, highlightOptions, targetPath, allowCart }) => {
  const { language, translate } = useGlobalize({ translations })

  const {
    price,
    originalPrice,
    formattedPrice,
    discountPercent,
    discount2Percent,
    formattedOriginalPrice,
    properties = [],
    specialItemKind,
    articleNumber,
  } = product

  const isProductFamily = !!product.familyMemberIds
  return (
    <Card id={articleNumber} color={isProductFamily ? 'orange' : undefined} link>
      <div className="product-image">
        <Link to={targetPath}>
          {isProductFamily && (
            <Label style={{ zIndex: 2 }} attached="top right" color="orange" content={translate(`${i18nKey}.more`)} />
          )}
          <ShopImage product={product} />
        </Link>
      </div>
      <Card.Content>
        <Card.Meta>
          <EnergyLabel properties={properties} articleNumber={product.articleNumber} />
          <div className="price">
            {isProductFamily && formattedPrice && translate(`${i18nKey}.from`)}
            {formattedPrice?.replace('.00', '.-') || ''}
            {!!specialItemKind && <Label tag size="mini" className="netPrice" content={translate('shop.special_items.specialPrice')} />}
            {originalPrice !== price && formattedPrice && (
              <span className="discount">
                <span className="strike">{formattedOriginalPrice.replace('.00', '.-')}</span>
                {!!discountPercent && <Label tag size="mini" content={`- ${discountPercent} %`} />}
                {!!discount2Percent && <Label tag color="orange" size="mini" content={`- ${discount2Percent} %`} />}
              </span>
            )}
          </div>
        </Card.Meta>
        <Card.Header>
          <Link to={targetPath}>
            <Highlighter {...highlightOptions} textToHighlight={product.title[language]} />
          </Link>
          {!!specialItemKind && (
            <div>
              <Label basic color="black" content={translate(`shop.special_items.${specialItemKind}`)} />
            </div>
          )}
        </Card.Header>
      </Card.Content>
      <Card.Content extra>
        <Grid stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column>
              {!isProductFamily && !specialItemKind && <Stock as="icon" stock={product.stock} />}
              <Link to={targetPath}>
                <Highlighter {...highlightOptions} textToHighlight={articleNumber} />
              </Link>
              {allowCart && (
                <AddToCart quickAdd style={{ float: 'right' }} product={product} />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  )
})
