import { globalize } from 'databinding/globalize'
import React from 'react'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import translations from 'components/Shop/Product/ProductList/_ProductList.i18n.json'

const i18nKey = 'product_list'

const NoHitsWarning = ({ heading, globalize: { translate } }) => (
  <React.Fragment>
    {heading && <Header content={heading} as="h2" />}
    <p children={translate(`${i18nKey}.no_hits`)} className="unobtrusive" />
  </React.Fragment>
)
export default globalize({ translations })(NoHitsWarning)
