import NoResultMessage from './NoResultMessage'
import ProductListContainer from './ProductListContainer'
import classNames from 'lib/classNames'
import { useHooks } from './hooks'
import React, { memo } from 'react'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import ProductListContent from 'components/Shop/Product/ProductList/ProductListContent'

const i18nKey = 'product_list'

export default memo(({ inline, layout = {}, as, heading, text, itemsPerRow = 4, filterText, size, products = [], headerSearch }) => {
  const { showAsList, displayedProducts, increaseDisplayedProducts, toggleViewStyle, displayCount, translate } =
    useHooks({ products, as, headerSearch })
  const showAs = showAsList ? size || 'list' : 'cards'
  const segmentCSS = classNames([
    layout.grey && 'grey',
    layout.spacing && 'spacing',
    layout.padded && 'padded',
    'product-list',
  ])

  if (products.length < 1) return <NoResultMessage {...{ segmentCSS, inline, heading }} />

  return (
    <Segment basic className={segmentCSS}>
      <ProductListContainer inline={inline}>
        <ProductListContent {...{ heading, showAs, products: displayedProducts }}
          {...{ itemsPerRow, filterText, text, layout, as, toggleViewStyle }}
        />
      </ProductListContainer>
      {products.length >= displayCount && (
        <Divider horizontal section clearing>
          <Button
            onClick={increaseDisplayedProducts}
            content={translate(`${i18nKey}.load_more`)}
            primary
            fluid
            icon="refresh"
            size="small"
          />
        </Divider>
      )}
    </Segment>
  )
})
