import NavigationLink from 'components/Navigation/NavigationLink'
import { useGlobalize } from 'databinding/globalize'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import translations from './_Cart.i18n.json'
import JWT from 'jsonwebtoken'
import loadCart from 'databinding/shop/thunks/loadCart'
import { clearCart } from 'databinding/shop/actions'
import { usePrevious } from 'lib/usePrevious'
const i18nKey = 'cart.'

export default () => {
  const orderCart = useSelector(({ shop: { orderCart } }) => orderCart)
  const orderCartLoadedAt = useSelector(({ shop: { orderCartLoadedAt } }) => orderCartLoadedAt)
  const orderCartPending = useSelector(({ shop: { orderCartPending } }) => orderCartPending)
  const orderCartError = useSelector(({ shop: { orderCartError } }) => orderCartError)
  const jwtPublicKey = useSelector(({ shop: { jwtPublicKey } }) => jwtPublicKey)
  const currentUser = useSelector(({ shop: { currentUser } }) => currentUser)
  const { translate } = useGlobalize({ translations })
  const oldOrderCartLoadedAt = usePrevious(orderCartLoadedAt)
  const dispatch = useDispatch()

  // load cart if not loaded yet
  useEffect(() => {
    const orderCartToken = localStorage.getItem('orderCartToken')
    if (!orderCart?.id && orderCartToken && jwtPublicKey) {
      try {
        const decodedKey = JWT.verify(orderCartToken, jwtPublicKey)
        const id = decodedKey.cartId
        const cartName = 'order'
        dispatch(loadCart({ id, jwt: orderCartToken, cartName }))
      } catch (err) {
        console.error('orderCartToken was not valid', err)
        dispatch(clearCart({ cartName: 'order' }))
      }
    }
  }, [dispatch, jwtPublicKey, orderCart])

  // remove localStorage item if error
  useEffect(() => {
    if (oldOrderCartLoadedAt !== orderCartLoadedAt && orderCartError) localStorage.removeItem('orderCartToken')
  }, [orderCartLoadedAt, oldOrderCartLoadedAt, orderCartError])

  const orderItemsCount = (((orderCart || {}).data || {}).products || []).reduce(
    (acc, cur = {}) => acc + cur.quantity,
    0
  )
  return (
    <NavigationLink
      icon="cart"
      component="Button"
      node={['dealer', 'bop'].includes(currentUser.userType) ? '/checkout/order/basket' : '/checkout/customer/basket'}
      className="cart"
      primary={orderItemsCount > 0}
      inverted={orderItemsCount < 1}
      disabled={orderItemsCount < 1}
      loading={orderCartPending}
      content={
        <span>
          <span className="hide-below-tablet" children={translate(i18nKey + 'cart')} />
          {orderItemsCount > 0 && <Label content={orderItemsCount} color={'red'} floating />}
        </span>
      }
    />
  )
}
