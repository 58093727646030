import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

class Link extends React.PureComponent {
  render() {
    const { path, navigationTitle, children, as, ...remainingProps } = this.props
    if (typeof children === 'function')
      return <RouterLink as={as} to={path} {...remainingProps} children={children(this.props)} />
    return <RouterLink as={as} to={path} children={children || navigationTitle} {...remainingProps} />
  }
}

export default Link
