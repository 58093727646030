export default (routes, pathname) => {
  return (
    routes.find((route) => {
      let routeMatches = false
      if (route.path && typeof route.path === 'object') {
        routeMatches = !!Object.values(route.path).find((path) => {
          let pathMatches = false
          if (path === pathname) {
            pathMatches = true
          } else if (path.match(/:/)) {
            const pathParts = path.split('/')
            const pathNameParts = pathname.split('/')
            if (pathParts.length !== pathNameParts.length) {
              pathMatches = false // no match if no length match
            } else {
              pathMatches = true // if the loop runs through without break, path matches
              for (let i = 0; i < pathParts.length; i++) {
                // check path elements for equality, omit parameters
                if (!pathParts[i].match(/^:/) && pathNameParts[i] !== pathParts[i]) {
                  pathMatches = false
                  break
                }
              }
            }
          }
          return pathMatches
        })
      }
      return routeMatches
    }) || {}
  )
}
