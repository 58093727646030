import classNames from 'lib/classNames'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Step from 'semantic-ui-react/dist/commonjs/elements/Step'

const mapStateToProps = undefined

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ push }, dispatch)
}

class StepNavigation extends React.PureComponent {
  getMenuItems() {
    const {
      inactive, // ownProps
      routes,
      push,
    } = this.props
    return routes.map((route, index) => {
      let returnValue = false
      if (route) {
        returnValue = (
          <Step
            {...{
              key: index,
              active: route.active,
              onClick: () => !inactive && push(route.path),
            }}>
            {route.icon && <Icon name={route.icon} />}
            <Step.Content>
              <Step.Title children={route.navigationTitle} />
            </Step.Content>
          </Step>
        )
      } else {
        console.warn('Route is undefined')
      }
      return returnValue
    })
  }

  render() {
    // noinspection JSUnusedLocalSymbols Do not remove unused imports!
    const { inactive, routes, history, location, match, selection, staticContext, node, push, ...remainingProps } =
      this.props //eslint-disable-line no-unused-vars
    const items = this.getMenuItems()
    let returnValue = false

    if (items.length > 0) {
      returnValue = (
        <Step.Group
          {...remainingProps}
          children={items}
          className={classNames([inactive && 'inactive', 'progress-bar'])}
        />
      )
    }
    return returnValue
  }
}

StepNavigation = connect(mapStateToProps, mapDispatchToProps)(StepNavigation)
export default withRouter(StepNavigation)
