import config from 'config/config'
import { globalize } from 'databinding/globalize'
import React from 'react'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import translations from './_Footer.i18n.json'

class ContactInfo extends React.PureComponent {
  render() {
    const {
      globalize: { translate, language },
    } = this.props

    return (
      <React.Fragment>
        <Header as="h3" children={config.company.name[language]} />
        <p>
          {config.company.address1}
          <br />
          {config.company.address2}
          <br />
          {config.company.zip} {config.company.city}
          <br />
        </p>
        <Divider hidden clearing />
        <p>
          {translate('contact.phone_short')}: <a href={`tel:${config.company.phone}`}>{config.company.phone}</a>
        </p>
        <p>
          {translate('contact.email')}: <a href={`mailto:${config.company.email}`}>{config.company.email}</a>
          <br />
        </p>
        <Divider hidden clearing />
        <p>
          <strong>{translate('contact.business_hours')}</strong>
          <br />
          {translate('contact.weekdays')} <br />
          {config.company.openingHours}
        </p>
      </React.Fragment>
    )
  }
}

ContactInfo = globalize({ translations })(ContactInfo)
export default ContactInfo
