import request from 'superagent'
import loadCurrentUser from 'databinding/shop/thunks/loadCurrentUser'

export default () => async (dispatch, getState) => {
  const { jwt: token } = getState().shop
  try {
    const response = await request
      .get(`${process.env.REACT_APP_SHOP_API}/users/refresh.json`)
      .set('Authorization', `Bearer ${token}`)
    const { jwt } = (response || {}).body || {}
    localStorage.setItem('jwt', jwt)
    dispatch(loadCurrentUser())
  } catch (e) {
    // todo redux: implement
  }
}
