import { confirmationModal } from 'components/Helpers/ConfirmationModal'
import translations from 'components/Login/_Login.i18n.json'
import NavigationLink from 'components/Navigation/NavigationLink'
import config from 'config/config'
import { globalize } from 'databinding/globalize'
import { userLogout } from 'databinding/shop/actions'
import getCurrentNode from 'lib/getCurrentNode'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

const mapStateToProps = (state) => {
  const { currentUser = { userType: 'visitor' } } = state.shop
  const { routes = [] } = state.cms
  return { currentUser, routes }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ push, userLogout }, dispatch)
}

class MenuItemLogin extends React.PureComponent {
  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
  }

  logout() {
    const {
      push,
      userLogout,
      routes, //connect
      globalize: { translate }, //globalize
      location: { pathname }, //withRouter
    } = this.props
    confirmationModal({
      icon: config.icons.signOut, // icon object, see config.js
      text: translate('login.logout.text'), // modal content (optional)
      title: translate('login.logout.title'), // modal title
      button: translate('login.logout.button'), // button Text
      confirm: () => {
        userLogout()
        localStorage.clear()
        const node = getCurrentNode(routes, pathname)
        if (node.includes('account') || node.includes('basket')) push('/')
      },
    })
  }

  render() {
    const {
      currentUser, //connect
      globalize: { translate }, //globalize
    } = this.props

    let renderValue = false

    if (currentUser.userType === 'visitor') {
      renderValue = (
        <NavigationLink responsive="hide-below-tablet" icon="lock" component="MenuItem" node="/user/login" />
      )
    } else {
      renderValue = (
        <Menu.Item responsive="hide-below-tablet" onClick={this.logout}>
          <Icon name="unlock" />
          <span className="hide-below-tablet" children={translate('login.logout.title')} />
        </Menu.Item>
      )
    }
    return renderValue
  }
}

MenuItemLogin = connect(mapStateToProps, mapDispatchToProps)(MenuItemLogin)
MenuItemLogin = globalize({ translations })(MenuItemLogin)
MenuItemLogin = withRouter(MenuItemLogin)
export default MenuItemLogin
