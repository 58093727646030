import { loadRoutesDone } from '../actions'
// eslint-disable-next-line
import Worker from 'worker-loader!databinding/worker/worker'

const worker = new Worker()

export default () => (dispatch) => {
  worker.addEventListener('message', (e) => {
    if (e.data.success) dispatch(loadRoutesDone({ routes: e.data.routes, userRoutes: e.data.userRoutes }))
    else console.log('routes could not be loaded', e.data)
  })
  worker.postMessage({
    action: 'getRoutes',
    userRoutesUrl: process.env.REACT_APP_CMS_API + '/routes.json',
    categoryRoutesUrl: process.env.REACT_APP_SHOP_API + '/product_categories/shop_routes.json',
  })
}
