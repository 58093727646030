import NavigationLink from 'components/Navigation/NavigationLink'
import { useGlobalize } from 'databinding/globalize'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import translations from './_Cart.i18n.json'
import loadCart from 'databinding/shop/thunks/loadCart'
import { usePrevious } from 'lib/usePrevious'
const i18nKey = 'cart.'

export default () => {
  const offerCart = useSelector(({ shop: { offerCart } }) => offerCart)
  const offerCartLoadedAt = useSelector(({ shop: { offerCartLoadedAt } }) => offerCartLoadedAt)
  const offerCartPending = useSelector(({ shop: { offerCartPending } }) => offerCartPending)
  const offerCartError = useSelector(({ shop: { offerCartError } }) => offerCartError)
  const { translate } = useGlobalize({ translations })
  const oldOfferCartLoadedAt = usePrevious(offerCartLoadedAt)
  const dispatch = useDispatch()

  // load cart if not loaded yet
  useEffect(() => {
    if (!offerCart?.id && localStorage.getItem('offerCartId')) {
      const id = localStorage.getItem('offerCartId')
      const cartName = 'offer'
      dispatch(loadCart({ id, cartName }))
    }
  }, [dispatch, offerCart])

  // remove localStorage item if error
  useEffect(() => {
    if (oldOfferCartLoadedAt !== offerCartLoadedAt && offerCartError) localStorage.removeItem('offerCartId')
  }, [offerCartLoadedAt, oldOfferCartLoadedAt, offerCartError])

  const offerItemsCount = (((offerCart || {}).data || {}).products || []).reduce(
    (acc, cur = {}) => acc + cur.quantity,
    0
  )

  return (
    <NavigationLink
      icon="file text outline"
      component="Button"
      node="/checkout/offer/basket"
      className="cart"
      loading={offerCartPending}
      secondary={offerItemsCount > 0}
      inverted={offerItemsCount < 1}
      disabled={offerItemsCount < 1}
      content={
        <span>
          <span className="hide-below-tablet" children={translate(i18nKey + 'offer')} />
          {offerItemsCount > 0 && <Label content={offerItemsCount} color={'red'} floating />}
        </span>
      }
    />
  )
}
