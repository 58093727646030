import request from 'superagent'
import { createAssetDone, createAssetFailed } from '../actions'
import loadAssets from 'databinding/cms/thunks/loadAssets'
import { CREATE_ASSET } from 'databinding/cms/constants'

export default ({ files, uuid }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_ASSET })
      const { jwt: token } = getState().shop
      const req = request.post(`${process.env.REACT_APP_CMS_API}/assets.json`).set('Authorization', `Bearer ${token}`)
      if (Array.isArray(files)) for (const file of files) req.attach(file.name, file, file.name)
      else if (files) req.attach(files.name, files)
      if (uuid) req.field({ uuid })
      await req
      dispatch(createAssetDone())
      dispatch(loadAssets())
    } catch (e) {
      dispatch(createAssetFailed({ error: e.message }))
      //todo redux: handle connection errors
      console.log(e)
    }
  }
