import NoHitsWarning from 'components/Shop/Product/ProductList/NoHitsWarning'
import React from 'react'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'

export default ({ segmentCSS, inline, heading }) => (
  <Segment basic className={segmentCSS}>
    {inline ? (
      <NoHitsWarning {...{ heading }} />
    ) : (
      <Container>
        <NoHitsWarning {...{ heading }} />
      </Container>
    )}
  </Segment>
)
