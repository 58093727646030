import { loadAssetsDone } from '../actions'
// eslint-disable-next-line
import Worker from 'worker-loader!databinding/worker/worker'

const worker = new Worker()

export default () => (dispatch) => {
  worker.addEventListener('message', (e) => {
    if (e.data.success) dispatch(loadAssetsDone({ assets: e.data.body }))
    else console.log('assets could not be loaded', e.data)
  })
  worker.postMessage({ action: 'get', url: process.env.REACT_APP_CMS_API + '/assets.json', convert: true })
}
