import React, { memo } from 'react'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Item from 'semantic-ui-react/dist/commonjs/views/Item'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'

export default memo(({ showAs,children, itemsPerRow }) => {
  switch (showAs) {
    case 'small':
      return <Grid verticalAlign="middle" divided="vertically" className="products small" children={children} />
    case 'list':
      return <Item.Group unstackable divided className="products list" children={children}/>
    default:
      return <Card.Group doubling stackable itemsPerRow={itemsPerRow} className="products normal" children={children}/>
  }
})
