import React from 'react'
import { useSelector } from 'react-redux'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import OrderButton from 'components/Shop/Product/AddToCart/OrderButton'
import OfferButton from 'components/Shop/Product/AddToCart/OfferButton'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'

export default ({ quickAdd, product, style }) => {
  const currentUser = useSelector(({ shop: { currentUser } }) => currentUser)
  if (['dealer', 'bop'].includes(currentUser.userType)) {
    return (
      <Form as="span" style={style}>
        <OrderButton {...{ quickAdd, product }} />
        {!quickAdd && <Divider clearing hidden />}
        <OfferButton {...{ quickAdd, product }} />
      </Form>
    )
  }
  if (['shop', 'user', 'admin'].includes(currentUser.userType)) {
    return (
      <Form as="span" style={style}>
        <OfferButton {...{ quickAdd, product }} />
      </Form>
    )
  }
  return (
    <Form as="span" style={style}>
      <OrderButton {...{ quickAdd, product }} />
    </Form>
  )
}
