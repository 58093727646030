import translations from 'components/cms/AssetBrowser/_AssetBrowser.i18n.json'
import ReduxFormInput from 'components/Helpers/ReduxForm/ReduxFormInput'
import { globalize } from 'databinding/globalize'
import capitalize from 'capitalize'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'

const i18nKey = 'asset_browser.editor.languages'

class TitleFields extends React.PureComponent {
  render() {
    const {
      languages,
      globalize: { translate },
    } = this.props
    return languages.map((language, index) => (
      <Field
        key={index}
        component={ReduxFormInput}
        fluid
        label={translate(`${i18nKey}.${language}`)}
        name={`title${capitalize(language)}`}
        width={12}
        placeholder={translate(`${i18nKey}.${language}`)}
      />
    ))
  }
}

TitleFields.propTypes = {
  languages: PropTypes.array,
}

TitleFields = globalize({ translations })(TitleFields)
export default TitleFields
