import { loadProductCategoriesDone } from '../actions'
// eslint-disable-next-line
import Worker from 'worker-loader!databinding/worker/worker.js'

const worker = new Worker()

export default () => async (dispatch) => {
  worker.addEventListener('message', (e) => {
    if (e.data.success) dispatch(loadProductCategoriesDone({ productCategories: e.data.body }))
    else console.log(e.data)
  })
  worker.postMessage({
    action: 'get',
    url: process.env.REACT_APP_SHOP_API + '/product_categories/shop_index.json',
    convert: true,
  })
}
