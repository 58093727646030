import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { useGlobalize } from 'databinding/globalize'
import { useSelector } from 'react-redux'
import { useAddToCart } from 'components/Shop/Product/AddToCart/useAddToCart'
import React from 'react'
import translations from 'components/Shop/_Shop.i18n.json'
import { useLocalStorageEffectOffer } from './useLocalStorageEffect'

const i18nKey = 'shop.product_detail.'

export default ({ quickAdd, product }) => {
  useLocalStorageEffectOffer()
  const { translate } = useGlobalize({ translations })
  const offerCartPending = useSelector(({ shop: { offerCartPending } }) => offerCartPending)
  const addToCart = useAddToCart(product, 'offer')
  return (
    <Button
      onClick={addToCart}
      loading={offerCartPending}
      disabled={offerCartPending}
      content={!quickAdd && translate(i18nKey + 'add_to_offer')}
      alt={translate(i18nKey + 'add_to_offer')}
      inverted={quickAdd}
      basic={quickAdd}
      fluid
      secondary
      icon="file text outline"
    />
  )
}
