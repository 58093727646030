import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import getBreadCrumbs from 'lib/getBreadCrumbs'
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import List from 'semantic-ui-react/dist/commonjs/elements/List'

const mapStateToProps = (state) => {
  const { routes } = state.cms
  return { routes }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

class NavigationBreadCrumb extends React.PureComponent {
  render() {
    const {
      breadcrumbOnly, //ownProps
      routes, //connect
      globalize: { language }, //globalize
      location: { pathname }, // withRouter
    } = this.props
    const breadCrumbs = getBreadCrumbs(routes, pathname)
    const linkItems = breadCrumbs.map((route = {}, index) => (
      <List.Item key={index}>
        <Link to={route.path[language]} children={route.navigationTitle[language] || route.title[language]} />
      </List.Item>
    ))

    return <List horizontal link items={linkItems} className={classNames(['bread-crumb', breadcrumbOnly && 'only'])} />
  }
}

NavigationBreadCrumb = connect(mapStateToProps, mapDispatchToProps)(NavigationBreadCrumb)
NavigationBreadCrumb = globalize()(NavigationBreadCrumb)
NavigationBreadCrumb = withRouter(NavigationBreadCrumb)
export default NavigationBreadCrumb
