import MyMarkdown from 'components/cms/cmsComponents/MyMarkdown'
import AssetDownload from 'components/Helpers/AssetDownload'
import AssetImage from 'components/Helpers/AssetImage'
import classNames from 'lib/classNames'
import React from 'react'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import ReactPlayer from 'react-player'

class TextImage extends React.PureComponent {
  getGridItems() {
    const { asset_id, columns = [] } = this.props //ownProps
    return columns.map((column = {}, index) => (
      <Grid.Column
        key={index}
        width={column.widths && column.widths.width}
        tablet={column.widths && column.widths.tablet}
        mobile={column.widths && column.widths.mobile}
        computer={column.widths && column.widths.computer}
        widescreen={column.widths && column.widths.widescreen}
        largeScreen={column.widths && column.widths.largeScreen}
        href={column.link}
        target={column.target}>
        {column.text && asset_id && <Divider hidden section />}
        {column.heading && <Header content={column.heading} as="h3" id={column.id} />}
        {column.asset_id && (
          <div className="image-container">
            <AssetImage id={column.asset_id} fluid />
          </div>
        )}
        {column.text && <MyMarkdown children={column.text} />}
        {column.download_id && (
          <React.Fragment>
            <Divider hidden />
            <AssetDownload id={column.download_id} />
            <Divider hidden />
          </React.Fragment>
        )}

        {column.video_url && (
          <div className="video-container">
            <ReactPlayer url={column.video_url} {...column.video_options} playsinline />
          </div>
        )}
      </Grid.Column>
    ))
  }

  getPanels() {
    const { columns = [] } = this.props //ownProps

    return columns.map((column = {}, index) => ({
      title: column.heading,
      content: {
        key: index,
        content: column.text && <MyMarkdown children={column.text} />,
      },
    }))
  }

  render() {
    const {
      heading,
      id,
      columns,
      asset_id,
      css_classes: segmentClasses,
      layout = {
        textAlignment: 'left',
        fullbleed: false,
        clipping: false,
        parallax: false,
        spacing: false,
        segment: false,
      }, //ownProps
    } = this.props //ownProps

    const containerClasses = classNames([layout.clipping === true && 'clipping'])

    let columnGrid = columns && columns.length > 0 && (
      <Grid stackable {...{ relaxed: !layout.fullbleed }} className={classNames(['text-image'])}>
        <Grid.Row
          {...{
            columns: layout.columns && layout.columns > 0 ? layout.columns : columns.length,
            children: this.getGridItems(),
          }}
        />
      </Grid>
    )

    const accordion = <Accordion styled fluid panels={this.getPanels()} />

    return (
      <Segment basic className={classNames(segmentClasses)}>
        {heading && (
          <Container>
            <Header
              as="h2"
              {...{
                textAlign: layout.textAlignment,
                content: heading,
                id,
              }}
            />
          </Container>
        )}
        {asset_id && (
          <Container {...{ className: containerClasses, fluid: layout.fullbleed }}>
            <div className="image-container">
              <AssetImage fluid {...{ id: asset_id }} />
            </div>
          </Container>
        )}

        <Container
          children={layout.accordion ? accordion : columnGrid}
          className={classNames([layout.spacing && 'spacing'])}
        />
      </Segment>
    )
  }
}

export default TextImage
