import ContactInfo from 'components/Navigation/Footer/ContactInfo'
import Footer from 'components/Navigation/Footer/Footer'
import CookieBar from 'components/Navigation/Header/CookieBar/CookieBar'
import SiteHeader from 'components/Navigation/Header/SiteHeader'
import MenuItemAccount from 'components/Navigation/Menu/MenuItems/MenuItemAccount'
import MenuItemLanguages from 'components/Navigation/Menu/MenuItems/MenuItemLanguages'
import MenuItemLogo from 'components/Navigation/Menu/MenuItems/MenuItemLogo'
import NavigationChildren from 'components/Navigation/NavigationChildren'
import config from 'config/config'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar'
import Visibility from 'semantic-ui-react/dist/commonjs/behaviors/Visibility'
import { usePrevious } from 'lib/usePrevious'
import ErrorBoundary from 'ErrorBoundary'

export default ({ children }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false)
  const [floatingNavigation, setFloatingNavigation] = useState(false)
  const { pathname } = useLocation()
  const previousPathname = usePrevious(pathname)
  const toggleSidebar = useCallback(() => setSidebarVisible(!sidebarVisible), [sidebarVisible, setSidebarVisible])
  const setFixed = useCallback(() => setFloatingNavigation(false), [setFloatingNavigation])
  const setFloating = useCallback(() => setFloatingNavigation(true), [setFloatingNavigation])

  useEffect(() => {
    if (pathname !== previousPathname) setSidebarVisible(false)
  }, [pathname, previousPathname, setSidebarVisible])

  useEffect(() => {
    const downHandler = (event) => {
      if (event && event.key === 'Escape') setSidebarVisible(false)
    }
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  })

  const dark = config.theme.darkStorefront
  const sidebarProps = {
    as: Menu,
    animation: 'overlay',
    direction: 'left',
    width: 'wide',
    visible: sidebarVisible,
    vertical: true,
    inverted: true,
    size: 'massive',
    className: 'sidebar-storefront',
  }
  const menuProps = {
    inverted: true,
    vertical: true,
    secondary: true,
    pointing: true,
    floated: 'right',
    size: 'large',
  }

  return (
    <ErrorBoundary location="navigation-wrapper">
      <Sidebar.Pushable as={Segment}>
        <Sidebar {...sidebarProps}>
          <Menu {...menuProps}>
            <Menu.Item onClick={toggleSidebar} className="unobtrusive close">
              <Icon name="close" link />
            </Menu.Item>
            <Menu.Item>
              <MenuItemLogo showLogo inverted={dark} />
            </Menu.Item>
          </Menu>
          <NavigationChildren component="MenuItems" node="/bartscher" {...menuProps} />
          <Divider hidden />
          <Menu size="large" vertical secondary floated="right">
            <MenuItemAccount />
          </Menu>
          <Divider clearing section hidden />
          <Menu {...menuProps}>
            <Menu.Item>
              <ContactInfo />
            </Menu.Item>
          </Menu>
          <Divider clearing section hidden />
          <MenuItemLanguages mobile />
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarVisible}>
          <Segment basic id="content">
            <CookieBar />

            <SiteHeader {...{ toggleSidebar, floatingNavigation }} />
            <Visibility
              {...{
                // causes menu to be stuck on breakpoint transition mobile > tablet
                offset: [42, 0], // no more than 42 (less than searchHeader / 2), becaus: scrolling on mobile
                onTopVisible: setFixed,
                onTopPassed: setFloating,
                fireOnMount: true,
                continuous: false,
                once: false,
                context: window.document.body,
                style: { minHeight: '1000px' },
              }}
              children={children}
            />
          </Segment>
          <Footer />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </ErrorBoundary>
  )
}
