import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'

const mapStateToProps = undefined

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ push }, dispatch)
}

class DropdownNavigation extends React.PureComponent {
  getDropdownOptions() {
    const { routes } = this.props
    const { push } = this.props

    return routes.map((route, index) => {
      let returnValue = false
      if (route) {
        returnValue = {
          key: index,
          icon: route.icon,
          value: index,
          onClick: () => push(route.path),
          active: route.active,
          text: route.navigationTitle,
        }
      } else {
        console.warn('Route is undefined')
      }
      return returnValue
    })
  }

  render() {
    // noinspection JSUnusedLocalSymbols Do not remove unused imports!
    const { routes, history, location, match, selection, staticContext, node, push, ...remainingProps } = this.props //eslint-disable-line no-unused-vars
    const options = this.getDropdownOptions()
    let returnValue = false

    if (options.length > 0) {
      returnValue = <Dropdown selection {...remainingProps} options={options} />
    }
    return returnValue
  }
}

DropdownNavigation = connect(mapStateToProps, mapDispatchToProps)(DropdownNavigation)
export default withRouter(DropdownNavigation)
