import NavigationChildren from 'components/Navigation/NavigationChildren'
import config from 'config/config'
import { useGlobalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import React from 'react'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import pushNode from 'databinding/cms/thunks/pushNode'
import { useDispatch } from 'react-redux'

export default ({ filterValue, floatingNavigation, updateFilterValue, setShowSearch }) => {
  const { translate } = useGlobalize()
  const dispatch = useDispatch()
  const dark = config.theme.darkStorefront
  return (
    <div
      className={classNames([
        'header-menu-mobile',
        dark && 'inverted',
        floatingNavigation && 'floating',
        'hide-above-computer',
      ])}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Form.Input
              fluid
              {...{
                onChange: updateFilterValue,
                value: filterValue,
                placeholder: translate('global.search'),
                className: classNames([dark && 'inverted']),
                action: {
                  inverted: true,
                  icon: 'search',
                  onClick: () => dispatch(pushNode({ node: '/search', params: { search: filterValue } })),
                },
                onFocus: () => setShowSearch(filterValue.length > 2),
                onKeyDown: (e) => {
                  if (!e) {
                    return
                  }
                  if (e.key === 'Escape') {
                    setShowSearch(false)
                  }
                  if (e.key === 'Enter') {
                    dispatch(pushNode({ node: '/search', params: { search: filterValue } }))
                  }
                },
              }}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <div className="ui form">
              <div className="inverted field">
                <NavigationChildren
                  component="Dropdown"
                  node="/categories"
                  fluid
                  selection
                  text={translate('global.select_product')}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
