import classNames from 'lib/classNames'
import React from 'react'
import { useSelector } from 'react-redux'
import ProgressiveImage from './ProgressiveImage'
import { useGlobalize } from 'databinding/globalize'
import placeholderDe from 'images/placeholder_de.jpg'
import placeholderFr from 'images/placeholder_fr.jpg'

export default ({ id, fluid, size }) => {
  const assets = useSelector(({ cms: { assets } }) => assets)
  const asset = assets.find((asset) => asset.uuid === id) || {}
  const { language } = useGlobalize()
  const placeholder = language === 'fr' ? placeholderFr : placeholderDe
  return (
    <ProgressiveImage
      asset={asset}
      children={({ src, error, loading }) => {
        if (!src || error)
          return (
            <img alt="" src={placeholder} className={classNames(['ui', 'image', fluid && 'fluid', size && size])} />
          )
        return (
          <img
            alt=""
            src={src}
            className={classNames(['ui', 'image', loading && 'blurred', fluid && 'fluid', size && size])}
          />
        )
      }}
    />
  )
}
