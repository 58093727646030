import translations from 'components/cms/AssetBrowser/_AssetBrowser.i18n.json'
import { globalize } from 'databinding/globalize'
import PropTypes from 'prop-types'
import React from 'react'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

const i18nKey = 'asset_browser'

class EditDropdown extends React.PureComponent {
  constructor(props) {
    super(props)
    this.deleteAsset = this.deleteAsset.bind(this)
  }

  deleteAsset() {
    const { deleteAsset, asset } = this.props
    deleteAsset(asset.id)
  }

  standardItems() {
    const { setEditingAssetID } = this.context
    const {
      asset, //ownProps
      globalize: { translate }, // globalize
    } = this.props
    return [
      <Dropdown.Item
        key={0}
        onClick={() => setEditingAssetID(asset.id)}
        icon="pencil"
        text={translate(`${i18nKey}.edit`)}
      />,
      <Dropdown.Item key={1} onClick={this.deleteAsset} icon="delete" text={translate(`${i18nKey}.delete`)} />,
    ]
  }

  customItems() {
    const { customActions } = this.context
    const { asset } = this.props
    return customActions(asset).map((customAction, index) => (
      <Dropdown.Item
        {...{
          key: index + 2,
          ...customActions,
        }}
        /*
                key={index + 2}
                text={customAction.text}
                disabled={customAction.disabled}
                icon={customAction.icon}
                description={customAction.description}
                onClick={customAction.onClick}
        */
      />
    ))
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown icon={null} trigger={<Icon name="ellipsis horizontal" inverted size="large" />}>
          <Dropdown.Menu children={this.standardItems().concat(this.customItems())} />
        </Dropdown>
      </React.Fragment>
    )
  }
}

EditDropdown.contextTypes = {
  setEditingAssetID: PropTypes.func,
  customActions: PropTypes.func,
  asset: PropTypes.object,
}

EditDropdown = globalize({ translations })(EditDropdown)
export default EditDropdown
