import { globalize } from 'databinding/globalize'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import getProductUrl from './lib/getProductUrl'

const mapStateToProps = (state) => {
  const { products = [] } = state.shop
  const { routes = [] } = state.cms
  return { products, routes }
}

const mapDispatchToProps = undefined

class ProductRedirect extends React.PureComponent {
  render() {
    const {
      products = [],
      routes,
      globalize: { language },
      match: { params },
    } = this.props

    const articleNumber = params.articleNumber
    const product = products.find((product) => product.articleNumber === articleNumber)
    const redirectTarget = getProductUrl({
      product,
      routes,
      language,
      articleNumber,
    })
    return articleNumber && redirectTarget && <Redirect to={redirectTarget} />
  }
}

ProductRedirect = connect(mapStateToProps, mapDispatchToProps)(ProductRedirect)
ProductRedirect = globalize()(ProductRedirect)
export default ProductRedirect
