import { loadDealersDone } from '../actions'
// eslint-disable-next-line
import Worker from 'worker-loader!databinding/worker/worker.js'

const worker = new Worker()

export default () => (dispatch) => {
  worker.addEventListener('message', (e) => {
    if (e.data.success) dispatch(loadDealersDone({ dealers: e.data.body }))
    else console.log(e.data)
  })
  worker.postMessage({ action: 'get', url: process.env.REACT_APP_SHOP_API + '/users/shop_index.json', convert: true })
}
