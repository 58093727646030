import React, { memo } from 'react'
import { useGlobalize } from 'databinding/globalize'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

export default memo(({ selectedProperties, setSelectedProperties, product }) => {
  const { language } = useGlobalize()
  return (product.selectableProperties || []).map((prop) => (
    <React.Fragment key={prop.id}>
      <Form.Dropdown
        selection
        fluid
        placeholder={prop.name[language]}
        label={
          <label>
            {prop.name[language]}
            {prop.description[language] && (
              <Popup
                content={prop.description[language]}
                trigger={<Icon link name="help circle" color="orange" />}
                size="tiny"
                wide
                style={{ borderRadius: '4px' }}
              />
            )}
          </label>
        }
        labeled
        options={prop.values.map((value) => ({ value: value.id, text: value.value[language] }))}
        onChange={(_, comp) => {
          const value = prop.values.find((v) => v.id === comp.value)
          setSelectedProperties({
            ...selectedProperties,
            [prop.key]: { id: prop.id, name: prop.name, valueId: value.id, value: value.value },
          })
        }}
      />
      <Divider hidden />
    </React.Fragment>
  ))
})
