import getCurrentNode from 'lib/getCurrentNode'
import getIndexRoute from 'lib/getIndexRoute'
import { dirname } from 'path'

export default (routes, pathname) => {
  let node = getCurrentNode(routes, pathname)
  let breadcrumbs = []
  while (node !== '/') {
    // eslint-disable-next-line
    const route = routes.find((route) => route.node === node)
    if (route) {
      breadcrumbs.unshift(route)
    }
    node = dirname(node)
  }
  const indexRoute = getIndexRoute(routes)
  if (indexRoute) {
    breadcrumbs.unshift(indexRoute)
  }
  return breadcrumbs
}
