import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'

const RouterLink = ({ href, children }) =>
  href.match(/^(https?:)?\/\//) || href.match(/^mailto:/) ? (
    <a href={href} children={children} />
  ) : (
    <Link to={href} children={children} />
  )

export default (props) => <ReactMarkdown skipHtml components={{ link: RouterLink }} {...props} />
