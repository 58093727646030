import darkTheme from 'lib/darkTheme'
import PropTypes from 'prop-types'
import React from 'react'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

class SelectLayout extends React.PureComponent {
  render() {
    const dark = darkTheme()
    const { viewAsList, toggleViewStyle } = this.props
    return (
      <Button.Group size="large">
        <Button inverted={dark} onClick={toggleViewStyle} disabled={viewAsList} icon="list layout" />
        <Button inverted={dark} onClick={toggleViewStyle} disabled={!viewAsList} icon="block layout" />
      </Button.Group>
    )
  }
}

SelectLayout.propTypes = {
  viewAsList: PropTypes.bool,
  toggleViewStyle: PropTypes.func,
}
export default SelectLayout
