import {
  CLEAR_CART,
  CLEAR_CART_PDF,
  CLEAR_SCROLL_POSITION,
  CLEAR_STATE,
  CREATE_CART_DONE,
  CREATE_USER_LOGO_DONE,
  CREATE_USER_LOGO_FAILED,
  DELETE_ADDRESS_DONE,
  DELETE_ADDRESS_FAILED,
  DELETE_CART_DONE,
  DELETE_CART_FAILED,
  DELETE_USER_LOGO_DONE,
  DELETE_USER_LOGO_FAILED,
  FINALIZE_CART_DONE,
  FINALIZE_CART_FAILED,
  HIDE_SALES_MESSAGE,
  LOAD_ARCHIVED_PRODUCTS_DONE,
  LOAD_ARCHIVED_PRODUCTS_FAILED,
  LOAD_CART_DONE,
  LOAD_CART_FAILED,
  LOAD_MY_ORDERS_DONE,
  LOAD_MY_ORDERS_FAILED,
  LOAD_CURRENT_USER_DONE,
  LOAD_CURRENT_USER_FAILED,
  LOAD_DEALERS_DONE,
  LOAD_JWT_PUBLIC_KEY_DONE,
  LOAD_PRODUCT_CATEGORIES_DONE,
  LOAD_PRODUCT_PROPERTIES_DONE,
  LOAD_PRODUCT_PROPERTIES_FAILED,
  LOAD_PRODUCTS_DONE,
  LOAD_SPARE_PARTS_DONE,
  LOAD_SPARE_PARTS_FAILED,
  LOAD_SPECIAL_ITEMS_DONE,
  LOAD_USERS_DONE,
  LOAD_USERS_FAILED,
  STORE_SCROLL_POSITION,
  UPDATE_ADDRESS_DONE,
  UPDATE_ADDRESS_FAILED,
  UPDATE_CART_ADMIN_DONE,
  UPDATE_CART_DONE,
  UPDATE_CART_FAILED,
  UPDATE_PRODUCT_CATEGORY,
  UPDATE_USER_DONE,
  UPDATE_USER_FAILED,
  USER_FORGOT_PASSWORD_DONE,
  USER_FORGOT_PASSWORD_FAILED,
  USER_LOGIN_DONE,
  USER_LOGIN_FAILED,
  USER_LOGOUT,
  USER_REFRESH_DONE,
  USER_REFRESH_FAILED,
  USER_REQUEST_PASSWORD_DONE,
  USER_REQUEST_PASSWORD_FAILED,
  USER_RESET_JWT_ERROR,
  LOAD_CUSTOMER_ORDERS_DONE,
  LOAD_DEALER_ORDERS_DONE,
  LOAD_DEALER_ORDERS_FAILED,
  LOAD_CUSTOMER_ORDERS_FAILED,
  LOAD_SAVED_CARTS_DONE,
  LOAD_SAVED_CARTS_FAILED,
  LOAD_OFFERS_DONE,
  LOAD_OFFERS_FAILED,
  CREATE_TERMS_PDF_DONE,
} from './constants'

// todo redux: what was that used for
export const clearState = () => ({ type: CLEAR_STATE })

export const clearCart = ({ cartName }) => ({ type: CLEAR_CART, cartName })

// todo redux: check if done methods used at all
export const userRequestPasswordDone = () => ({ type: USER_FORGOT_PASSWORD_DONE })
export const userResetPasswordDone = () => ({ type: USER_REQUEST_PASSWORD_DONE })
export const updateCurrentUserDone = () => ({ type: UPDATE_USER_DONE })
export const updateAddressDone = () => ({ type: UPDATE_ADDRESS_DONE })
export const deleteAddressDone = () => ({ type: DELETE_ADDRESS_DONE })
export const createUserLogoDone = () => ({ type: CREATE_USER_LOGO_DONE })
export const deleteUserLogoDone = () => ({ type: DELETE_USER_LOGO_DONE })
export const createTermsPDFDone = () => ({ type: CREATE_TERMS_PDF_DONE })

export const userLoginDone = ({ jwt }) => ({ type: USER_LOGIN_DONE, jwt })
export const userLoginFailed = ({ error }) => ({ type: USER_LOGIN_FAILED, error })
export const userLogout = () => ({ type: USER_LOGOUT })
export const userResetJwtError = () => ({ type: USER_RESET_JWT_ERROR })

export const userRefreshDone = ({ jwt }) => ({ type: USER_REFRESH_DONE, jwt })
export const userRefreshFailed = ({ error }) => ({ type: USER_REFRESH_FAILED, error })

export const userRequestPasswordFailed = ({ error }) => ({ type: USER_FORGOT_PASSWORD_FAILED, error })

export const userResetPasswordFailed = ({ error }) => ({ type: USER_REQUEST_PASSWORD_FAILED, error })

export const loadCurrentUserDone = ({ currentUser }) => ({ type: LOAD_CURRENT_USER_DONE, currentUser })
export const loadCurrentUserFailed = () => ({ type: LOAD_CURRENT_USER_FAILED })

export const updateCurrentUserFailed = () => ({ type: UPDATE_USER_FAILED })

export const loadJwtPublicKeyDone = ({ jwtPublicKey }) => ({ type: LOAD_JWT_PUBLIC_KEY_DONE, jwtPublicKey })

export const loadProductsDone = ({ rawProducts }) => ({ type: LOAD_PRODUCTS_DONE, rawProducts })

export const loadArchivedProductsDone = ({ archivedProducts }) => ({
  type: LOAD_ARCHIVED_PRODUCTS_DONE,
  archivedProducts,
})
export const loadArchivedProductsFailed = ({ error }) => ({ type: LOAD_ARCHIVED_PRODUCTS_FAILED, error })

export const loadSpecialItemsDone = ({ specialItems }) => ({ type: LOAD_SPECIAL_ITEMS_DONE, specialItems })

export const loadProductCategoriesDone = ({ productCategories }) => ({
  type: LOAD_PRODUCT_CATEGORIES_DONE,
  productCategories,
})
export const updateProductCategory = ({ productCategory }) => ({ type: UPDATE_PRODUCT_CATEGORY, productCategory })

export const loadProductPropertiesDone = ({ productProperties }) => ({
  type: LOAD_PRODUCT_PROPERTIES_DONE,
  productProperties,
})
export const loadProductPropertiesFailed = ({ error }) => ({ type: LOAD_PRODUCT_PROPERTIES_FAILED, error })

export const createCartDone = ({ cart }) => ({ type: CREATE_CART_DONE, cart })

export const updateCartDone = ({ cart }) => ({ type: UPDATE_CART_DONE, cart })
export const updateCartFailed = ({ error }) => ({ type: UPDATE_CART_FAILED, error })

export const updateCartAdminDone = ({ cart }) => ({ type: UPDATE_CART_ADMIN_DONE, cart })

export const finalizeCartDone = ({ cart }) => ({ type: FINALIZE_CART_DONE, cart })
export const finalizeCartFailed = ({ error }) => ({ type: FINALIZE_CART_FAILED, error })
export const deleteCartDone = () => ({ type: DELETE_CART_DONE })
export const deleteCartFailed = ({ error }) => ({ type: DELETE_CART_FAILED, error })

export const loadCartDone = ({ cart }) => ({ type: LOAD_CART_DONE, cart })
export const loadCartFailed = ({ error, cartName }) => ({ type: LOAD_CART_FAILED, error, cartName })

export const clearCartPDF = ({ language }) => ({ type: CLEAR_CART_PDF, language })

export const loadMyOrdersDone = ({ myOrders }) => ({ type: LOAD_MY_ORDERS_DONE, myOrders })
export const loadMyOrdersFailed = ({ error }) => ({ type: LOAD_MY_ORDERS_FAILED, error })

export const loadCustomerOrdersDone = ({ customerOrders }) => ({ type: LOAD_CUSTOMER_ORDERS_DONE, customerOrders })
export const loadCustomerOrdersFailed = ({ error }) => ({ type: LOAD_CUSTOMER_ORDERS_FAILED, error })

export const loadDealerOrdersDone = ({ dealerOrders }) => ({ type: LOAD_DEALER_ORDERS_DONE, dealerOrders })
export const loadDealerOrdersFailed = ({ error }) => ({ type: LOAD_DEALER_ORDERS_FAILED, error })

export const loadSavedCartsDone = ({ savedCarts }) => ({ type: LOAD_SAVED_CARTS_DONE, savedCarts })
export const loadSavedCartsFailed = ({ error }) => ({ type: LOAD_SAVED_CARTS_FAILED, error })

export const loadOffersDone = ({ offers }) => ({ type: LOAD_OFFERS_DONE, offers })
export const loadOffersFailed = ({ error }) => ({ type: LOAD_OFFERS_FAILED, error })

export const updateAddressFailed = ({ error }) => ({ type: UPDATE_ADDRESS_FAILED, error })

export const deleteAddressFailed = ({ error }) => ({ type: DELETE_ADDRESS_FAILED, error })

export const loadDealersDone = ({ dealers }) => ({ type: LOAD_DEALERS_DONE, dealers })

export const loadUsersDone = ({ users }) => ({ type: LOAD_USERS_DONE, users })
export const loadUsersFailed = (error) => ({ type: LOAD_USERS_FAILED, error })

export const createUserLogoFailed = ({ error }) => ({ type: CREATE_USER_LOGO_FAILED, error })

export const deleteUerLogoFailed = ({ error }) => ({ type: DELETE_USER_LOGO_FAILED, error })

export const loadSparePartsDone = ({ spareParts }) => ({ type: LOAD_SPARE_PARTS_DONE, spareParts })
export const loadSparePartsFailed = ({ error }) => ({ type: LOAD_SPARE_PARTS_FAILED, error })

export const storeScrollPosition = (route, displayItems, articleNumber, showAsList) => ({
  type: STORE_SCROLL_POSITION,
  route,
  displayItems,
  articleNumber,
  showAsList,
})
export const clearScrollPosition = (route) => ({ type: CLEAR_SCROLL_POSITION, route })

export const hideSalesMessage = () => ({ type: HIDE_SALES_MESSAGE })
