import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import { useGlobalize } from 'databinding/globalize'
import { useSelector } from 'react-redux'
import { useAddToCart } from 'components/Shop/Product/AddToCart/useAddToCart'
import React, { memo, useState } from 'react'
import translations from 'components/Shop/_Shop.i18n.json'
import SelectableProperties from './SelectableProperties'
import { useLocalStorageEffectOrder } from './useLocalStorageEffect'

const i18nKey = 'shop.product_detail.'

export default memo(({ quickAdd, product }) => {
  useLocalStorageEffectOrder()
  const { translate } = useGlobalize({ translations })
  const [selectedProperties, setSelectedProperties] = useState(
    (product.selectableProperties || []).reduce((acc, { key }) => ({ ...acc, [key]: undefined }), {})
  )
  const orderCartPending = useSelector(({ shop: { orderCartPending } }) => orderCartPending)
  const addToCart = useAddToCart(product, 'order', selectedProperties)
  const everythingSelected = Object.values(selectedProperties).reduce((acc, val) => acc && !!val, true)
  return (
    <React.Fragment>
      <SelectableProperties {...{ selectedProperties, setSelectedProperties, product }} />
      <Button
        onClick={addToCart}
        loading={orderCartPending}
        disabled={orderCartPending || !everythingSelected}
        content={!quickAdd && translate(i18nKey + 'add_to_cart')}
        alt={translate(i18nKey + 'add_to_cart')}
        inverted={quickAdd}
        basic={quickAdd}
        fluid
        primary
        icon="cart"
      />
    </React.Fragment>
  )
})
