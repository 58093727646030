import NavigationChildren from 'components/Navigation/NavigationChildren'
import React from 'react'
import { connect } from 'react-redux'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'

const mapStateToProps = (state) => {
  const { currentUser = { userType: 'visitor' } } = state.shop
  return { currentUser }
}

class MenuItemAccountDropdown extends React.PureComponent {
  render() {
    const { currentUser } = this.props
    return (
      currentUser.userType !== 'visitor' && (
        <Menu.Item className={'hide-below-tablet'}>
          <Dropdown item text={currentUser.email}>
            <Dropdown.Menu>
              <NavigationChildren component="DropdownMenuItems" node={`/account/${currentUser.userType}`} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      )
    )
  }
}

MenuItemAccountDropdown = connect(mapStateToProps)(MenuItemAccountDropdown)
export default MenuItemAccountDropdown
