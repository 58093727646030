import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import React from 'react'
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'

class ComponentHeaderButton extends React.PureComponent {
  render() {
    const { action, index } = this.props
    const innerButton = (
      <Button
        {...{
          key: index,
          as: action.as,
          target: action.target,
          href: action.href,
          color: action.color,
          icon: action.icon,
          inverted: action.inverted,
          content: action.content,
          onClick: action.onClick,
          secondary: action.secondary,
          disabled: action.disabled,
          primary: action.primary,
          labelPosition: action.content && (action.labelPosition || 'right'),
          className: classNames(['icon', 'hide-below-tablet', 'componentButton']),
          size: 'mini',
        }}
      />
    )
    let returnValue = innerButton

    if (action.hint) {
      returnValue = (
        <Popup
          {...{
            content: action.hint,
            inverted: action.inverted,
            trigger: innerButton,
            size: 'mini',
          }}
        />
      )
    }
    return returnValue
  }
}

ComponentHeaderButton = globalize()(ComponentHeaderButton)
export default ComponentHeaderButton
