import NavigationChildren from 'components/Navigation/NavigationChildren'
import CardNavigationHeader from 'components/Navigation/renditions/children/CardNavigationHeader'
import config from 'config/config'
import { useGlobalize } from 'databinding/globalize'
import buildRoutes from 'lib/buildRoutes'
import classNames from 'lib/classNames'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Card from 'semantic-ui-react/dist/commonjs/views/Card'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import { usePrevious } from 'lib/usePrevious'
export default ({ route, level, path }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { pathname } = useLocation()
  const { language, translate } = useGlobalize()
  const routes = useSelector(({ cms: { routes } }) => routes)
  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
  const previousPathname = usePrevious(pathname)

  useEffect(() => {
    if (pathname !== previousPathname) setIsOpen(false)
  }, [pathname, previousPathname, setIsOpen])

  if (!route) return false
  const showSubNavi = buildRoutes({ node: route.node, level, pathname, language, path, routes }).length > 0
  const classNamesCard = classNames([isOpen && 'active', !showSubNavi && 'single'])

  return (
    <Card raised={route.active} fluid className={classNamesCard}>
      {showSubNavi ? (
        <Card.Content onClick={toggleOpen}>
          <Header className="ellipsis" as="h3" content={route.navigationTitle} />
        </Card.Content>
      ) : (
        <Card.Content>
          <CardNavigationHeader route={route} />
        </Card.Content>
      )}
      <Card.Description>
        <Menu secondary vertical>
          <NavigationChildren component="MenuItems" node={route.node} />
          {showSubNavi && (
            <Menu.Item position="right">
              <CardNavigationHeader route={route} content={translate('global.shop.all_products')} />
              <Icon name="angle right" color={config.semantic.primaryColor} />
            </Menu.Item>
          )}
        </Menu>
      </Card.Description>
    </Card>
  )
}
