import React from 'react'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

export default ({visible, showAs, toggleViewStyle}) => !!visible && (
  <Container>
    <Menu icon secondary>
      <Menu.Item name="list" active={showAs !== 'cards'} onClick={toggleViewStyle}>
        <Icon name="list layout" />
      </Menu.Item>
      <Menu.Item name="grid" active={showAs === 'cards'} onClick={toggleViewStyle}>
        <Icon name="grid layout" />
      </Menu.Item>
    </Menu>
    <Divider hidden />
  </Container>
)
