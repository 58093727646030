import React, { memo } from 'react'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import MyMarkdown from 'components/cms/cmsComponents/MyMarkdown'
import ViewToggler from './Helpers/ViewToggler'
import ProductListViewContainer from './ProductListViewContainer'
import ProductListViewItem from 'components/Shop/Product/ProductList/ProductListViewItem'

export default memo(
  ({ heading, showAs, as, products, itemsPerRow, filterText, text, layout, toggleViewStyle }) => (
    <React.Fragment>
      {heading && <Header content={heading} as="h2" />}
      {text && <MyMarkdown className="description" children={text} />}
      <ViewToggler
        visible={layout.toggle && !as && products.length > 0}
        showAs={showAs}
        toggleViewStyle={toggleViewStyle}
      />
      <ProductListViewContainer
        {...{
          showAs,
          products,
          itemsPerRow,
          children: products.map((product = {}) => (
            <ProductListViewItem {...{ key: product.id, product, filterText, showAs }} />
          )),
        }}/>
    </React.Fragment>
  )
)
