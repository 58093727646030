import config from 'config/config'

// CHF formatting
export default (amount) => {
  // const prefix = amount < 0 ? '- ' : ''
  const formattedCurrency = Intl.NumberFormat('de-CH', config.format.currency.CHF)
    .format(amount)
    // .replace('.00', '.–')
    .replace('CHF', 'CHF\u202f')

  return `${formattedCurrency}`
}
