import MenuItemAccountDropdown from 'components/Navigation/Menu/MenuItems/MenuItemAccountDropdown'
import MenuItemLogin from 'components/Navigation/Menu/MenuItems/MenuItemLogin'
import MenuItemCartOffers from 'components/Shop/MenuItemCarts/MenuItemCartOffers'
import MenuItemCartOrders from 'components/Shop/MenuItemCarts/MenuItemCartOrders'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'

const mapStateToProps = (state) => {
  const { currentUser = { userType: 'visitor' } } = state.shop
  return { currentUser }
}

class AccountMenu extends React.PureComponent {
  render() {
    const { currentUser } = this.props //connect
    switch (currentUser.userType) {
      case 'admin':
        return (
          <Menu.Menu position="right" className="account">
            <Menu.Item>
              <MenuItemCartOffers />
            </Menu.Item>
            <MenuItemAccountDropdown />
            <MenuItemLogin />
          </Menu.Menu>
        )

      case 'dealer':
      case 'bop':
        return (
          <Menu.Menu position="right" className="account">
            <Menu.Item>
              <MenuItemCartOffers />
            </Menu.Item>
            <Menu.Item fitted>
              <MenuItemCartOrders />
            </Menu.Item>
            <MenuItemAccountDropdown />
            <MenuItemLogin />
          </Menu.Menu>
        )

      case 'user':
        return (
          <Menu.Menu position="right" className="account">
            <Menu.Item>
              <MenuItemCartOffers />
            </Menu.Item>
            <MenuItemAccountDropdown />
            <MenuItemLogin />
          </Menu.Menu>
        )

      case 'shop':
        return (
          <Menu.Menu position="right" className="account">
            <Menu.Item>
              <MenuItemCartOffers />
            </Menu.Item>
            <MenuItemAccountDropdown />
            <MenuItemLogin />
          </Menu.Menu>
        )

      case 'customer':
        return (
          <Menu.Menu position="right" className="account">
            <Menu.Item fitted>
              <MenuItemCartOrders />
            </Menu.Item>
            <MenuItemAccountDropdown />
            <MenuItemLogin />
          </Menu.Menu>
        )

      default:
        return (
          <Menu.Menu position="right" className="account">
            <Menu.Item fitted>
              <MenuItemCartOrders />
            </Menu.Item>
            <MenuItemLogin />
          </Menu.Menu>
        )
    }
  }
}

AccountMenu = connect(mapStateToProps)(AccountMenu)
AccountMenu = withRouter(AccountMenu)
export default AccountMenu
