export const LOAD_ASSETS_DONE = 'cms/LOAD_ASSETS_DONE'

export const CREATE_ASSET = 'cms/CREATE_ASSET'
export const CREATE_ASSET_DONE = 'cms/CREATE_ASSET_DONE'
export const CREATE_ASSET_FAILED = 'cms/CREATE_ASSET_FAILED'

export const REPLACE_ASSET = 'cms/REPLACE_ASSET'
export const REPLACE_ASSET_DONE = 'cms/REPLACE_ASSET_DONE'
export const REPLACE_ASSET_FAILED = 'cms/REPLACE_ASSET_FAILED'

export const SAVE_ASSET = 'cms/SAVE_ASSET'
export const SAVE_ASSET_DONE = 'cms/SAVE_ASSET_DONE'
export const SAVE_ASSET_FAILED = 'cms/SAVE_ASSET_FAILED'

export const DELETE_ASSET = 'cms/DELETE_ASSET'
export const DELETE_ASSET_DONE = 'cms/DELETE_ASSET_DONE'
export const DELETE_ASSET_FAILED = 'cms/DELETE_ASSET_FAILED'

export const LOAD_ROUTES_DONE = 'cms/LOAD_ROUTES_DONE'
export const SAVE_USER_ROUTES = 'cms/SAVE_USER_ROUTES'
export const SAVE_USER_ROUTES_DONE = 'cms/SAVE_USER_ROUTES_DONE'
export const SAVE_USER_ROUTES_FAILED = 'cms/SAVE_USER_ROUTES_FAILED'

export const LOAD_PAGES_DONE = 'cms/LOAD_PAGES_DONE'

export const CREATE_PAGE = 'cms/CREATE_PAGE'
export const CREATE_PAGE_DONE = 'cms/CREATE_PAGE_DONE'
export const CREATE_PAGE_FAILED = 'cms/CREATE_PAGE_FAILED'

export const SAVE_PAGE = 'cms/SAVE_PAGE'
export const SAVE_PAGE_DONE = 'cms/SAVE_PAGE_DONE'
export const SAVE_PAGE_FAILED = 'cms/SAVE_PAGE_FAILED'

export const SHOW_PANEL = 'cms/SHOW_PANEL'
export const TOGGLE_PANEL = 'cms/TOGGLE_PANEL'
export const HIDE_PANEL = 'cms/HIDE_PANEL'

export const SET_DRAG_MARKER = 'cms/SET_DRAG_MARKER'
export const SET_EDITING_INDEX = 'cms/SET_EDITING_INDEX'
