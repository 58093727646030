import translations from 'components/cms/AssetBrowser/_AssetBrowser.i18n.json'
import AssetViewer from 'components/cms/AssetBrowser/AssetViewer'
import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import darkTheme from 'lib/darkTheme'
import React from 'react'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import { Provider, ReactReduxContext } from 'react-redux'

const i18nKey = 'asset_browser'

class selectAssetModalProxy {
  static update() {
    console.warn('update undefined')
  }

  static register(callback) {
    this.update = callback
  }
}

export const selectAssetModal = (modal) => {
  selectAssetModalProxy.update({ ...modal, initialized: true })
}

class SelectAssetModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      defaultAction: () => console.warn('No confirmation action configured!'),
    }
    this.resetState = this.resetState.bind(this)
  }

  componentDidMount() {
    selectAssetModalProxy.register((modal) => this.setState(modal))
  }

  resetState() {
    document.body.classList.remove('qodo')
    this.setState({
      initialized: false,
      customActions: undefined,
      defaultAction: () => console.warn('No confirmation action configured!'),
    })
  }

  render() {
    const { initialized, customActions, defaultAction } = this.state
    const { translate } = this.props.globalize
    const dark = darkTheme()
    return (
      <ReactReduxContext.Consumer>
        {(ctx) => (
          <Modal open={initialized} size="large" className={classNames(['asset-browser', dark && 'inverted'])}>
            <Modal.Header>
              <Grid stackable padded>
                <Grid.Row>
                  <Grid.Column width={12} textAlign="right">
                    <Icon link name="close" onClick={this.resetState} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Header>

            <Modal.Content>
              <Modal.Description>
                <Provider store={ctx.store}>
                  <AssetViewer
                    {...{
                      closeAction: this.resetState,
                      title: translate(`${i18nKey}.select_asset`),
                      customActions: customActions,
                      defaultAction: (asset) => {
                        defaultAction(asset)
                        this.resetState()
                      },
                    }}
                  />
                </Provider>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
      </ReactReduxContext.Consumer>
    )
  }
}
SelectAssetModal = globalize({ translations })(SelectAssetModal)
export default SelectAssetModal
