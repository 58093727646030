import HeaderMenu from 'components/Navigation/Header/HeaderMenu/HeaderMenu'
import HeaderMenuMobile from 'components/Navigation/Header/HeaderMenu/HeaderMenuMobile'
import SearchResults from 'components/Navigation/Header/Search/SearchResults'
import Logo from 'components/Navigation/Menu/Logo'
import NavigationChildren from 'components/Navigation/NavigationChildren'
import NavigationLink from 'components/Navigation/NavigationLink'
import config from 'config/config'
import { useGlobalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import SalesMessage from './SalesMessage'
import { useDispatch, useSelector } from 'react-redux'
import pushNode from 'databinding/cms/thunks/pushNode'
import getCurrentNode from 'lib/getCurrentNode'

export default ({ floatingNavigation, toggleSidebar }) => {
  const routes = useSelector(({ cms: { routes } }) => routes)
  const [filterValue, setFilterValue] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const { translate } = useGlobalize()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    setShowSearch(false)
    setFilterValue('')
  }, [pathname])

  const updateFilterValue = useCallback((event, comp) => {
    setFilterValue(comp.value)
    setShowSearch(comp.value.length >= 4)
  }, [])
  const toggleSearch = useCallback(() => setShowSearch(!showSearch), [showSearch])

  const handleSearch = useCallback(() => {
    if (filterValue) dispatch(pushNode({ node: '/search', params: { search: filterValue } }))
  }, [filterValue, dispatch])

  const dark = config.theme.darkStorefront
  const node = getCurrentNode(routes, pathname)

  return (
    <div className={classNames(['site-header', floatingNavigation && 'floating'])}>
      <HeaderMenu {...{ floatingNavigation, toggleSidebar }} />
      <HeaderMenuMobile {...{ floatingNavigation, filterValue, updateFilterValue, setShowSearch, handleSearch }} />

      <Segment
        basic
        {...{
          className: classNames([
            'hide-below-computer',
            'brand-header',
            dark && 'inverted',
            floatingNavigation && 'has-floating-nav',
          ]),
        }}>
        <Container>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={2}>
                <NavigationLink node="/index" children={<Logo />} />
              </Grid.Column>
              <Grid.Column mobile={12} tablet={7} computer={7} largeScreen={7} widescreen={7} textAlign="right">
                <Menu
                  secondary
                  inverted
                  className={classNames(['secondary-navigation', 'hide-below-computer', dark && 'inverted'])}>
                  <NavigationChildren component="MenuItems" node="/bartscher" />
                </Menu>
              </Grid.Column>
              <Grid.Column width={3} textAlign="right">
                <Form.Input
                  fluid
                  action={{
                    inverted: true,
                    icon: 'search',
                    onClick: handleSearch,
                  }}
                  size="large"
                  {...{
                    onFocus: () => setShowSearch(filterValue.length > 2),
                    onKeyDown: (e) => {
                      if (!e) return
                      if (e.key === 'Escape') setShowSearch(false)
                      if (e.key === 'Enter') handleSearch()
                    },
                    onChange: updateFilterValue,
                    value: filterValue,
                    placeholder: translate('global.search'),
                    className: classNames([dark && 'inverted']),
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>

      <Segment basic inverted={dark} className="category-navigation hide-below-computer">
        <Container>
          {/* ------------- Floating Primary Navigation – Tablet and above ------------- */}
          <Menu
            borderless
            fluid
            secondary
            pointing
            {...{
              className: classNames(['primary-navigation', 'hide-below-computer', 'floating', dark && 'inverted']),
              fixed: floatingNavigation ? 'top' : null,
            }}>
            <NavigationChildren component="MenuItems" node="/categories" />
          </Menu>
        </Container>
      </Segment>
      <SalesMessage floating={floatingNavigation} />

      {showSearch && node !== '/search' && <SearchResults {...{ toggleSearch, showSearch, filterValue }} />}
    </div>
  )
}
