import PropTypes from 'prop-types'
import React from 'react'
import Highlighter from 'react-highlight-words'
import Item from 'semantic-ui-react/dist/commonjs/views/Item'
import List from 'semantic-ui-react/dist/commonjs/elements/List'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import { globalize } from 'databinding/globalize'
import translations from '../_Addresses.i18n.json'
import { branch, compose, renderNothing, setPropTypes, withProps } from 'recompose'

const i18nKey = 'address'

const propTypes = {
  filterText: PropTypes.string, // highlight a search query defined by filterText
  address: PropTypes.object.isRequired,
  noLinks: PropTypes.bool,
  plainTitle: PropTypes.bool,
  postal: PropTypes.bool, // limits the address to fields relevant for a mailing address
}

const composer = (comp) =>
  compose(
    globalize({ translations }),
    setPropTypes(propTypes),
    branch(({ address }) => !address, renderNothing),
    withProps(({ globalize: { translate } }) => ({ translate })),
    withProps(({ filterText, address }) => {
      const { address1, address2, address3, address4, address5, address6, postcode, place } = address
      const options = {
        unhighlightClassName: 'zero-padding',
        highlightClassName: 'highlight',
        searchWords: [filterText],
        activeIndex: -1,
      }
      const highlight = {
        address1: filterText ? <Highlighter {...options} textToHighlight={address1 || ''} /> : address1,
        address2: filterText ? <Highlighter {...options} textToHighlight={address2 || ''} /> : address2,
        address3: filterText ? <Highlighter {...options} textToHighlight={address3 || ''} /> : address3,
        address4: filterText ? <Highlighter {...options} textToHighlight={address4 || ''} /> : address4,
        address5: filterText ? <Highlighter {...options} textToHighlight={address5 || ''} /> : address5,
        address6: filterText ? <Highlighter {...options} textToHighlight={address6 || ''} /> : address6,
        place: filterText ? <Highlighter {...options} textToHighlight={place || ''} /> : place,
        postcode: filterText ? <Highlighter {...options} textToHighlight={`CH-${postcode || ''}`} /> : `CH-${postcode}`,
      }
      return { highlight }
    }),
    withProps(({ customerNumber }) => ({
      customerNumberKey:
        typeof customerNumber === 'boolean'
          ? `${i18nKey}.customer_number`
          : `${i18nKey}.${customerNumber}_customer_number`,
    }))
  )(comp)

export default composer(
  ({
    address,
    highlight,
    customerNumberKey,
    noLinks = false,
    postal,
    plainTitle = false,
    premium,
    customerNumber,
    translate,
  }) => {
    return (
      <Item.Group className="address">
        <Item>
          <Item.Content>
            {address.address1 && !plainTitle && (
              <Item.Header>
                {premium && <Label size="mini" content="Premium" color="orange" />}
                <div>{highlight.address1}</div>
              </Item.Header>
            )}
            <Item.Meta>
              <List>
                {address.firstName && address.lastName && (
                  <List.Item>
                    {address.firstName} {address.lastName}
                  </List.Item>
                )}
                {address.address1 && plainTitle && <List.Item>{highlight.address1}</List.Item>}
                {address.address2 && <List.Item>{highlight.address2}</List.Item>}
                {address.address3 && <List.Item>{highlight.address3}</List.Item>}
                {address.address4 && <List.Item>{highlight.address4}</List.Item>}
                {address.address5 && <List.Item>{highlight.address5}</List.Item>}
                {address.address6 && <List.Item>{highlight.address6}</List.Item>}
                {address.postcode && address.place && (
                  <List.Item>
                    {highlight.postcode}&nbsp;{highlight.place}
                  </List.Item>
                )}
                {!postal && (address.phone || address.fax) && <List.Item className="divider" />}
                {!postal && address.phone && (
                  <List.Item>
                    Tel.: {noLinks ? address.phone : <a href={`tel:${address.phone}`}>{address.phone}</a>}
                  </List.Item>
                )}
                {!postal && address.fax && (
                  <List.Item>Fax: {noLinks ? address.fax : <a href={`tel:${address.fax}`}>{address.fax}</a>}</List.Item>
                )}
                {!postal && address.email && (
                  <List.Item className="divider">
                    {noLinks ? address.email : <a href={`mailto:${address.email}`}>{address.email}</a>}
                  </List.Item>
                )}
                {customerNumber && address.addressNumber && (
                  <List.Item children={translate(customerNumberKey) + `: ${address.addressNumber}`} />
                )}
              </List>
            </Item.Meta>
          </Item.Content>
        </Item>
      </Item.Group>
    )
  }
)
