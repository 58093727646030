import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import List from 'semantic-ui-react/dist/commonjs/elements/List'

const mapStateToProps = undefined

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ push }, dispatch)
}

class LinkListNavigation extends React.PureComponent {
  constructor(props) {
    super(props)
    this.push = this.push.bind(this)
  }

  push(event, { path }) {
    const { push } = this.props
    push(path)
  }

  getListItems() {
    const { routes } = this.props
    return routes.map((route, index) => {
      let returnValue = false
      if (route) {
        returnValue = (
          <List.Item
            key={index}
            as="a"
            active={route.active}
            path={route.path}
            onClick={this.push}
            children={route.navigationTitle}
          />
        )
      } else {
        console.warn('Route is undefined')
      }
      return returnValue
    })
  }

  render() {
    // noinspection JSUnusedLocalSymbols Do not remove unused imports!
    const { routes, history, location, match, selection, staticContext, node, push, ...remainingProps } = this.props //eslint-disable-line no-unused-vars
    return <List link relaxed {...remainingProps} children={this.getListItems()} />
  }
}

LinkListNavigation = connect(mapStateToProps, mapDispatchToProps)(LinkListNavigation)
export default withRouter(LinkListNavigation)
