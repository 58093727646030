import translations from 'components/cms/AssetBrowser/_AssetBrowser.i18n.json'
import AssetList from 'components/cms/AssetBrowser/AssetList'
import getFileTypes from 'components/cms/AssetBrowser/lib/getFileTypes'
import getFilteredAssets from 'components/cms/AssetBrowser/lib/getFilteredAssets'
import Pagination from 'components/cms/AssetBrowser/Pagination'
import SelectLayout from 'components/cms/AssetBrowser/SelectLayout'
import UploadButton from 'components/cms/AssetBrowser/UploadButton'
import ComponentHeader from 'components/cms/Helper/ComponentHeader'
import DropTarget from 'components/Helpers/DropTarget'
import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import darkTheme from 'lib/darkTheme'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import createAsset from 'databinding/cms/thunks/createAsset'

const i18nKey = 'asset_browser'

const mapStateToProps = (state) => {
  const { assets = [], uploadingAsset } = state.cms
  return { assets, uploadingAsset }
}

class AssetBrowser extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setPageNumber = this.setPageNumber.bind(this)
    this.handleDrop = this.handleDrop.bind(this)
    this.toggleViewStyle = this.toggleViewStyle.bind(this)
    this.updateFilterText = this.updateFilterText.bind(this)
    this.updateTypeFilter = this.updateTypeFilter.bind(this)
    this.state = {
      viewAsList: false,
      pageNumber: 0,
      filterText: '',
      filterType: undefined,
    }
  }

  componentDidUpdate(prevProps) {
    const { filterType: newFilterType, filterText: newFilterText } = this.props
    const { filterType: oldFilterType, filterText: oldFilterText } = prevProps
    if (newFilterType !== oldFilterType || newFilterText !== oldFilterText) this.setState({ pageNumber: 0 })
  }

  setPageNumber(pageNumber) {
    this.setState({ pageNumber })
  }

  handleDrop(files) {
    const { dispatch } = this.props
    dispatch(createAsset({ files }))
  }

  toggleViewStyle() {
    const { viewAsList } = this.state
    this.setState({ viewAsList: !viewAsList })
  }

  updateFilterText(event, field = {}) {
    this.setState({ filterText: field.value })
  }

  updateTypeFilter(event, field = {}) {
    this.setState({ filterType: field.value })
  }

  render() {
    const { viewAsList, pageNumber, filterText, filterType } = this.state
    const { title, allowedTypes, uploadingAsset, assets = [] } = this.props
    const { translate } = this.props.globalize
    const dark = darkTheme()

    const { filteredAssets, currentPage, totalPages } = getFilteredAssets({
      pageNumber,
      assets,
      allowedTypes,
      filterText,
      filterType,
    })

    return (
      <div className={classNames(['assetBrowser', 'ui', 'form', dark && 'inverted'])}>
        <ComponentHeader
          inline
          title={title || translate(`${i18nKey}.title`)}
          fluid={true}
          customActions={[
            <Form.Input
              name="filterText"
              icon="search"
              value={filterText}
              placeholder={translate(`${i18nKey}.filter`)}
              onChange={this.updateFilterText}
            />,
            <Form.Dropdown
              options={getFileTypes({ allowedTypes, translate })}
              placeholder={translate(`${i18nKey}.filetype`)}
              width={2}
              name="filterType"
              selection
              className="inverted"
              value={filterType}
              onChange={this.updateTypeFilter}
            />,
            <UploadButton />,
          ]}
        />
        <DropTarget
          {...{
            multiple: false,
            disableClick: true,
            onDrop: (files) => {
              if (files.length === 0) return // prevent drag on self
              this.handleDrop()
            },
          }}>
          <Segment attached loading={uploadingAsset} className={classNames(['payload', 'small', dark && 'inverted'])}>
            <AssetList {...{ assets: filteredAssets, viewAsList }} />
          </Segment>
        </DropTarget>

        <Segment basic tertiary>
          <ComponentHeader
            inline
            footer
            title=""
            fluid={true}
            customActions={[
              <SelectLayout {...{ viewAsList, toggleViewStyle: this.toggleViewStyle }} />,
              <Pagination
                {...{
                  setPageNumber: this.setPageNumber,
                  currentPage,
                  totalPages,
                }}
              />,
            ]}
          />
        </Segment>
      </div>
    )
  }
}

AssetBrowser.propTypes = {
  title: PropTypes.string,
  allowedTypes: PropTypes.array,
}

AssetBrowser = connect(mapStateToProps)(AssetBrowser)
AssetBrowser = globalize({ translations })(AssetBrowser)
export default AssetBrowser
