import getComponentsForPage from 'components/cms/lib/getComponentsForPage'
import { globalize } from 'databinding/globalize'
import classNames from 'lib/classNames'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import translations from './_ContentSubnavi.i18n.json'

const mapStateToProps = (state) => {
  const { pages, components, cmsSidebarVisible, routes } = state.cms
  return { pages, components, cmsSidebarVisible, routes }
}

class ContentSubnavi extends React.PureComponent {
  state = {
    active: 0,
  }

  getLinks(items) {
    const { active } = this.state

    return items.map(
      (item, index) =>
        item.heading && (
          <Menu.Item
            {...{
              active: index === active,
              key: index,
              name: `text-${item.id}`,
              content: item.heading,
              href: `#${item.anchor}`,
              onClick: () => {
                this.setState({ active: index })
              },
            }}
          />
        )
    )
  }

  // TODO: adapt this
  render() {
    const {
      pages = [],
      routes = [],
      components = [], //connect
      match: { path }, //globalize
      globalize: { language }, //globalize
      grey,
      spacing, // ownProps
    } = this.props

    const classesContainer = classNames([spacing && 'spacing-after', grey && 'grey'])
    const myComponents = getComponentsForPage({
      pages,
      routes,
      components,
      path,
      language,
    })
    let nodes
    nodes = myComponents.filter(
      (component) =>
        component.props &&
        component.props.anchor &&
        (component.props.component === 'Divider' || component.props.component === 'Title')
    )
    nodes = nodes.map((component) => ({
      anchor: component.props.anchor,
      heading: component.props.title || component.props.text,
    }))

    return (
      <Segment basic className={classesContainer}>
        <Container className="justify-center">
          <Menu pointing secondary children={this.getLinks(nodes)} />
        </Container>
      </Segment>
    )
  }
}

ContentSubnavi = globalize({ translations })(ContentSubnavi)
ContentSubnavi = connect(mapStateToProps)(ContentSubnavi)
ContentSubnavi = withRouter(ContentSubnavi)
export default ContentSubnavi
