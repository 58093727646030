// concatenates and cleans up CSS classnames - expects an array,  returns a string
export default (classNames) => {
  let returnValue = ''
  if (classNames && Array.isArray(classNames)) {
    returnValue = classNames.filter((className) => className).join(' ')
  } else if (classNames && String.isString(classNames)) {
    returnValue = classNames
  }
  return returnValue
}
