import PropTypes from 'prop-types'
import React from 'react'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

class AssetListImage extends React.PureComponent {
  render() {
    const { asset = {}, rendition = 'thumb', customProps = {} } = this.props
    const { contentType = '', styles = {} } = asset
    const renditionObject = styles[rendition] || {}

    let returnValue = false
    if (contentType.match(/^image/)) {
      returnValue = (
        <Image
          {...{
            ...customProps,
            className: 'indicate-link',
            src: process.env.REACT_APP_CMS_API + renditionObject.url,
          }}
        />
      )
    } else if (contentType === 'application/pdf') {
      returnValue = <Icon name="file pdf outline" size={rendition === 'large' ? 'massive' : 'large'} />
    }
    return returnValue
  }
}

AssetListImage.propTypes = {
  asset: PropTypes.object,
  rendition: PropTypes.string,
  customProps: PropTypes.object,
}

export default AssetListImage
