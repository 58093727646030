import {
  CREATE_ASSET,
  CREATE_ASSET_DONE,
  CREATE_ASSET_FAILED,
  CREATE_PAGE_DONE,
  CREATE_PAGE_FAILED,
  HIDE_PANEL,
  LOAD_ASSETS_DONE,
  LOAD_PAGES_DONE,
  LOAD_ROUTES_DONE,
  SAVE_PAGE_DONE,
  SAVE_PAGE_FAILED,
  SET_DRAG_MARKER,
  SET_EDITING_INDEX,
  SHOW_PANEL,
  TOGGLE_PANEL,
} from 'databinding/cms/constants'
import routes from 'databinding/routes.json'

const initialState = {
  sections: [],
  routes: [],
  userRoutes: [],
  assets: [],
  cmsSidebarVisible: false,
}

//noinspection FunctionWithMultipleReturnPointsJS
export const cms = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ASSETS_DONE:
      return { ...state, assetsError: undefined, assets: action.assets }
    case LOAD_ROUTES_DONE:
      return { ...state, routes: [...routes, ...action.routes], userRoutes: action.userRoutes }
    case LOAD_PAGES_DONE:
      return { ...state, pagesError: undefined, pages: action.pages }
    // TODO: @andreas - now might be the time, see AssetDownload.jsx which is used @Text + Image > Column (both AssetDownloads in sidebar are spinning)
    // note: If we at any time need to know, which assets are uploading, we can create an array with uploading ids
    case CREATE_ASSET:
      return { ...state, uploadingAsset: true }
    case CREATE_ASSET_DONE:
      return { ...state, uploadingAsset: false, assetsError: undefined }
    case CREATE_ASSET_FAILED:
      return { ...state, uploadingAsset: false, assetsError: action.error }
    case SAVE_PAGE_DONE:
      return { ...state, savePageError: false, pageSavedAt: new Date().getTime() }
    case SAVE_PAGE_FAILED:
      return { ...state, savePageError: action.error, pageSavedAt: new Date().getTime() }
    case CREATE_PAGE_DONE:
      return { ...state, savePageError: false, pageSavedAt: new Date().getTime() }
    case CREATE_PAGE_FAILED:
      return { ...state, savePageError: action.error, pageSavedAt: new Date().getTime() }
    case SHOW_PANEL:
      return { ...state, cmsSidebarVisible: true }
    case TOGGLE_PANEL:
      return { ...state, cmsSidebarVisible: !state.cmsSidebarVisible }
    case HIDE_PANEL:
      return { ...state, cmsSidebarVisible: false }

    case SET_DRAG_MARKER:
      return { ...state, dragMarker: action.dragMarker }
    case SET_EDITING_INDEX:
      return {
        ...state,
        editingComponentIndex: action.editingComponentIndex,
        editingElementIndex: action.editingElementIndex,
      }
    default:
      return state
  }
}
