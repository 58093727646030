import { loadCurrentUserDone, userLogout } from 'databinding/shop/actions'
import request from 'superagent'
import jwt from 'jsonwebtoken'
import { toCamel } from 'convert-keys'

export default () => async (dispatch, getState) => {
  try {
    const { jwt: token, jwtPublicKey } = getState().shop
    if (!token) return
    jwt.verify(token, jwtPublicKey)
    const response = await request
      .get(`${process.env.REACT_APP_SHOP_API}/users/current.json`)
      .set('Authorization', `Bearer ${token}`)
    dispatch(loadCurrentUserDone({ currentUser: toCamel(response.body) }))
  } catch (e) {
    if (['TokenExpiredError', 'JsonWebTokenError'].includes(e.name)) {
      dispatch(userLogout())
      localStorage.removeItem('jwt')
    }
    console.log(e.name)
    //todo redux: handle connection errors
    console.error('current user could not be loaded', e)
  }
}
