const i18nKey = 'asset_browser'

export default ({ allowedTypes, translate }) => {
  let fileTypes = [
    //{ text: translate(`${i18nKey}.file_type.image/jpeg`), value: 'image/jpg' },
    //{ text: translate(`${i18nKey}.file_type.image/png`), value: 'image/png' },
    { text: translate(`${i18nKey}.file_type.images`), value: 'image/' },
    {
      text: translate(`${i18nKey}.file_type.application/pdf`),
      value: 'application/pdf',
    },
  ]

  if (allowedTypes) {
    fileTypes = fileTypes.filter((option) => allowedTypes.indexOf(option.value) >= 0)
  }

  return [{ text: translate(`${i18nKey}.file_type.all`), value: null }, ...fileTypes]
}
