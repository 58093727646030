import AccountMenu from 'components/Navigation/Menu/AccountMenu'
import MenuItemLanguages from 'components/Navigation/Menu/MenuItems/MenuItemLanguages'
import config from 'config/config'
import classNames from 'lib/classNames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import MenuItemLogo from '../../Menu/MenuItems/MenuItemLogo'

class HeaderMenu extends React.PureComponent {
  render() {
    const dark = config.theme.darkStorefront

    // props
    const { sidebarVisible } = this.props
    // action creators
    const { toggleSidebar, floatingNavigation } = this.props

    // ------------- Top Menu containing Login, Cart etc. - always displayed -------------
    return (
      <Menu
        {...{
          borderless: true,
          fluid: true,
          secondary: true,
          size: 'small',
          fixed: 'top',
          className: classNames(['header-menu', floatingNavigation ? 'floating' : 'not-floating', dark && 'inverted']),
        }}>
        <Menu.Item onClick={toggleSidebar} className={classNames([!floatingNavigation && 'hide-above-computer'])}>
          <Icon name="bars" rotated={sidebarVisible ? 'clockwise' : null} size="large" />
        </Menu.Item>
        <MenuItemLogo showLogo={floatingNavigation} inverted={dark} />
        <AccountMenu />
        <Menu.Menu>
          <MenuItemLanguages />
        </Menu.Menu>
      </Menu>
    )
  }
}

HeaderMenu = withRouter(HeaderMenu) // this is needed for MenuItemLanguages. Do not remove.
export default HeaderMenu
