import DealerListItems from 'components/cms/cmsComponents/DealerList/DealerListItems'
import ContactInfo from 'components/Navigation/Footer/ContactInfo'
import { globalize } from 'databinding/globalize'
import React from 'react'
import Table from 'semantic-ui-react/dist/commonjs/collections/Table'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'

class DealerList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.updateFilterText = this.updateFilterText.bind(this)
    this.state = {
      filterText: '',
    }
  }

  updateFilterText(event, field = {}) {
    this.setState({ filterText: field.value })
  }

  render() {
    const { filterText } = this.state
    const {
      globalize: { translate },
    } = this.props
    return (
      <Segment basic>
        <Container>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form.Input
                  name="filterText"
                  fluid
                  autoFocus
                  icon="search"
                  value={filterText}
                  placeholder={translate('global.search')}
                  onChange={this.updateFilterText}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Table basic="very" compact>
                  <Table.Body>
                    <DealerListItems filterText={filterText} />
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width={4}>
                <ContactInfo />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
  }
}

DealerList = globalize()(DealerList)
export default DealerList
