export default ({ routes, node, language }) => {
  let returnValue
  const parameters = {}
  const routeByNode = routes.find((route) => {
    let returnValue = true
    if (route.node === node) {
      returnValue = true
    } else {
      // find parameter nodes
      const routeNodeParts = route.node.split('/')
      const nodeParts = node.split('/')
      if (routeNodeParts.length !== nodeParts.length) {
        // no match if no length match
        returnValue = false
      } else {
        // check path elements for equality, omit parameters
        for (let i = 0; i < nodeParts.length; i++) {
          if (!routeNodeParts[i].match(/^:/) && nodeParts[i] !== routeNodeParts[i]) {
            returnValue = false
            break
          }
        }
        if (returnValue) {
          // save parameter values in object
          for (let i = 0; i < nodeParts.length; i++) {
            if (routeNodeParts[i].match(/^:/)) parameters[routeNodeParts[i]] = nodeParts[i]
          }
        }
      }
    }
    return returnValue
  })
  if (routeByNode) {
    const { path } = routeByNode
    if (path && path[language]) {
      const pathParts = path[language].split('/')
      returnValue = pathParts.map((part) => (part.match(/^:/) ? parameters[part] : part)).join('/')
    } else {
      console.error(`Undefined Language ${language} for node ${node}`)
    }
  } else {
    console.error(`Route could not be found for node ${node}`)
  }
  return returnValue
}
