import NavigationBreadCrumb from 'components/Navigation/NavigationBreadCrumb'
import { globalize } from 'databinding/globalize'
import getPageTitle from 'lib/getPageTitle'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'

const mapStateToProps = (state) => {
  const { routes } = state.cms
  return { routes }
}

class Title extends React.PureComponent {
  render() {
    const {
      breadcrumbOnly,
      inline,
      breadcrumb,
      title,
      padded,
      dividing,
      as = 'h1',
      icon, //ownProps
      routes, //redux
      match: { path }, // withRouter
      globalize: { language }, // globalize
    } = this.props // from redux

    const heading = (
      <React.Fragment>
        {!breadcrumbOnly && (
          <React.Fragment>
            <Header
              {...{
                as,
                dividing,
                icon,
                content: title || getPageTitle(routes, path, language),
                className: 'page-title',
              }}
            />
          </React.Fragment>
        )}
        {(breadcrumb || breadcrumbOnly) && <NavigationBreadCrumb breadcrumbOnly={breadcrumbOnly} />}
      </React.Fragment>
    )

    let returnValue
    if (inline) returnValue = <div className="inline-title" children={heading} />
    else
      returnValue = (
        <Container>
          {padded ? (
            <Grid relaxed padded className="spacing-after">
              <Grid.Row>
                <Grid.Column children={heading} />
              </Grid.Row>
            </Grid>
          ) : (
            <div className="spacing-after" children={heading} />
          )}
        </Container>
      )

    return returnValue
  }
}

Title = globalize()(Title)
Title = connect(mapStateToProps)(Title)
export default withRouter(Title)
