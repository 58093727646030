import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import SemanticMenu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import config from 'config/config'

const mapStateToProps = undefined

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ push }, dispatch)
}

class MenuItemsNavigation extends React.PureComponent {
  constructor(props) {
    super(props)
    this.push = this.push.bind(this)
  }

  push(event, { path }) {
    const { push } = this.props
    push(path)
  }

  getMenuItems() {
    const { routes, badges } = this.props
    return routes.map((route, index) => {
      const badgeContent = badges && badges[route.node]
      let returnValue = false
      if (route) {
        returnValue = (
          <SemanticMenu.Item key={index} active={route.active} path={route.path} onClick={this.push}>
            {route.icon && <Icon name={route.icon} />}
            {route.navigationTitle}
            {badgeContent && <Label color="red" className="badge" content={badgeContent} />}
          </SemanticMenu.Item>
        )
      } else {
        console.warn('Route is undefined')
      }
      return returnValue
    })
  }

  render() {
    const { component } = this.props
    const items = this.getMenuItems()

    let returnValue = false
    if (items.length > 0) {
      if (component === 'Menu') {
        returnValue = <SemanticMenu secondary pointing color={config.semantic.primaryColor} items={items} />
      } else {
        returnValue = items
      }
    }
    return returnValue
  }
}

MenuItemsNavigation = connect(mapStateToProps, mapDispatchToProps)(MenuItemsNavigation)
export default withRouter(MenuItemsNavigation)
