import { useLocation } from 'react-router-dom'
import { usePrevious } from 'lib/usePrevious'
import { useEffect } from 'react'

export const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()
  const lastPathName = usePrevious(pathname)
  useEffect(() => {
    const domNode = document.getElementsByClassName('route')[0]
    if (domNode) {
      domNode.scrollIntoView()
    }
    const contentNode = document.getElementById('content')
    if (contentNode) {
      contentNode.scrollIntoView()
    }
  }, [pathname, lastPathName])
  return children
}
