import MyMarkdown from 'components/cms/cmsComponents/MyMarkdown'
import AssetDownload from 'components/Helpers/AssetDownload'
import AssetImage from 'components/Helpers/AssetImage'
import NavigationLink from 'components/Navigation/NavigationLink'
import { globalize } from 'databinding/globalize'
import placeholder from 'images/empty.png'
import classNames from 'lib/classNames'
import React from 'react'
import { connect } from 'react-redux'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'

const mapStateToProps = (state) => {
  const { products = [] } = state.shop
  return { products }
}

const mapDispatchToProps = undefined

class Slide extends React.PureComponent {
  render() {
    const {
      slide = {},
      className,
      style, //ownProps
      products = [], //connect
      globalize: { language }, //globalize
    } = this.props
    const { button = {}, widths = {}, styles = {}, css_classes: gridClasses, asset_id: assetId } = slide

    const containerClasses = classNames([slide.inverse ? 'dark' : 'light', slide.readable && 'readable'])
    let stylesContainer = {},
      stylesRow = {},
      stylesImage = {}
    const gridWidths = {
      width: widths.width,
      tablet: widths.tablet,
      mobile: widths.mobile,
      computer: widths.computer,
      widescreen: widths.widescreen,
      largeScreen: widths.largeScreen,
    }

    if (styles) {
      stylesImage = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: styles.imageAlignment,
        minHeight: 0,
      }
      stylesContainer = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: styles.blockAlignmenVert,
      }
      stylesRow = {
        textAlign: styles.textAlignment,
        display: 'flex',
        alignItems: styles.blockAlignmentHoriz,
        justifyContent: styles.blockAlignmentHoriz,
        flexDirection: styles.blockAlignmentHoriz !== 'flex-end' ? 'row' : 'column',
      }
    }

    let node
    if (button.articleNumber) {
      const product = products.find((product) => product.articleNumber === button.articleNumber) || {}
      const { seoUrl = {} } = product
      node = `/product/${seoUrl[language]}/${button.articleNumber}`
    } else {
      node = button.node
    }
    const buttonProps = {
      secondary: button.type === 'secondary',
      primary: button.type === 'primary',
      icon: button.icon,
      content: button.title,
    }
    return (
      <div {...{ className, style }}>
        <div style={stylesImage}>{assetId ? <AssetImage id={assetId} fluid /> : <Image src={placeholder} fluid />}</div>
        {(slide.heading || slide.text) && (
          <div className="slide-info">
            <Container className={containerClasses} style={stylesContainer}>
              <Grid stackable className={classNames(gridClasses)}>
                <Grid.Row style={stylesRow}>
                  <Grid.Column {...gridWidths} className="content">
                    <div className="wrapper" style={stylesRow}>
                      {slide.heading && <Header as="h1" content={slide.heading} />}
                    </div>
                    <div className="wrapper" style={stylesRow}>
                      {slide.text && <MyMarkdown children={slide.text} />}
                    </div>

                    {(node || button.download_id) && (
                      <React.Fragment>
                        <Divider clearing hidden />
                        <div style={stylesRow}>
                          {!button.download_id && (
                            <NavigationLink
                              node={node}
                              component="Button"
                              {...buttonProps}
                              labelPosition={button.icon && 'right'}
                            />
                          )}
                          {button.download_id && <AssetDownload id={button.download_id} button={buttonProps} />}
                        </div>
                      </React.Fragment>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
        )}
      </div>
    )
  }
}

Slide = connect(mapStateToProps, mapDispatchToProps)(Slide)
Slide = globalize()(Slide)
export default Slide
