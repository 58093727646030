import AssetBrowser from 'components/cms/AssetBrowser/AssetBrowser'
import AssetEditor from 'components/cms/AssetBrowser/AssetEditor'
import PropTypes from 'prop-types'
import React from 'react'
import keydown from 'react-keydown'

class AssetViewer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setEditingAssetID = this.setEditingAssetID.bind(this)
    this.state = {
      editingAssetId: undefined,
    }
  }

  getChildContext() {
    const { customActions = () => [], defaultAction = () => console.warn('No default action defined') } = this.props
    return {
      setEditingAssetID: this.setEditingAssetID,
      customActions,
      defaultAction,
    }
  }

  setEditingAssetID(id) {
    this.setState({ editingAssetId: id })
  }

  componentDidUpdate(prevProps) {
    // const {
    //   keydown: { event },
    // } = nextProps
    // const { closeAction } = this.props
    //
    // if (event && closeAction) {
    //   event.preventDefault()
    //   if (event.key === 'Escape') {
    //     closeAction()
    //   }
    // }
  }

  render() {
    const { editingAssetId } = this.state
    const { title } = this.props
    if (editingAssetId) return <AssetEditor {...{ editingAssetId, setEditingAssetID: this.setEditingAssetID }} />
    return <AssetBrowser {...{ title }} />
  }
}

AssetViewer.propTypes = {
  title: PropTypes.string,
  customActions: PropTypes.array,
  defaultAction: PropTypes.func,
}

AssetViewer.childContextTypes = {
  setEditingAssetID: PropTypes.func,
  customActions: PropTypes.func,
  defaultAction: PropTypes.func,
}

AssetViewer = keydown('esc')(AssetViewer)
export default AssetViewer
