import React from 'react'
import request from 'superagent'
import StackTrace from 'stacktrace-js'
import Logo from 'components/Navigation/Menu/Logo'
import Divider from 'components/cms/cmsComponents/Divider/Divider'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import { history } from 'databinding/store'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import {detect} from 'detect-browser'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, text: '' }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('componentDidCatch')
    this.setState({ hasError: true, error, errorInfo })
    if (process.env.NODE_ENV === 'production') {
      this.sendToAPI()
    }
  }

  sendToAPI() {
    const { error, errorInfo, text } = this.state
    const browser = detect()
    StackTrace.fromError(error).then((stackFrames) =>
      request.post(`${process.env.REACT_APP_CMS_API}/error.json`, {
        location: this.props.location,
        pathname: ((history || {}).location || {}).pathname,
        errorMessage: error.message,
        errorInfo,
        text,
        browser: browser.name + ' ' + browser.version + ' ' + browser.os,
        stackTrace: stackFrames
          .filter((stackFrame) => !stackFrame.fileName.includes('node_modules'))
          .map(
            (stackFrame) =>
              `Datei: ${stackFrame.fileName}, Zeile: ${stackFrame.lineNumber}, Zeichen: ${stackFrame.columnNumber}`
          )
          .join('\n'),
        localStorage: Array.from(Array(localStorage.length).keys())
          .map((n) => localStorage.key(n))
          .map((key) => `${key}: ${localStorage.getItem(key)}`)
          .join('\n'),
      })
    )
  }

  clearLocalStorage() {
    localStorage.clear()
  }

  handleChange(event,component) {
    this.setState({ text: component.value })
  }

  getTexts() {
    const texts = {
      de: [
        'Es tut uns sehr leid, dass dies passiert ist. Ein grober Fehlerbericht wurde bereits an unser Entwicklungsteam gesendet und wir werden unser Bestes tun, um den Fehler so schnell wie möglich zu beheben.',
        'Was Sie tun können: Versuchen Sie, die Seite neu zu laden. Wenn dies nicht hilft, versuchen Sie, den lokalen Speicher Ihres Browsers zu leeren, indem Sie den Knopf ganz unten drücken. Der Inhalt Ihres Warenkorbs geht dabei allerdings verloren.',
        'Wie Sie helfen können: Geben Sie in das Textfeld eine kurze Beschreibung der Schritte ein, die zum Fehler geführt haben und senden Sie es ab. Vielen Dank für Ihre Hilfe',
      ],
      fr: [
        "Nous sommes vraiment désolés que cela se soit produit. Un rapport d'erreur sommaire a déjà été envoyé à notre équipe de développement et nous ferons de notre mieux pour le corriger dès que possible.",
        'Ce que vous pouvez faire : Essayez de recharger la page. Si cela ne vous aide pas, essayez de vider la mémoire locale de votre navigateur en appuyant sur le bouton ci-dessous. Vous perdrez cependant le contenu de votre panier.',
        "Comment vous pouvez aider : Saisissez dans la zone de texte une brève description des étapes qui ont conduit à l'erreur.",
      ],
    }
    const language = (((history || {}).location || {}).pathname || '/de').split('/')[1]
    return texts[language]
  }

  getError() {
    const language = (((history || {}).location || {}).pathname || '/de').split('/')[1]
    switch (this.props.location) {
      case 'app-root':
      case 'navigation-wrapper': {
        const error = {
          de: 'Beim Ausführen dieser App ist leider auf oberster Ebene ein Fehler aufgetreten, daher kann die komplette App nicht mehr angezeigt werden.',
          fr: "Lors de l'exécution de cette application, une erreur est malheureusement survenue au niveau supérieur, c'est pourquoi l'application complète ne peut plus être affichée.",
        }
        return error[language]
      }
      case 'switch':
      case 'page': {
        const error = {
          de: 'Eine Komponente in dieser App hat leider einen Fehler erzeugt und wurde daher durch diese Meldung ersetzt. Die Navigation oben ist jedoch weiterhin verfügbar.',
          fr: 'Un composant de cette application a malheureusement généré une erreur et a donc été remplacé par ce message. La navigation en haut est toutefois toujours disponible.',
        }
        return error[language]
      }
      default:
        return ''
    }
  }

  render() {
    if (this.state.hasError) {
      const language = (((history || {}).location || {}).pathname || '/de').split('/')[1]
      const title = {
        de: 'Fehler',
        fr: 'Erreur',
      }
      const texts = [this.getError(), ...this.getTexts()]
      return (
        <React.Fragment>
          {['app-root', 'navigation-wrapper'].includes(this.props.location) && (
            <Segment className="error-header">
              <Container>
                <a href="/">
                  <Logo size="small" />
                </a>
              </Container>
            </Segment>
          )}
          <Segment basic clearing>
            <Container>
              <Divider hidden fitted />
              <Header as="h1" content={title[language]} />
              <React.Fragment
                children={texts.map((t) => (
                  <p children={t} />
                ))}
              />
              <Form.TextArea
                value={this.state.text}
                onChange={this.handleChange.bind(this)}
                rows={10}
                style={{ width: '100%' }}
              />
              <Button size="tiny" onClick={this.sendToAPI.bind(this)}>
                Absenden
              </Button>
              <Divider hidden fitted />
              <Button color="red" basic onClick={this.clearLocalStorage.bind(this)}>
                Lokalen Speicher löschen / vider la mémoire locale
              </Button>
            </Container>
          </Segment>
        </React.Fragment>
      )
    }

    return this.props.children
  }
}
